import { useState, useCallback } from 'react';

const useAnchorMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { anchorEl, handleOpenMenu, handleCloseMenu };
};

export default useAnchorMenu;

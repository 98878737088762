import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'store/slices/languageSlice';
import { useTheme } from '@mui/material/styles';

const useLanguages = () => {
  const dispatch = useDispatch();
  const {
    i18n: { resolvedLanguage, dir },
  } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    dispatch(setLanguage(resolvedLanguage));
    document.documentElement.lang = resolvedLanguage;
    document.documentElement.dir = dir();
    theme.direction = dir();
  }, [dir, dispatch, resolvedLanguage, theme]);
};

export default useLanguages;

import { forwardRef, useState, useMemo } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as FolderIcon } from 'assets/images/folder-icon.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallet-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { ReactComponent as AddUserIcon } from 'assets/images/add-user-icon.svg';
import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { StyledFab, NavBtnWrapper } from './styles';
import MenuDrawer from 'components/MenuDrawer';
import { useTranslation } from 'react-i18next';

const NavBtn = forwardRef(({ children, ...props }, ref) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  const addButtonMenuList = useMemo(() => {
    return [
      {
        icon: (
          <SvgIcon component={FolderIcon} svgstroke='true' color='primary' />
        ),
        text: t('action.addAssetLiability'),
        to: '/add-assets-liabilities',
      },
      {
        icon: (
          <SvgIcon component={WalletIcon} svgstroke='true' color='success' />
        ),
        text: t('action.addMoney'),
        to: '/add-money-in-out',
      },
      {
        icon: (
          <SvgIcon
            component={AddUserIcon}
            svgstroke='true'
            sx={{ color: 'violet.main' }}
          />
        ),
        text: t('action.addUser'),
        to: '/add-user',
      },
      {
        icon: (
          <SvgIcon
            component={DocumentIcon}
            svgstroke='true'
            sx={{ color: 'orange.main' }}
          />
        ),
        text: t('action.addPost'),
        to: '/add-post',
      },
    ];
  }, [t]);

  return (
    <NavBtnWrapper {...props} ref={ref}>
      <StyledFab color='primary' onClick={toggleDrawer(true)}>
        <SvgIcon
          component={PlusIcon}
          svgstroke='true'
          viewBox='0 0 10 10'
          sx={{ fontSize: 14 }}
        />
      </StyledFab>

      {children}

      <MenuDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        list={addButtonMenuList}
      />
    </NavBtnWrapper>
  );
});

export default NavBtn;

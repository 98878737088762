import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { stringAvatar } from 'utils/stringAvatar';
import { useTranslation } from 'react-i18next';

const UserMenu = ({ user, children, anchorEl, onOpen, onClose, matches }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flexGrow: 0,
        marginInlineStart: matches ? 2.25 : 0.5,
        marginInlineEnd: 0,
      }}
    >
      <Tooltip title={t('tooltip.userMenu')}>
        <IconButton onClick={onOpen} sx={{ p: 0 }}>
          <Avatar
            alt={user.displayName}
            src={user.photoURL ? user.photoURL : null}
            {...stringAvatar({ fName: user.firstName, lName: user.lastName })}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '53px' }}
        id='menu-header'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default UserMenu;

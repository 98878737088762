import moment from 'moment';

const dates = {
  today: moment().startOf('day'),
  yesterday: moment().subtract(1, 'days').startOf('day'),
  lastWeek: moment().subtract(1, 'week').startOf('day'),
  lastMonth: moment().subtract(1, 'months').startOf('day'),
  lastQuarter: moment().subtract(1, 'quarter').startOf('day'),
  lastYear: moment().subtract(1, 'years').startOf('day'),
  priorWeek: {
    start: moment().subtract(1, 'weeks').startOf('week'),
    end: moment().subtract(1, 'weeks').endOf('week'),
  },
  priorMonth: {
    start: moment().subtract(1, 'months').startOf('month'),
    end: moment().subtract(1, 'months').endOf('month'),
  },
  priorQuarter: {
    start: moment().subtract(1, 'quarter').startOf('quarter'),
    end: moment().subtract(1, 'quarter').endOf('quarter'),
  },
  priorYear: {
    start: moment().subtract(1, 'years').startOf('year'),
    end: moment().subtract(1, 'years').endOf('year'),
  },
  monthToDate: moment().startOf('month'),
  quarterToDate: moment().startOf('quarter'),
  yearToDate: moment().startOf('year'),
};

const filterDate = (item, key, filtersDate) => {
  if (!item) return true;
  if (!filtersDate) return true;

  switch (filtersDate.date) {
    case '':
      return true;

    case 'custom':
      return filtersDate.dateFrom && filtersDate.dateTo
        ? moment(item[key].toDate()).isSameOrAfter(
            moment(filtersDate.dateFrom).startOf('day'),
            'day'
          ) &&
            moment(item[key].toDate()).isSameOrBefore(
              moment(filtersDate.dateTo).startOf('day'),
              'day'
            )
        : filtersDate.dateFrom
        ? moment(item[key].toDate()).isSameOrAfter(
            moment(filtersDate.dateFrom).startOf('day'),
            'day'
          )
        : filtersDate.dateTo
        ? moment(item[key].toDate()).isSameOrBefore(
            moment(filtersDate.dateTo).startOf('day'),
            'day'
          )
        : true;

    case 'today':
      return moment(item[key].toDate()).isBetween(
        dates.today,
        dates.today,
        'day',
        '[]'
      );

    case 'yesterday':
      return moment(item[key].toDate()).isBetween(
        dates.yesterday,
        dates.yesterday,
        'day',
        '[]'
      );

    case 'priorWeek':
    case 'priorMonth':
    case 'priorQuarter':
    case 'priorYear':
      return moment(item[key].toDate()).isBetween(
        dates[filtersDate.date].start,
        dates[filtersDate.date].end,
        'day',
        '[]'
      );

    default:
      return moment(item[key].toDate()).isBetween(
        dates[filtersDate.date],
        dates.today,
        'day',
        '[]'
      );
  }
};

export const filterDateLabels = filtersDate => {
  if (!filtersDate)
    return {
      start: 'all',
      end: null,
      today: dates.today
    };

  switch (filtersDate.date) {
    case '':
      return {
        start: null,
        end: null,
        today: dates.today
      };

    case 'custom':
      return {
        start: filtersDate.dateFrom
          ? moment(filtersDate.dateFrom).startOf('day')
          : 'all',
        end: filtersDate.dateTo
          ? moment(filtersDate.dateTo).startOf('day')
          : dates.today,
        today: dates.today
      };

    case 'today':
      return {
        start: dates.today,
        end: null,
        today: dates.today
      };

    case 'yesterday':
      return {
        start: dates.yesterday,
        end: null,
        today: dates.today
      };

    case 'priorWeek':
    case 'priorMonth':
    case 'priorQuarter':
    case 'priorYear':
      return {
        start: dates[filtersDate.date].start,
        end: dates[filtersDate.date].end,
        today: dates.today,
      };

    default:
      return {
        start: dates[filtersDate.date],
        end: dates.today,
        today: dates.today,
      };
  }
};

export default filterDate;

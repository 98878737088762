import { styled, alpha } from '@mui/material/styles';

export const DotsList = styled('ul')({
  padding: 0,
  margin: '0 -3px',
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
});

export const DotsItem = styled('li')(({ theme, active }) => ({
  margin: '0 3px',
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor:
    active === 'true'
      ? theme.palette.common.black
      : alpha(theme.palette.common.black, 0.15),
}));

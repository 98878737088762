import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortBy: 'date',
  search: '',
  searchOpen: false,
};

export const transactionSortFilterSlice = createSlice({
  name: 'transactionSortFilter',
  initialState,
  reducers: {
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    toggleSearchOpen: (state) => {
      state.search = '';
      state.searchOpen = !state.searchOpen;
    },
  },
});

export const { setSortBy, setSearch, setSearchOpen, toggleSearchOpen } =
transactionSortFilterSlice.actions;

export default transactionSortFilterSlice.reducer;

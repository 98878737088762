import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectNotice } from 'store/selectors';
import LinearProgress from '@mui/material/LinearProgress';

const Notice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading, show, message, variant } = useSelector(selectNotice);

  useEffect(() => {
    if (show) {
      enqueueSnackbar(message, { variant: variant });
    }
  }, [enqueueSnackbar, show, message, variant]);

  return loading ? <LinearProgress /> : null;
};

export default Notice;

import i18n from 'i18n';

const dates = (locales = i18n) => {
  const dates = [
    {
      value: '',
      label: locales.t('filtersDate.allTime'),
    },
    {
      value: 'custom',
      label: locales.t('filtersDate.custom'),
    },
    {
      value: 'today',
      label: locales.t('filtersDate.today'),
    },
    {
      value: 'yesterday',
      label: locales.t('filtersDate.yesterday'),
    },
    {
      value: 'lastWeek',
      label: locales.t('filtersDate.lastWeek'),
    },
    {
      value: 'lastMonth',
      label: locales.t('filtersDate.lastMonth'),
    },
    {
      value: 'lastQuarter',
      label: locales.t('filtersDate.lastQuarter'),
    },
    {
      value: 'lastYear',
      label: locales.t('filtersDate.lastYear'),
    },
    {
      value: 'priorWeek',
      label: locales.t('filtersDate.priorWeek'),
    },
    {
      value: 'priorMonth',
      label: locales.t('filtersDate.priorMonth'),
    },
    {
      value: 'priorQuarter',
      label: locales.t('filtersDate.priorQuarter'),
    },
    {
      value: 'priorYear',
      label: locales.t('filtersDate.priorYear'),
    },
    {
      value: 'monthToDate',
      label: locales.t('filtersDate.monthToDate'),
    },
    {
      value: 'quarterToDate',
      label: locales.t('filtersDate.quarterToDate'),
    },
    {
      value: 'yearToDate',
      label: locales.t('filtersDate.yearToDate'),
    },
  ];

  return dates;
};

export default dates;

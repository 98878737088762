import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as GlobalIcon } from 'assets/images/global-icon.svg';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'components/common/styles';

const LanguagesMenu = ({ colorWhite, children, anchorEl, onOpen, onClose, matches }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 0, marginInlineStart: matches ? 2.25 : 0.5, marginInlineEnd: 0 }}>
      <Tooltip title={t('tooltip.languages')}>
        <StyledButton onClick={onOpen} disableRipple={true}>
          <IconButton component='div'>
            <SvgIcon
              component={GlobalIcon}
              viewBox='0 0 1000 1000'
              width='24'
              height='24'
              color={colorWhite ? 'light' : 'text'}
            />
          </IconButton>
          <Typography
            sx={{
              marginTop: -0.25,
              color: colorWhite ? 'light.main' : 'text.main',
            }}
            variant='body6'
            component='span'
          >
            {t('tooltip.languages')}
          </Typography>
        </StyledButton>
      </Tooltip>
      <Menu
        sx={{ mt: '47px', maxHeight: 'calc(100% - 80px)' }}
        id='languages-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default LanguagesMenu;

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';
import { addPost } from 'store/slices/postsSlice';
import { addCategory, updateDeleteCategory } from './categorySlice';
import { addActivity, updateDeleteActivity } from './activitySlice';
import { addTag, updateDeleteTag } from './tagSlice';

export const addTransaction = createAsyncThunk(
  'transaction/addTransaction',
  async ({ newPost, category, activity, tag, ...data }, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const currency = thunkAPI.getState().currency.currency;
    const docRef = doc(collection(db, 'transaction'));
    const isOwner = uid === data.assetsOwnerId;

    if (data.associatedMemberUid) {
      const assetDocRef = doc(db, 'assets', data.assetsId);

      await updateDoc(assetDocRef, {
        associatedMemberUid: arrayUnion(data.associatedMemberUid),
      });
    }

    const newData = {
      ...data,
      id: docRef.id,
      uid,
      usersId: isOwner ? [uid] : [uid, data.assetsOwnerId],
      currency,
      createdDate: serverTimestamp(),
    };

    const updatePostTransaction = async postId => {
      const postRef = doc(db, 'posts', postId);
      const docSnap = await getDoc(postRef);
      const { repliesId, ...prevPostData } = docSnap.data();

      const transaction = {
        id: docRef.id,
        money: data.money,
        amount: data.amount,
        currency,
      };

      if (
        prevPostData.transaction &&
        prevPostData.transaction.id !== docRef.id
      ) {
        const prevTransactionRef = doc(
          db,
          'transaction',
          prevPostData.transaction.id
        );
        await updateDoc(prevTransactionRef, {
          relatedPostId: null,
        });
      }

      if (repliesId && repliesId.length) {
        await Promise.all(
          repliesId.map(async replyId => {
            return updatePostTransaction(replyId);
          })
        );
      }

      return updateDoc(postRef, {
        transaction,
      });
    };

    if (data.relatedPostId) {
      await updatePostTransaction(data.relatedPostId);
    }

    if (category) {
      const categoryData = {
        category,
        transactionId: docRef.id,
        uid,
      };
      await thunkAPI.dispatch(addCategory(categoryData));
    }

    if (activity) {
      const activityData = {
        activity,
        transactionId: docRef.id,
        uid,
      };
      await thunkAPI.dispatch(addActivity(activityData));
    }

    if (tag) {
      const tagData = {
        tag,
        transactionId: docRef.id,
        uid,
      };
      await thunkAPI.dispatch(addTag(tagData));
    }

    if (newPost) {
      const { payload } = await thunkAPI.dispatch(
        addPost({
          text: newPost,
          images: null,
          assetsId: data.assetsId,
          projectName: data.assetsProjectName,
          transaction: {
            id: docRef.id,
            money: data.money,
            amount: data.amount,
            currency,
          },
          assetsOwnerId: data.assetsOwnerId,
        })
      );
      newData.relatedPostId = payload;
    }

    return setDoc(docRef, newData);
  }
);

export const updateTransaction = createAsyncThunk(
  'transaction/updateTransaction',
  async ({ newPost, category, activity, tag, ...data }, thunkAPI) => {
    const currency = thunkAPI.getState().currency.currency;
    const docRef = doc(db, 'transaction', data.id);
    const docSnapshot = await getDoc(docRef);
    const { relatedPostId, associatedMemberUid } = docSnapshot.data();

    if (data.associatedMemberUid) {
      const assetDocRef = doc(db, 'assets', data.assetsId);

      await updateDoc(assetDocRef, {
        associatedMemberUid: arrayUnion(data.associatedMemberUid),
      });
    } else if (associatedMemberUid) {
      const assetDocRef = doc(db, 'assets', data.assetsId);
      const transactionMemberRef = query(
        collection(db, 'transaction'),
        where('assetsId', '==', data.assetsId),
        where('associatedMemberUid', '==', associatedMemberUid)
      );
      const transactionMemberSnapshot = await getDocs(transactionMemberRef);

      if (transactionMemberSnapshot.size === 1) {
        await updateDoc(assetDocRef, {
          associatedMemberUid: arrayRemove(associatedMemberUid),
        });
      }
    }

    const newData = {
      ...data,
    };

    const updatePostTransaction = async (postId, transaction) => {
      const postRef = doc(db, 'posts', postId);
      const docSnap = await getDoc(postRef);
      const { repliesId, ...prevPostData } = docSnap.data();

      if (
        prevPostData.transaction &&
        prevPostData.transaction.id !== docRef.id
      ) {
        const prevTransactionRef = doc(
          db,
          'transaction',
          prevPostData.transaction.id
        );
        await updateDoc(prevTransactionRef, {
          relatedPostId: null,
        });
      }

      if (repliesId && repliesId.length) {
        await Promise.all(
          repliesId.map(async replyId => {
            return updatePostTransaction(replyId, transaction);
          })
        );
      }

      return await updateDoc(postRef, {
        transaction,
      });
    };

    if (newPost) {
      if (relatedPostId) {
        await updatePostTransaction(relatedPostId, null);
      }
      const { payload } = await thunkAPI.dispatch(
        addPost({
          text: newPost,
          images: null,
          assetsId: data.assetsId,
          assetsOwnerId: data.assetsOwnerId,
          projectName: data.assetsProjectName,
          transaction: {
            id: docRef.id,
            money: data.money,
            amount: data.amount,
            currency,
          },
        })
      );
      newData.relatedPostId = payload;
    } else {
      if (data.relatedPostId || relatedPostId) {
        if (data.relatedPostId !== relatedPostId) {
          if (data.relatedPostId && relatedPostId) {
            await updatePostTransaction(relatedPostId, null);
            await updatePostTransaction(data.relatedPostId, {
              id: docRef.id,
              money: data.money,
              amount: data.amount,
              currency,
            });
          } else if (data.relatedPostId) {
            await updatePostTransaction(data.relatedPostId, {
              id: docRef.id,
              money: data.money,
              amount: data.amount,
              currency,
            });
          } else {
            await updatePostTransaction(relatedPostId, null);
          }
        } else {
          await updatePostTransaction(data.relatedPostId, {
            id: docRef.id,
            money: data.money,
            amount: data.amount,
            currency,
          });
        }
      }
    }

    await thunkAPI.dispatch(updateDeleteCategory(data.id));
    await thunkAPI.dispatch(updateDeleteActivity(data.id));
    await thunkAPI.dispatch(updateDeleteTag(data.id));

    if (category) {
      const categoryData = {
        category,
        transactionId: data.id,
        uid: data.uid,
      };
      await thunkAPI.dispatch(addCategory(categoryData));
    }

    if (activity) {
      const activityData = {
        activity,
        transactionId: data.id,
        uid: data.uid,
      };
      await thunkAPI.dispatch(addActivity(activityData));
    }

    if (tag) {
      const tagData = {
        tag,
        transactionId: data.id,
        uid: data.uid,
      };
      await thunkAPI.dispatch(addTag(tagData));
    }

    return updateDoc(docRef, newData);
  }
);

export const deleteTransaction = createAsyncThunk(
  'transaction/deleteTransaction',
  async (id, thunkAPI) => {
    const docRef = doc(db, 'transaction', id);
    const docSnapshot = await getDoc(docRef);
    const { relatedPostId } = docSnapshot.data();

    const updatePostTransaction = async postId => {
      const postRef = doc(db, 'posts', postId);
      const docSnap = await getDoc(postRef);
      const { repliesId } = docSnap.data();

      const transaction = null;

      if (repliesId && repliesId.length) {
        await Promise.all(
          repliesId.map(async replyId => {
            return updatePostTransaction(replyId);
          })
        );
      }

      return updateDoc(postRef, {
        transaction,
      });
    };

    if (relatedPostId) {
      await updatePostTransaction(relatedPostId);
    }

    await thunkAPI.dispatch(updateDeleteCategory(id));
    await thunkAPI.dispatch(updateDeleteActivity(id));
    await thunkAPI.dispatch(updateDeleteTag(id));

    return deleteDoc(docRef);
  }
);

const initialState = {
  transaction: [],
  transactionMember: [],
  loading: false,
  error: null,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransaction: (state, { payload }) => {
      state.transaction = payload;
      state.loading = false;
    },
    setTransactionMember: (state, { payload }) => {
      state.transactionMember = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          addTransaction.pending,
          updateTransaction.pending,
          deleteTransaction.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addTransaction.fulfilled,
          updateTransaction.fulfilled,
          deleteTransaction.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addTransaction.rejected,
          updateTransaction.rejected,
          deleteTransaction.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setTransaction, setTransactionMember } =
  transactionSlice.actions;

export default transactionSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import emailjs from '@emailjs/browser';

export const sendReports = createAsyncThunk(
  'reports/sendReports',
  async ({ email, membersEmail, file, fileName, excelFileExtension }, thunkAPI) => {
    const currentUser = thunkAPI.getState().auth.user;
    const members = thunkAPI.getState().users.members;
    const filePdf = file.find(fileItem => fileItem.fileFormat === 'pdf');
    const fileExcel = file.find(fileItem => fileItem.fileFormat === 'excel');

    if (email.length) {
      await Promise.all(
        email.map(emailItem => {
          const user =
            currentUser.email === emailItem
              ? currentUser
              : members.find(member => member.email === emailItem);

          return emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
              toUser: user ? user.displayName : 'User',
              fromUser: currentUser.displayName,
              email: emailItem,
              filePdf: filePdf && filePdf.file,
              fileExcel: fileExcel && fileExcel.file,
              filePdfName: fileName,
              fileExcelName: fileName + excelFileExtension,
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          );
        })
      );
    }

    if (membersEmail.length) {
      await Promise.all(
        membersEmail.map(memberEmail => {
          const user = members.find(member => member.email === memberEmail);

          return emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
              toUser: user ? user.displayName : 'User',
              fromUser: currentUser.displayName,
              email: memberEmail,
              filePdf: filePdf && filePdf.file,
              fileExcel: fileExcel && fileExcel.file,
              filePdfName: fileName,
              fileExcelName: fileName + excelFileExtension,
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          );
        })
      );
    }

    return null;
  }
);

const initialState = {
  loading: false,
  error: null,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendReports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendReports.fulfilled, state => {
        state.loading = false;
      })
      .addCase(sendReports.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error;
      });
  },
});

export default reportsSlice.reducer;

import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';

const AllListenersContext = createContext(null);

export const AllListenersProvider = ({ children }) => {
  const [listeners, setListeners] = useState(null);

  const unsubscribe = useCallback(() => {
    listeners.forEach(listener => listener());
  }, [listeners]);

  const value = useMemo(() => {
    return {
      listeners,
      setListeners,
      unsubscribe,
    };
  }, [listeners, unsubscribe]);

  return (
    <AllListenersContext.Provider value={value}>
      {children}
    </AllListenersContext.Provider>
  );
};

export const useAllListeners = () => useContext(AllListenersContext);

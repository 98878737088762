import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'store/slices/authSlice';
import assetsReducer from 'store/slices/assetsSlice';
import liabilitiesReducer from 'store/slices/liabilitiesSlice';
import portfolioReducer from 'store/slices/portfolioSlice';
import categoryReducer from 'store/slices/categorySlice';
import activityReducer from 'store/slices/activitySlice';
import tagReducer from 'store/slices/tagSlice';
import transactionReducer from 'store/slices/transactionSlice';
import noticeReducer from 'store/slices/noticeSlice';
import assetsLiabilitiesSortFilterReducer from 'store/slices/assetsLiabilitiesSortFilterSlice';
import transactionSortFilterReducer from 'store/slices/transactionSortFilterSlice';
import postsReducer from 'store/slices/postsSlice';
import usersReducer from 'store/slices/usersSlice';
import currencyReducer from 'store/slices/currencySlice';
import languageReducer from 'store/slices/languageSlice';
import likesReducer from 'store/slices/likesSlice';
import filtersReducer from 'store/slices/filtersSlice';
import reportsReducer from 'store/slices/reportsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    assets: assetsReducer,
    liabilities: liabilitiesReducer,
    portfolio: portfolioReducer,
    category: categoryReducer,
    activity: activityReducer,
    tag: tagReducer,
    transaction: transactionReducer,
    notice: noticeReducer,
    assetsLiabilitiesSortFilter: assetsLiabilitiesSortFilterReducer,
    transactionSortFilter: transactionSortFilterReducer,
    posts: postsReducer,
    users: usersReducer,
    currency: currencyReducer,
    language: languageReducer,
    likes: likesReducer,
    filters: filtersReducer,
    reports: reportsReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

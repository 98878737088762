import { createSlice, isAnyOf, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  signInWithPopup,
  updateEmail,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  EmailAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  query,
  collection,
  where,
  getDoc,
  getDocs,
  writeBatch,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  auth,
  googleProvider,
  facebookProvider,
  db,
  storage,
  actionCodeSettings,
} from 'firebaseConfig';
import getExtension from 'utils/getExtension';
import { addCurrency } from './currencySlice';
import i18n from 'i18n';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const signIn = createAsyncThunk('auth/signIn', ({ email, password }) =>
  signInWithEmailAndPassword(auth, email, password)
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ firstName, lastName, email, password }) => {
    await createUserWithEmailAndPassword(auth, email, password);
    const { uid, photoURL } = auth.currentUser;
    const docRef = doc(db, 'users', uid);
    const userMetadataRef = doc(db, 'users', uid, 'metadata', 'metadata');

    const userData = {
      displayName: `${firstName} ${lastName}`,
      firstName,
      lastName,
      email,
      photoURL,
    };

    await setDoc(docRef, { ...userData, uid }, { merge: true });
    return setDoc(
      userMetadataRef,
      {
        registrationDate: serverTimestamp(),
        lastActivityDate: serverTimestamp(),
      },
      { merge: true }
    );
  }
);

export const sendSignInLink = createAsyncThunk(
  'auth/sendSignInLink',
  ({ email, assetsId, isMember }) => {
    const { uid } = auth.currentUser;

    return sendSignInLinkToEmail(auth, email, {
      ...actionCodeSettings,
      url: `${actionCodeSettings.url}/register?email=${email}&uid=${uid}${
        assetsId ? `&assetsId=${assetsId}` : ''
      }${isMember ? `&isMember=${true}` : ''}`,
    });
  }
);

export const resetPassword = createAsyncThunk('auth/resetPassword', email =>
  sendPasswordResetEmail(auth, email)
);

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', () =>
  signInWithPopup(auth, googleProvider)
);

export const signInWithFacebook = createAsyncThunk(
  'auth/signInWithFacebook',
  () => signInWithPopup(auth, facebookProvider)
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async ({ currency, ...userData }, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid, photoURL, email } = thunkAPI.getState().auth.user;
    const displayName = `${userData.firstName} ${userData.lastName}`;
    const docRef = doc(db, 'users', uid);
    const postsRef = query(
      collection(db, 'posts'),
      where('usersId', 'array-contains', uid)
    );

    const userDocSnap = await getDoc(docRef);
    const userDocData = userDocSnap.data();
    const postsSnapshot = await getDocs(postsRef);

    let newUserData = {
      ...userData,
      displayName,
    };

    if (userData.email !== email) {
      try {
        await updateEmail(auth.currentUser, userData.email);
        newUserData.email = userData.email;
      } catch (error) {
        try {
          const providerID = auth.currentUser.providerData[0].providerId;

          if (providerID === 'password') {
            const password = prompt(i18n.t('alert.enterPassword'));
            const credential = EmailAuthProvider.credential(
              auth.currentUser.email,
              password
            );
            await reauthenticateWithCredential(auth.currentUser, credential);
          }

          if (providerID === 'google.com') {
            const credential = await new GoogleAuthProvider();
            await reauthenticateWithPopup(auth.currentUser, credential);
          }

          if (providerID === 'facebook.com') {
            const credential = await new FacebookAuthProvider();
            await reauthenticateWithPopup(auth.currentUser, credential);
          }

          await updateEmail(auth.currentUser, userData.email);
          newUserData.email = userData.email;
        } catch (err) {
          newUserData.email = email;
          alert(i18n.t('alert.emailNotChanged'));
        }
      }
    }

    if (userData.photoURL !== photoURL) {
      const storageRef = ref(
        storage,
        `profilePictures/${uid}.${getExtension(userData.photoURL[0].name)}`
      );
      const snapshot = await uploadBytes(storageRef, userData.photoURL[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      newUserData.photoURL = downloadURL;
    }

    await Promise.all(
      postsSnapshot.docs.map(async doc => {
        const postData = doc.data();

        if (postData.uid === uid) {
          return await updateDoc(doc.ref, {
            displayName: newUserData.displayName,
            photoURL: newUserData.photoURL,
          });
        } else {
          return await updateDoc(doc.ref, {
            parentDisplayName: newUserData.displayName,
          });
        }
      })
    );

    if (userDocData.contractorAt && userDocData.contractorAt.length) {
      userDocData.contractorAt.forEach(userId => {
        if (++currentBatchSize >= 500) {
          currentBatch = writeBatch(db);
          batches.push(currentBatch);
          currentBatchSize = 1;
        }

        const userRef = doc(db, 'users', userId, 'contractors', uid);
        currentBatch.update(userRef, {
          displayName: newUserData.displayName,
          photoURL: newUserData.photoURL,
          email: newUserData.email,
        });
      });
    }

    currentBatch.update(docRef, newUserData);

    await thunkAPI.dispatch(addCurrency(currency));

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const updateLastActivityDate = createAsyncThunk(
  'auth/updateLastActivityDate',
  async (_, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const userRef = doc(db, 'users', uid);
    const userMetadataRef = doc(db, 'users', uid, 'metadata', 'metadata');
    const userDocSnap = await getDoc(userRef);
    const userMetadataDocSnap = await getDoc(userMetadataRef);

    if (!userMetadataDocSnap.exists()) {
      const { registrationDate } = userDocSnap.data();

      return setDoc(
        userMetadataRef,
        {
          registrationDate,
          lastActivityDate: serverTimestamp(),
        },
        { merge: true }
      );
    } else {
      return updateDoc(userMetadataRef, {
        lastActivityDate: serverTimestamp(),
      });
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async (_, thunkAPI) => {
  await thunkAPI.dispatch(updateLastActivityDate());
  return firebaseSignOut(auth);
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          signIn.pending,
          signUp.pending,
          signOut.pending,
          resetPassword.pending,
          updateProfile.pending,
          sendSignInLink.pending,
          updateLastActivityDate.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          signIn.fulfilled,
          signUp.fulfilled,
          signInWithGoogle.fulfilled,
          signInWithFacebook.fulfilled,
          resetPassword.fulfilled,
          updateProfile.fulfilled,
          sendSignInLink.fulfilled,
          updateLastActivityDate.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          signIn.rejected,
          signUp.rejected,
          signOut.rejected,
          resetPassword.rejected,
          signInWithGoogle.rejected,
          signInWithFacebook.rejected,
          updateProfile.rejected,
          sendSignInLink.rejected,
          updateLastActivityDate.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;

import { createContext, useState, useMemo, useContext } from 'react'

const ContentShrinkContext = createContext(null)

export const ContentShrinkProvider = ({ children }) => {
  const [shrink, setShrink] = useState(false)

  const value = useMemo(() => {
    return {
      shrink,
      setShrink
    }
  }, [shrink])

  return (
    <ContentShrinkContext.Provider value={value}>
      {children}
    </ContentShrinkContext.Provider>
  )
}

export const useContentShrink = () => useContext(ContentShrinkContext)
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ButtonBase from '@mui/material/ButtonBase';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

export const ContentBlock = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const Form = styled('form')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const FormBottom = styled(Box)({
  marginTop: 'auto',
});

export const FormBottomControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: -24,
  marginRight: -24,
  padding: '6px 18px',
  borderTop: `1px solid ${alpha(theme.palette.grey[700], 0.1)}`,
}));

export const Wrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  paddingTop: 44,
  paddingBottom: 88,
});

export const Main = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const MainContainer = styled(Container)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const Input = styled('input')({
  display: 'none',
});

export const InputTransparent = styled(TextField)(({ theme, error }) => ({
  flexGrow: 1,
  '.MuiInputBase-root': {
    flexDirection: 'column',
    minHeight: '100%',
    padding: 8,
    margin: -8,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    fontSize: '0.875rem',
    lineHeight: 1.7143,
    fontWeight: 400,
    borderRadius: theme.shape.borderRadiusSm,
    ...(error && {
      color: theme.palette.error.main,
    }),
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  '.MuiInputBase-input': {
    flexGrow: 1,
    padding: 0,
  },
}));

export const ColoredBox = styled(Box)(({ bg, theme }) => ({
  position: 'relative',
  zIndex: 1,
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.white,
  transition: theme.transitions.create('padding-bottom'),
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: -96,
    left: -99999,
    right: -99999,
    bottom: -109,
    backgroundImage: `linear-gradient(to top, ${theme.palette[bg].gradientStart2} 14.2%, ${theme.palette[bg].gradientEnd2} 100%)`,
  },
}));

export const ShrinkList = styled(List)(({ shrink, theme }) => ({
  ...(shrink === 'true' && {
    marginTop: -52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('margin-top'),
    '.MuiListItem-root': {
      width: 'auto',
      flex: '1 1 0',
      padding: '0 4px',
      marginBottom: 0,
      textAlign: 'center',
    },
    '.MuiListItemText-secondary': {
      marginBottom: 0,
      fontSize: '0.6875rem',
      lineHeight: 1.8182,
    },
    '.MuiTypography-h2': {
      fontSize: '0.6875rem',
      lineHeight: 2.1819,
      fontWeight: 700,
      fontFamily: theme.typography.fontFamily,
    },
  }),
}));

export const CardHolder = styled(Box)(({ theme, padding }) => ({
  padding: padding ?? 16,
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadiusMd,
  boxShadow: theme.shadows[1],
}));

export const CardInner = styled('div')({
  padding: 16,
});

export const ImageButton = styled(ButtonBase)(({ theme, background }) => ({
  position: 'relative',
  zIndex: 1,
  width: '100%',
  paddingTop: '82.48%',
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadiusSm,
  overflow: 'hidden',
}));

export const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const Video = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const ImageBackdrop = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.black2, 0.5),
}));

export const ImageList = styled('div')(({ small, simple }) => ({
  padding:
    small === 'true'
      ? '8px 16px 8px'
      : simple === 'true'
      ? '16px 0 8px'
      : '24px 24px 8px',
  margin:
    small === 'true'
      ? '0 -16px 8px'
      : simple === 'true'
      ? '0 0 4px'
      : '0 -24px 8px',
  display: 'flex',
  alignItems: 'center',
  overflowY: 'auto',
}));

export const ImageWrapper = styled('div')({
  marginInlineEnd: 16,
  position: 'relative',
  zIndex: 1,
  '&:last-child': {
    marginRight: 0,
  },
});

export const ImageHolder = styled('div')(({ theme, simple }) => ({
  position: 'relative',
  zIndex: 1,
  width: simple === 'true' ? 40 : 80,
  height: simple === 'true' ? 40 : 80,
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadiusSm,
  overflow: 'hidden',
}));

export const ImageIconButton = styled(IconButton)({
  position: 'absolute',
  zIndex: 2,
  top: 0,
  insetInlineEnd: 0,
  transform: 'translate(50%, -50%)',
});

export const ControlBox = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: -24,
  marginRight: -24,
  padding: '11px 13px 12px',
  paddingInlineEnd: '24px',
  width: 'calc(100% + 48px)',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: alpha(theme.palette.grey[700], 0.1),
  },
}));

export const Blockquote = styled('blockquote')(({ theme }) => ({
  maxWidth: 330,
  margin: 'auto auto 0',
  paddingTop: 24,
  color: theme.palette.error.second,
  textAlign: 'center',
  fontStyle: 'italic',
  fontSize: '0.6875rem',
  lineHeight: 1.5714,
}));

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

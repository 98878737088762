import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  getDocs,
  where,
  query,
  arrayRemove,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';

export const addCategory = createAsyncThunk(
  'category/addCategory',
  async ({ category, transactionId, uid }) => {
    const categoryArr = await Promise.all(
      category.map(async ({ id, title }) => {
        const docRef = doc(db, 'category', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'category'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return categoryArr;
  }
);

export const updateDeleteCategory = createAsyncThunk(
  'category/updateDeleteCategory',
  async id => {
    const categoryRef = query(
      collection(db, 'category'),
      where('transactionId', 'array-contains', id)
    );

    const categorySnapshot = await getDocs(categoryRef);

    const newCategories = await Promise.all(
      categorySnapshot.docs.map(async doc => {
        const categoryData = doc.data();

        if (categoryData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: categoryData.transactionId.filter(
              transactionId => transactionId !== id
            ),
          });
          return categoryData.id;
        }
      })
    );

    return newCategories;
  }
);

export const updateDeleteUsersCategory = createAsyncThunk(
  'category/updateDeleteUsersCategory',
  async ({ category, transactionId, uid }) => {
    const categoryRef = query(
      collection(db, 'category'),
      where('uid', '==', uid),
      where('transactionId', 'array-contains', transactionId)
    );

    const categorySnapshot = await getDocs(categoryRef);

    await Promise.all(
      categorySnapshot.docs.map(async doc => {
        const categoryData = doc.data();

        if (categoryData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: categoryData.transactionId.filter(
              transactionItemId => transactionItemId !== transactionId
            ),
          });
          return categoryData.id;
        }
      })
    );

    const categoryArr = await Promise.all(
      category.map(async ({ id, title }) => {
        const docRef = doc(db, 'category', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'category'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return categoryArr;
  }
);

export const updateMembersCategory = createAsyncThunk(
  'category/updateMembersCategory',
  async ({ memberId, categoriesId, prevCategoriesId }, thunkAPI) => {
    
    prevCategoriesId.forEach(async categoryId => {
      if (!categoriesId.includes(categoryId)) {
        const categoryRef = doc(db, 'category', categoryId);
        
        await updateDoc(categoryRef, {
          membersId: arrayRemove(memberId),
        });
      }
    });

    categoriesId.forEach(async categoryId => {
      if (!prevCategoriesId.includes(categoryId)) {
        const categoryRef = doc(db, 'category', categoryId);

        await updateDoc(categoryRef, {
          membersId: arrayUnion(memberId),
        });
      }
    });

    return null;
  }
);

export const getMembersCategoryTransactions = createAsyncThunk(
  'category/getMembersCategoryTransactions',
  async (membersCategory, thunkAPI) => {
    const transactionsArr = await Promise.all(
      membersCategory.map(async ({ transactionId }) => {
        return Promise.all(
          transactionId.map(async transactionItemId => {
            const docRef = doc(db, 'transaction', transactionItemId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              return docSnap.data();
            }
          })
        );
      })
    );

    return transactionsArr.flat();
  }
);

export const editCategoryTitle = createAsyncThunk(
  'category/editCategoryTitle',
  async ({ id, title }) => {
    const docRef = doc(db, 'category', id);

    return updateDoc(docRef, { title });
  }
);

const initialState = {
  category: [],
  membersCategory: [],
  membersCategoryTransaction: [],
  loading: false,
  error: null,
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategory: (state, { payload }) => {
      state.category = payload;
      state.loading = false;
    },
    setMembersCategory: (state, { payload }) => {
      state.membersCategory = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getMembersCategoryTransactions.fulfilled,
        (state, { payload }) => {
          state.membersCategoryTransaction = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          addCategory.pending,
          updateDeleteCategory.pending,
          updateDeleteUsersCategory.pending,
          updateMembersCategory.pending,
          getMembersCategoryTransactions.pending,
          editCategoryTitle.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addCategory.fulfilled,
          updateDeleteCategory.fulfilled,
          updateDeleteUsersCategory.fulfilled,
          updateMembersCategory.fulfilled,
          editCategoryTitle.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addCategory.rejected,
          updateDeleteCategory.rejected,
          updateDeleteUsersCategory.rejected,
          updateMembersCategory.rejected,
          getMembersCategoryTransactions.rejected,
          editCategoryTitle.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setCategory, setMembersCategory } = categorySlice.actions;

export default categorySlice.reducer;

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  getDocs,
  where,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';

export const addPortfolio = createAsyncThunk(
  'portfolio/addPortfolio',
  async ({ portfolio, assetsId, uid }) => {
    const portfolioArr = await Promise.all(
      portfolio.map(async ({ id, title }) => {
        const docRef = doc(db, 'portfolio', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            assetsId: arrayUnion(assetsId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'portfolio'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            assetsId: [assetsId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return portfolioArr;
  }
);

export const updateDeletePortfolio = createAsyncThunk(
  'portfolio/updateDeletePortfolio',
  async id => {
    const portfolioRef = query(
      collection(db, 'portfolio'),
      where('assetsId', 'array-contains', id)
    );

    const portfolioSnapshot = await getDocs(portfolioRef);

    const newPortfolios = await Promise.all(
      portfolioSnapshot.docs.map(async doc => {
        const portfolioData = doc.data();

        if (portfolioData.assetsId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            assetsId: portfolioData.assetsId.filter(assetId => assetId !== id),
          });
          return portfolioData.id;
        }
      })
    );

    return newPortfolios;
  }
);

export const editPortfolioTitle = createAsyncThunk(
  'portfolio/editPortfolioTitle',
  async ({ id, title }) => {
    const docRef = doc(db, 'portfolio', id);

    return updateDoc(docRef, { title });
  }
);

const initialState = {
  portfolio: [],
  loading: false,
  error: null,
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolio: (state, { payload }) => {
      state.portfolio = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(addPortfolio.pending, updateDeletePortfolio.pending, editPortfolioTitle.pending),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(addPortfolio.fulfilled, updateDeletePortfolio.fulfilled, editPortfolioTitle.fulfilled),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(addPortfolio.rejected, updateDeletePortfolio.rejected, editPortfolioTitle.rejected),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setPortfolio } = portfolioSlice.actions;

export default portfolioSlice.reducer;

import LinearProgress from '@mui/material/LinearProgress';
import AppRouter from 'components/router/AppRouter';
import useListeners from 'hooks/useListeners';
import useLanguages from 'hooks/useLanguages';

function App() {
  const { loading } = useListeners();
  useLanguages();

  return loading ? <LinearProgress /> : <AppRouter />;
}

export default App;

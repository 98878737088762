import Header from 'components/Header';
import Nav from 'components/navigation/Nav';
import { Wrapper, Main, MainContainer } from 'components/common/styles';
import { useWelcome } from 'context/WelcomeContext';
import Info from 'components/Info/Info';

const Layout = ({
  title,
  colorWhite,
  contentSm,
  contentLg,
  contentMd,
  button,
  children,
  bg,
  showFilters,
  showInfoButton,
}) => {
  const { isShowWelcome } = useWelcome();

  return (
    <Wrapper
      sx={{
        backgroundColor: bg && bg,
        paddingBottom: contentMd && 3,
      }}
    >
      <Header
        title={title}
        colorWhite={colorWhite}
        button={button}
        fixed={contentLg}
        showFilters={showFilters}
      />
      {isShowWelcome === 'false' && <Nav />}
      <Main
        component='main'
        pt={contentSm ? 0.5 : contentMd ? 2.3125 : contentLg ? 9 : 2}
      >
        <MainContainer>{children}</MainContainer>
        {showInfoButton && <Info />}
      </Main>
    </Wrapper>
  );
};

export default Layout;

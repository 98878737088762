import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  getDocs,
  where,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';

export const addActivity = createAsyncThunk(
  'activity/addActivity',
  async ({ activity, transactionId, uid }) => {
    const activityArr = await Promise.all(
      activity.map(async ({ id, title }) => {
        const docRef = doc(db, 'activity', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'activity'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return activityArr;
  }
);

export const updateDeleteActivity = createAsyncThunk(
  'activity/updateDeleteActivity',
  async id => {
    const activityRef = query(
      collection(db, 'activity'),
      where('transactionId', 'array-contains', id)
    );

    const activitySnapshot = await getDocs(activityRef);

    const newActivities = await Promise.all(
      activitySnapshot.docs.map(async doc => {
        const activityData = doc.data();

        if (activityData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: activityData.transactionId.filter(
              transactionId => transactionId !== id
            ),
          });
          return activityData.id;
        }
      })
    );

    return newActivities;
  }
);

export const updateDeleteUsersActivity = createAsyncThunk(
  'activity/updateDeleteUsersActivity',
  async ({ activity, transactionId, uid }) => {
    const activityRef = query(
      collection(db, 'activity'),
      where('uid', '==', uid),
      where('transactionId', 'array-contains', transactionId)
    );

    const activitySnapshot = await getDocs(activityRef);

    await Promise.all(
      activitySnapshot.docs.map(async doc => {
        const activityData = doc.data();

        if (activityData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: activityData.transactionId.filter(
              transactionItemId => transactionItemId !== transactionId
            ),
          });
          return activityData.id;
        }
      })
    );

    const activityArr = await Promise.all(
      activity.map(async ({ id, title }) => {
        const docRef = doc(db, 'activity', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'activity'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return activityArr;
  }
);

export const editActivityTitle = createAsyncThunk(
  'activity/editActivityTitle',
  async ({ id, title }) => {
    const docRef = doc(db, 'activity', id);

    return updateDoc(docRef, { title });
  }
);

const initialState = {
  activity: [],
  loading: false,
  error: null,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivity: (state, { payload }) => {
      state.activity = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          addActivity.pending,
          updateDeleteActivity.pending,
          updateDeleteUsersActivity.pending,
          editActivityTitle.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addActivity.fulfilled,
          updateDeleteActivity.fulfilled,
          updateDeleteUsersActivity.fulfilled,
          editActivityTitle.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addActivity.rejected,
          updateDeleteActivity.rejected,
          updateDeleteUsersActivity.rejected,
          editActivityTitle.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setActivity } = activitySlice.actions;

export default activitySlice.reducer;

import { auth } from 'firebaseConfig';
import { isSignInWithEmailLink } from 'firebase/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { useWelcome } from 'context/WelcomeContext';

const WelcomeRoute = ({ children }) => {
  const { isShowWelcome } = useWelcome();
  const location = useLocation();

  if (isShowWelcome === 'true') {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      return (
        <Navigate
          to={{ pathname: '/welcome', search: location.search }}
          state={{ isSignInWithLink: true }}
          replace
        />
      );
    } else {
      return (
        <Navigate
          to={{ pathname: '/welcome', search: location.search }}
          replace
        />
      );
    }
  }

  return children;
};

export default WelcomeRoute;

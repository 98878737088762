import axios from 'axios';

export const getCurrency = () => {
  return axios.get(process.env.REACT_APP_IP_API_URL);
};

export const getCurrencyRates = currency => {
  return axios.get(
    `${process.env.REACT_APP_EXCHANGE_RATE_API_URL}${process.env.REACT_APP_EXCHANGE_RATE_API_KEY}/latest/${currency}`
  );
};

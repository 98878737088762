import { DotsList, DotsItem } from './styles';
import { useWelcome } from 'context/WelcomeContext';

const Dots = () => {
  const { activeStep, stepLength } = useWelcome();

  return (
    <DotsList>
      {[...Array(stepLength)].map((_, index) => (
        <DotsItem
          active={activeStep === index ? 'true' : 'false'}
          key={`dot-${index}`}
        />
      ))}
    </DotsList>
  );
};

export default Dots;

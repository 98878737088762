import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  serverTimestamp
} from 'firebase/firestore';

import { db } from 'firebaseConfig';

export const addLikes = createAsyncThunk(
  'likes/addLikes',
  async ({ usersId, postId, assetsId, ...data }, thunkAPI) => {
    const { uid, displayName } = thunkAPI.getState().auth.user;
    const likesRef = doc(db, 'likes', postId);

    const docSnapshot = await getDoc(likesRef);

    if (!docSnapshot.exists()) {
      const newData = {
        usersId,
        postId,
        assetsId,
        likes: [
          {
            ...data,
            authors: [
              {
                uid,
                displayName,
              },
            ],
          },
        ],
        createdDate: serverTimestamp(),
      };

      return setDoc(likesRef, newData);
    } else {
      const { likes } = docSnapshot.data();

      const filterLike = likes.find(like => like.id === data.id);

      if (!filterLike) {
        return updateDoc(likesRef, {
          likes: [
            ...likes,
            {
              ...data,
              authors: [
                {
                  uid,
                  displayName,
                },
              ],
            },
          ],
        });
      } else {
        const oldLikes = likes.filter(like => like.id !== data.id);
        const filterAuthor = filterLike.authors.filter(author => author.uid !== uid);

        return updateDoc(likesRef, {
          likes: [
            ...oldLikes,
            {
              ...data,
              authors: [
                ...filterAuthor,
                {
                  uid,
                  displayName,
                },
              ],
            },
          ],
        });
      }
    }
  }
);

export const deleteLikes = createAsyncThunk(
  'likes/deleteLikes',
  async ({postId, id }, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const likesRef = doc(db, 'likes', postId);

    const docSnapshot = await getDoc(likesRef);
    const { likes } = docSnapshot.data();

    const filteredLikes = likes.filter(like => like.id !== id);
    const currentLike = likes.find(like => like.id === id);
    const authors = currentLike.authors;

    if (authors.length === 1) {
      if (likes.length === 1) {
        return deleteDoc(likesRef);
      } else {
        return updateDoc(likesRef, {
          likes: filteredLikes,
        });
      }
    } else {
      return updateDoc(likesRef, {
        likes: [
          ...filteredLikes,
          {
            ...currentLike,
            authors: authors.filter(author => author.uid !== uid)
          }
        ],
      });
    }
  }
);

const initialState = {
  likes: [],
  loading: false,
  error: null,
};

export const likesSlice = createSlice({
  name: 'likes',
  initialState,
  reducers: {
    setLikes: (state, { payload }) => {
      state.likes = payload ?? [];
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(addLikes.pending, deleteLikes.pending), state => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(isAnyOf(addLikes.fulfilled, deleteLikes.fulfilled), state => {
        state.loading = false;
      })
      .addMatcher(isAnyOf(addLikes.rejected, deleteLikes.rejected), (state, { error }) => {
        state.loading = false;
        state.error = error;
      });
  },
});

export const { setLikes } = likesSlice.actions;

export default likesSlice.reducer;

import { styled } from '@mui/material/styles';

export const BgImage = styled('div')(({ theme }) => ({
  position: 'relative',
  maxHeight: '100%',
  '&::after': {
    content: "''",
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: 0,
    width: '100%',
    height: '50%',
    background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), ${theme.palette.primary.main})`,
  },
  img: {
    width: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
}));

export const BgImageHolder = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
});

import {
  createSlice,
  isPending,
  isFulfilled,
  isRejected,
} from '@reduxjs/toolkit';
import {
  signIn,
  signUp,
  signInWithGoogle,
  signInWithFacebook,
  resetPassword,
  updateProfile,
  sendSignInLink,
} from 'store/slices/authSlice';
import {
  addAssets,
  deleteAssets,
  updateAssets,
} from 'store/slices/assetsSlice';
import {
  addLiability,
  updateLiability,
  deleteLiability,
} from 'store/slices/liabilitiesSlice';
import {
  addTransaction,
  updateTransaction,
  deleteTransaction,
} from 'store/slices/transactionSlice';
import {
  addPost,
  bookmarkPost,
  deletePost,
  updatePost,
  sendPost,
} from 'store/slices/postsSlice';
import {
  findUser,
  addUser,
  deleteUser,
  deleteUserAll,
  updateDeleteUser,
} from 'store/slices/usersSlice';
import {
  getCurrency,
  addCurrency,
  getCurrencyRates,
} from 'store/slices/currencySlice';
import { formatError } from 'utils/formatError';
import i18n from 'i18n';
import { addLikes, deleteLikes } from 'store/slices/likesSlice';
import {
  updateDeleteUsersCategory,
  updateMembersCategory,
  editCategoryTitle,
} from 'store/slices/categorySlice';
import {
  updateDeleteUsersActivity,
  editActivityTitle,
} from 'store/slices/activitySlice';
import { updateDeleteUsersTag, editTagTitle } from 'store/slices/tagSlice';
import { sendReports } from 'store/slices/reportsSlice';
import { editPortfolioTitle } from 'store/slices/portfolioSlice';

const initialState = {
  loading: false,
  show: false,
  message: '',
  variant: 'success',
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        isPending(
          signIn,
          signUp,
          sendSignInLink,
          resetPassword,
          updateProfile,
          addAssets,
          updateAssets,
          deleteAssets,
          addLiability,
          updateLiability,
          deleteLiability,
          addTransaction,
          updateTransaction,
          deleteTransaction,
          addPost,
          deletePost,
          updatePost,
          findUser,
          addUser,
          deleteUser,
          deleteUserAll,
          updateDeleteUser,
          getCurrency,
          addCurrency,
          getCurrencyRates,
          addLikes,
          deleteLikes,
          updateDeleteUsersCategory,
          updateDeleteUsersActivity,
          updateDeleteUsersTag,
          updateMembersCategory,
          sendReports,
          sendPost,
          bookmarkPost,
          editPortfolioTitle,
          editCategoryTitle,
          editActivityTitle,
          editTagTitle
        ),
        state => {
          state.loading = true;
          state.show = false;
        }
      )
      .addMatcher(isPending(signInWithGoogle, signInWithFacebook), state => {
        state.show = false;
      })
      .addMatcher(
        isRejected(
          signIn,
          signUp,
          sendSignInLink,
          signInWithGoogle,
          signInWithFacebook,
          resetPassword,
          updateProfile,
          addAssets,
          updateAssets,
          deleteAssets,
          addLiability,
          updateLiability,
          deleteLiability,
          addTransaction,
          updateTransaction,
          deleteTransaction,
          addPost,
          deletePost,
          updatePost,
          findUser,
          addUser,
          deleteUser,
          deleteUserAll,
          updateDeleteUser,
          getCurrency,
          addCurrency,
          getCurrencyRates,
          addLikes,
          deleteLikes,
          updateDeleteUsersCategory,
          updateDeleteUsersActivity,
          updateDeleteUsersTag,
          updateMembersCategory,
          sendReports,
          sendPost,
          bookmarkPost,
          editPortfolioTitle,
          editCategoryTitle,
          editActivityTitle,
          editTagTitle
        ),
        (state, { error }) => {
          state.show = true;
          state.message = formatError(error);
          state.variant = 'error';
          state.loading = false;
        }
      )
      .addMatcher(
        isFulfilled(
          signIn,
          signUp,
          sendSignInLink,
          signInWithGoogle,
          signInWithFacebook,
          resetPassword,
          updateProfile,
          addAssets,
          updateAssets,
          deleteAssets,
          addLiability,
          updateLiability,
          deleteLiability,
          addTransaction,
          updateTransaction,
          deleteTransaction,
          addPost,
          deletePost,
          updatePost,
          addUser,
          updateDeleteUser,
          updateDeleteUsersCategory,
          updateDeleteUsersActivity,
          updateDeleteUsersTag,
          sendReports,
          sendPost,
          editPortfolioTitle,
          editCategoryTitle,
          editActivityTitle,
          editTagTitle,
          updateMembersCategory,
        ),
        state => {
          state.show = true;
          state.variant = 'success';
          state.loading = false;
        }
      )
      .addMatcher(
        isFulfilled(
          findUser,
          deleteUser,
          deleteUserAll,
          getCurrency,
          addCurrency,
          getCurrencyRates,
          addLikes,
          deleteLikes,
          bookmarkPost
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isFulfilled(signIn, signInWithGoogle, signInWithFacebook),
        state => {
          state.message = i18n.t('notice.login');
        }
      )
      .addMatcher(isFulfilled(signUp), state => {
        state.message = i18n.t('notice.register');
      })
      .addMatcher(isFulfilled(sendSignInLink), state => {
        state.message = i18n.t('notice.sendSignInLink');
      })
      .addMatcher(isFulfilled(resetPassword), state => {
        state.message = i18n.t('notice.resetPassword');
      })
      .addMatcher(isFulfilled(updateProfile), state => {
        state.message = i18n.t('notice.updateProfile');
      })
      .addMatcher(isFulfilled(addAssets), state => {
        state.message = i18n.t('notice.addAssets');
      })
      .addMatcher(isFulfilled(updateAssets), state => {
        state.message = i18n.t('notice.updateAssets');
      })
      .addMatcher(isFulfilled(deleteAssets), state => {
        state.message = i18n.t('notice.deleteAssets');
      })
      .addMatcher(isFulfilled(addLiability), state => {
        state.message = i18n.t('notice.addLiability');
      })
      .addMatcher(isFulfilled(updateLiability), state => {
        state.message = i18n.t('notice.updateLiability');
      })
      .addMatcher(isFulfilled(deleteLiability), state => {
        state.message = i18n.t('notice.deleteLiability');
      })
      .addMatcher(isFulfilled(addTransaction), state => {
        state.message = i18n.t('notice.addTransaction');
      })
      .addMatcher(isFulfilled(updateTransaction), state => {
        state.message = i18n.t('notice.updateTransaction');
      })
      .addMatcher(isFulfilled(deleteTransaction), state => {
        state.message = i18n.t('notice.deleteTransaction');
      })
      .addMatcher(isFulfilled(addPost), state => {
        state.message = i18n.t('notice.addPost');
      })
      .addMatcher(isFulfilled(deletePost), state => {
        state.message = i18n.t('notice.deletePost');
      })
      .addMatcher(isFulfilled(updatePost), state => {
        state.message = i18n.t('notice.updatePost');
      })
      .addMatcher(isFulfilled(addUser), state => {
        state.message = i18n.t('notice.addUser');
      })
      .addMatcher(isFulfilled(updateDeleteUser), state => {
        state.message = i18n.t('notice.updateDeleteUser');
      })
      .addMatcher(isFulfilled(updateDeleteUsersCategory), state => {
        state.message = i18n.t('notice.updateDeleteCategory');
      })
      .addMatcher(isFulfilled(updateDeleteUsersActivity), state => {
        state.message = i18n.t('notice.updateDeleteActivity');
      })
      .addMatcher(isFulfilled(updateDeleteUsersTag), state => {
        state.message = i18n.t('notice.updateDeleteTag');
      })
      .addMatcher(isFulfilled(sendReports, sendPost), state => {
        state.message = i18n.t('notice.sendReports');
      })
      .addMatcher(
        isFulfilled(
          editPortfolioTitle,
          editCategoryTitle,
          editActivityTitle,
          editTagTitle,
          updateMembersCategory
        ),
        state => {
          state.message = i18n.t('notice.edited');
        }
      );
  },
});

export default noticeSlice.reducer;

import { createTheme, alpha } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ChevroneDownIcon } from 'assets/images/chevrone-down-icon.svg';
import colors from 'constants/colors';

let theme = createTheme({});

theme = createTheme(theme, {
  palette: { ...colors },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontWeightSemiBold: 600,
    h1: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: 1.33333,
      letterSpacing: 'normal',
    },
    h1sm: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.25,
      letterSpacing: 'normal',
    },
    h1xs: {
      fontWeight: 600,
      fontSize: '1.625rem',
      lineHeight: 1.3,
      letterSpacing: 'normal',
    },
    h2lg: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.33333,
    },
    h2: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: 'normal',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.3,
      letterSpacing: 'normal',
    },
    h4: {
      letterSpacing: 'normal',
    },
    h5: {
      letterSpacing: 'normal',
    },
    h6: {
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
      fontSize: '0.875rem',
      lineHeight: 1.7143,
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
    },
    body1: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
      lineHeight: 1.53847,
      fontSize: '0.8125rem',
    },
    body3: {
      fontFamily: '"Inter", sans-serif',
      fontSize: '0.8125rem',
      fontWeight: 500,
      lineHeight: 1.84616,
      letterSpacing: 'normal',
    },
    body4: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.33333,
    },
    body5: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.45,
    },
    body6: {
      fontFamily: '"Inter", sans-serif',
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    button: {
      fontFamily: '"Inter", sans-serif',
      fontSize: '0.875rem',
      lineHeight: 1.7143,
      fontWeight: 700,
      letterSpacing: 'normal',
      textTransform: 'normal',
    },
    caption: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
    },
    overline: {
      fontFamily: '"Inter", sans-serif',
      letterSpacing: 'normal',
    },
  },
  shape: {
    borderRadiusXs: 4,
    borderRadiusSm: 8,
    borderRadiusMd: 16,
    borderRadiusLg: 20,
    borderRadiusXl: 24,
  },
  shadows: {
    ...theme.shadows,
    1: `0px 40px 100px ${alpha('#1C1CA6', 0.1)}`,
    2: `0px 40px 100px ${alpha('#1C1CA6', 0.1)}, 0 10px 10px ${alpha(
      '#1C1CA6',
      0.1
    )}`,
    3: `0px 40px 100px ${alpha('#1C1CA6', 0.1)}, 0 10px 20px ${alpha(
      '#1C1CA6',
      0.1
    )}`,
    4: `0 10px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    5: `0 12px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    6: `0 12px 12px ${alpha(theme.palette.common.black, 0.2)}`,
    7: '0px 8px 12px rgba(0, 0, 0, 0.25)',
  },
  zIndex: {
    drawer: 1200,
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: '"Inter", sans-serif',
          letterSpacing: 'normal',
        },
        'input::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },
        '.fancybox__container': {
          zIndex: '2000 !important',
        },
        '.SnackbarContainer-bottom': {
          bottom: '80px !important',
        },
        'h1, h2, h3, h4, h5, h6': {
          margin: '0 0 16px',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: 600,
        },
        'p, ul, ol': {
          margin: '0 0 16px',
        },
        '.SnackbarItem-action': {
          marginInlineStart: 'auto !important',
          paddingInlineStart: '16px !important',
          marginInlineEnd: '-8px !important',
        },
        'div.react-multi-email': {
          minHeight: 56,
          padding: '6px 10px',
          fontSize: theme.typography.body1.fontSize,
          fontWeight: 600,
          lineHeight: 1.4168,
          backgroundColor: theme.palette.light.main,
          borderRadius: 16,
          border: '2px solid transparent',
          boxShadow: '0px 40px 100px rgb(28 28 166 / 10%)',

          '[data-tag]': {
            marginLeft: '0 !important',
            lineHeight: 1.4168,
            color: theme.palette.common.black,
            fontSize: theme.typography.body1.fontSize,
            backgroundColor: theme.palette.grey[300],
          },
          '[data-tag-handle]': {
            '&:hover': {
              color: theme.palette.primary.main
            }
          },
          '> input[type="text"]': {
            margin: '0.14285714em 0',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 600,
            color: theme.palette.common.black,
            fontFamily: theme.typography.fontFamily,
            lineHeight: 1.4168,
          },
          '&.empty > span[data-placeholder]': {
            color: theme.palette.common.grey,
            padding: '0.68em'
          },
          '&.focused': {
            borderColor: theme.palette.primary.main
          }
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: 'inherit',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          transition: theme.transitions.create('opacity'),
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '48px !important',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 500,
          pointerEvents: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: 48,
          height: 48,
          backgroundColor: theme.palette.info.main,
          boxShadow: theme.shadows[4],
          ...(ownerState.big === 'true' && {
            width: 96,
            height: 96,
            fontSize: 32,
          }),
          ...(ownerState.small === 'true' && {
            width: 40,
            height: 40,
            fontSize: 16,
            boxShadow: 'none',
          }),
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 768,
          paddingLeft: 24,
          paddingRight: 24,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.svgstroke === 'true' && {
            fill: 'none',
            stroke: 'currentColor',
          }),
        }),
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: 72,
          borderTopLeftRadius: theme.shape.borderRadiusLg,
          borderTopRightRadius: theme.shape.borderRadiusLg,
          backgroundColor: alpha('#F1F3FE', 0.5),
          backdropFilter: 'blur(22px)',
          overflow: 'hidden',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: 'currentColor',
          minWidth: 70,
          '&:hover': {
            color: theme.palette.primary.main,
          },
          '&.active': {
            '&::before': {
              display: 'block',
            },
            '.hover-filled': {
              fill: 'currentColor',
            },
            '.hover-filled-white': {
              fill: theme.palette.common.white,
            },
            '.hover-filled-stroke-white': {
              fill: theme.palette.common.white,
              stroke: theme.palette.common.white,
            },
            '.hover-stroke-white': {
              stroke: theme.palette.common.white,
            },
            '.path': {
              display: 'none',
            },
            '.active-path': {
              display: 'block',
              '.hover-filled': {
                stroke: 'none',
              },
            },
          },
          '&::before': {
            display: 'none',
            content: '""',
            position: 'absolute',
            zIndex: -1,
            top: 'calc(100% - 4px)',
            left: '50%',
            transform: 'translateX(-50%) rotate(45deg)',
            width: 64,
            height: 64,
            borderRadius: 16,
            backgroundColor: theme.palette.secondary.main,
          },
          '.active-path': {
            display: 'none',
          },
        },
        label: {
          paddingTop: 2,
          fontSize: '0.625rem',
          lineHeight: 1.2,
          minHeight: 24,
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            minHeight: 'auto',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: 40,
          height: 40,
          boxShadow: `0 8px 12px ${alpha(
            theme.palette[ownerState.color].main,
            0.4
          )}`,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          minHeight: 48,
          padding: '12px 16px',
          borderRadius: theme.shape.borderRadiusXl,
          '.MuiButton-startIcon': {
            marginInlineStart: 0,
            marginInlineEnd: 16,
            '& > *:nth-of-type(1)': {
              fontSize: 24,
            },
          },
          ...(ownerState.icon === 'true' && {
            padding: 0,
            width: 24,
            height: 24,
            minWidth: 'auto',
            minHeight: 'auto',
            '&.MuiButton-outlinedPrimary': {
              border: `2px solid ${alpha(theme.palette.common.black, 0.1)}`,
              borderRadius: 6,
              '&:hover': {
                borderColor: alpha(theme.palette.primary.main, 0.5),
              },
              '&:active': {
                borderColor: alpha(theme.palette.primary.main, 0.5),
              },
            },
          }),
          ...(ownerState.inside === 'true' && {
            position: 'absolute',
            top: '50%',
            insetInlineEnd: 4,
            transform: 'translateY(-50%)',
            borderRadius: theme.shape.borderRadiusMd,
            boxShadow: 'none !important',
          }),
          ...(ownerState.icon === 'small' && {
            '.MuiButton-startIcon': {
              marginInlineStart: 0,
              marginInlineEnd: 8,
            },
          }),
        }),
        contained: {
          boxShadow: theme.shadows[4],
          '&:hover': {
            boxShadow: theme.shadows[5],
          },
          '&:active': {
            boxShadow: theme.shadows[6],
          },
        },
        containedWhite: {
          boxShadow: theme.shadows[4],
          '&:hover': {
            boxShadow: theme.shadows[5],
          },
          '&:active': {
            boxShadow: theme.shadows[6],
          },
        },
        containedLight: {
          padding: '20px 16px',
          borderRadius: theme.shape.borderRadiusMd,
          boxShadow: theme.shadows[1],
          '&:hover': {
            boxShadow: theme.shadows[2],
          },
          '&:active': {
            boxShadow: theme.shadows[3],
          },
        },
        containedCyan: {
          minHeight: 'auto',
          padding: '6px 12px',
          boxShadow: 'none',
          borderRadius: theme.shape.borderRadiusMd,
          color: alpha(theme.palette.common.black, 0.5),
          fontSize: '0.8125rem',
          lineHeight: 1.5385,
          fontWeight: 500,
          '&:hover': {
            boxShadow: theme.shadows[4],
          },
          '&:active': {
            boxShadow: theme.shadows[5],
          },
          '.MuiButton-startIcon': {
            marginInlineEnd: 6,
            marginInlineStart: 0,
          },
          '.MuiButton-endIcon': {
            marginInlineStart: 4,
            marginInlineEnd: 0,
          },
        },
        outlinedPrimary: {
          borderColor: theme.palette.primary.light,
        },
        sizeMini: {
          padding: '4px 12px',
          minHeight: 44,
          borderRadius: 12,
          fontSize: '0.75rem',
          '.MuiButton-startIcon': {
            marginInlineEnd: 8,
            '& > :nth-of-type(1)': {
              fontSize: 18,
            },
          },
        },
        sizeSmall: {
          minHeight: 32,
          minWidth: 'auto',
          padding: '4px 20px',
          fontSize: '0.875rem',
          lineHeight: 1.7143,
        },
        sizeMiddle: {
          padding: '12px 32px',
        },
        sizeCommon: {
          padding: '16px 20px',
          justifyContent: 'space-between',
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 1.5,
          color: theme.palette.primary.main,
        },
        sizeCommon2: {
          padding: '16px 20px',
          justifyContent: 'space-between',
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 1.5,
        },
        sizeLarge: {
          padding: '16px 24px',
          lineHeight: 1.6,
          borderRadius: theme.shape.borderRadiusMd,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: () => ({
          '.MuiButtonGroup-grouped': {
            '&:hover': {
              zIndex: 1,
            },
            '&:active': {
              zIndex: 1,
            },
          },
        }),
      },
    },
    MuiStack: {
      styleOverrides: {
        root: () => ({
          '>:not(style)+:not(style)': {
            marginLeft: 0,
            marginInlineStart: 8
          },
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '.MuiBackdrop-root': {
            backgroundColor:
              ownerState.simple === 'true'
                ? 'transparent'
                : alpha(theme.palette.grey[1000], 0.8),
          },
        }),
        paper: {
          maxHeight: 'calc(100% - 48px)',
          height: '100%',
          borderTopLeftRadius: theme.shape.borderRadiusXl,
          borderTopRightRadius: theme.shape.borderRadiusXl,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[7],
        },
        paperAnchorBottom: ({ ownerState }) => ({
          ...(ownerState.drawersmall === 'true' && {
            height: 'auto',
            left: 12,
            right: 12,
            bottom: 20,
            paddingTop: 20,
            borderRadius: theme.shape.borderRadiusXl,
            '&::before': {
              content: '""',
              position: 'absolute',
              zIndex: 1,
              top: 8,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 88,
              height: 4,
              borderRadius: 100,
              backgroundColor: alpha(theme.palette.grey[500], 0.3),
            },
          }),
          ...(ownerState.drawerbig === 'true' && {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxHeight: '100%',
          }),
          ...(ownerState.simple === 'true' && {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            maxHeight: '100%',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: alpha(theme.palette.common.light, 0.8),
            backdropFilter: 'blur(22px)',
          }),
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '16px 20px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.main === 'true' && {
            display: 'flex',
            flexDirection: 'column-reverse',
            marginTop: 0,
            marginBottom: 0,
          }),
          ...(ownerState.column === 'true' && {
            flexDirection: 'column',
          }),
          ...(ownerState.row === 'true' && {
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }),
        }),
        primary: ({ ownerState }) => ({
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          fontWeight: 700,
          ...(ownerState.variant === 'secondary' && {
            fontSize: '0.8125rem',
            lineHeight: 1.8462,
          }),
        }),
        secondary: ({ ownerState }) => ({
          marginBottom: 2,
          ...(ownerState.color === 'white' && {
            color: theme.palette.common.white,
            opacity: 0.7,
          }),
          ...(ownerState.column === 'true' && {
            marginBottom: 0,
          }),
          ...(ownerState.row === 'true' && {
            marginBottom: 0,
            marginInlineEnd: 4,
          }),
          ...(ownerState.variant === 'secondary' && {
            color: theme.palette.text.grey,
            lineHeight: 1.5385,
          }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          flexShrink: 0,
          flexGrow: 1,
          position: 'static',
          maxWidth: 'none',
          marginBottom: 8,
          transform: 'none',
          fontSize: 10,
          lineHeight: 1.6,
          color: alpha(theme.palette.grey[500], 0.6),
          letterSpacing: 0.5,
          textTransform: 'uppercase',
          fontWeight: 700,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 8,
          marginInlineStart: 8,
          transform: 'none',
          fontSize: 11,
          lineHeight: 1.4546,
          letterSpacing: 0.5,
          fontWeight: 700,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 600,
          lineHeight: 1.5,
          '&.MuiInputBase-adornedEnd': {
            paddingInlineEnd: 20,
          },
          ...(ownerState.small === 'true' && {
            '&.MuiOutlinedInput-root': {
              padding: '7px 12px !important',
              borderRadius: 12,
              fontSize: '0.8125rem',
              lineHeight: 1.53845,
              fontWeight: 400,
              '&:not(.Mui-focused):not(.Mui-error)': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.grey[300],
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: 1,
              },
              '.MuiOutlinedInput-input': {
                minHeight: 40,
              },
            },
          }),
        }),
        input: {
          '&::placeholder': {
            opacity: 0.4,
          },
          '&.MuiInputBase-inputSizeSmall': {
            padding: '6.5px 16px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingInlineStart: 4,
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderRadiusMd,
          boxShadow: theme.shadows[1],
          '&:not(.Mui-focused):not(.Mui-error)': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
          '&.MuiInputBase-multiline': {
            padding: 16,
            '.MuiOutlinedInput-input': {
              padding: 0,
            },
          },
          '&.MuiInputBase-sizeSmall': {
            borderRadius: theme.shape.borderRadiusSm,
            '.MuiSelect-icon': {
              insetInlineEnd: 12,
            },
          },
        },
        input: {
          padding: '16.5px 20px',
        },
        notchedOutline: {
          top: 0,
          borderWidth: 2,
          legend: {
            display: 'none !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          display: 'inline-block',
          verticalAlign: 'top',
          fontWeight: 700,
          transition: theme.transitions.create('color'),
          '&:hover': {
            color: theme.palette.primary.main,
          },
          '&.MuiTypography-body4': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          position: 'fixed',
          zIndex: 4000,
          top: 0,
          left: 0,
          width: '100%',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevroneDownIcon,
      },
      styleOverrides: {
        select: {
          padding: '16px 20px !important',
          paddingInlineEnd: '54px !important',
          borderRadius: theme.shape.borderRadiusMd,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '12px !important',
            paddingInlineEnd: '42px !important',
            borderRadius: theme.shape.borderRadiusSm,
          },
        },
        icon: {
          top: '50%',
          insetInlineEnd: 20,
          transform: 'translateY(-50%)',
          color: 'currentColor',
          fill: 'currentColor',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: 32,
          padding: 4,
          backgroundColor: theme.palette.grey[300],
          borderRadius: theme.shape.borderRadiusSm,
        },
        indicator: {
          height: '100%',
          borderRadius: theme.shape.borderRadiusSm,
        },
        flexContainer: {
          position: 'relative',
          zIndex: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          flex: '1 1 0',
          minHeight: 40,
          borderRadius: theme.shape.borderRadiusSm,
          color: 'currentColor',
          '&.Mui-selected': {
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <SvgIcon component={ChevroneDownIcon} color='text' />,
      },
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            insetInlineEnd: 20,
          },
        },
        inputRoot: {
          paddingInlineStart: 14,
        },
        popupIndicator: {
          color: 'inherit',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: 6,
          ...(ownerState.shadow === 'true' && {
            svg: {
              borderRadius: '50%',
              boxShadow: '0px 6px 6px rgba(0, 0, 92, 0.1)',
            },
          }),
          '&.MuiIconButton-sizeSmall': {
            padding: 4,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          marginLeft: -24,
          marginRight: -24,
          padding: '11px 13px 12px',
          paddingInlineEnd: '24px',
          width: 'calc(100% + 48px)',
          '&::before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 1,
            backgroundColor: alpha(theme.palette.grey[700], 0.1),
          },
        },
        label: {
          width: 'calc(100% - 10px - 42px + 9px)',
          paddingInlineStart: 10,
          fontWeight: 700,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 20,
          borderRadius: theme.shape.borderRadiusXs,
          lineHeight: 1,
          fontWeight: 700,
        },
        label: {
          paddingLeft: 6,
          paddingRight: 6,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(theme.palette.grey[700], 0.1),
          borderBottomWidth: 1,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.active === 'true' && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          }),
        }),
      },
    },
  },
});

export default theme;

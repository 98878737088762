import { Navigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Wrapper, Main, MainContainer } from 'components/common/styles';
import bgImage from 'assets/images/welcome-bg.png';
import bgImage2x from 'assets/images/welcome-bg-2x.png';
import bgImage3x from 'assets/images/welcome-bg-3x.png';
import { BgImage, BgImageHolder } from './styles';
import { useWelcome } from 'context/WelcomeContext';
import { useTranslation } from 'react-i18next';

const WelcomeLayout = ({ children, bg }) => {
  const { isShowWelcome } = useWelcome();
  const location = useLocation();
  const { t } = useTranslation();

  if (isShowWelcome === 'false') {
    if (location.state?.isSignInWithLink) {
      return (
        <Navigate to={{ pathname: '/register', search: location.search }} />
      );
    } else {
      return <Navigate to='/' />;
    }
  }

  return (
    <Wrapper
      sx={{
        paddingBottom: 3,
        backgroundColor: bg ? `${bg}.main` : 'background.secondary',
      }}
    >
      {bg && (
        <BgImageHolder>
          <BgImage>
            <Box
              component='img'
              src={bgImage}
              srcSet={`${bgImage} 1x, ${bgImage2x} 2x, ${bgImage3x} 3x`}
              alt={t('logo')}
            ></Box>
          </BgImage>
        </BgImageHolder>
      )}
      <Main component='main' sx={{ position: 'relative', zIndex: 1 }}>
        <MainContainer>{children}</MainContainer>
      </Main>
    </Wrapper>
  );
};

export default WelcomeLayout;

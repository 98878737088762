import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as DashboardIcon } from 'assets/images/dashboard-icon.svg';
import { ReactComponent as DocumentIcon } from 'assets/images/document-icon.svg';
import { ReactComponent as WalletFillIcon } from 'assets/images/wallet-fill-icon.svg';
import { ReactComponent as FolderFillIcon } from 'assets/images/folder-fill-icon.svg';

const navigation = [
  {
    title: 'navigation.home',
    to: '/home',
    icon: <SvgIcon component={DashboardIcon} svgstroke='true' />,
  },
  {
    title: 'navigation.notifications',
    to: '/notifications',
    icon: <SvgIcon component={DocumentIcon} svgstroke='true' />,
  },
  {
    actionBtn: true,
    title: 'navigation.actionButton',
  },
  {
    title: 'navigation.transactions',
    to: '/money-in-out',
    icon: <SvgIcon component={WalletFillIcon} svgstroke='true' />,
  },
  {
    title: 'navigation.projects',
    to: '/assets-liabilities',
    icon: <SvgIcon component={FolderFillIcon} svgstroke='true' />,
  },
];

export default navigation;

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

export const HeaderAppBar = styled(AppBar)(({theme, shrink, fixed}) => ({
  position: 'relative',
  zIndex: 50,
  paddingRight: '0 !important',
  opacity: shrink === 'true' ? 0 : 1,
  pointerEvents: shrink === 'true' ? 'none' : 'all',
  ...(fixed === 'true' && {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    paddingTop: 44,
    paddingBottom: 8,
    backgroundColor: alpha(theme.palette.common.light, 0.6),
    backdropFilter: 'blur(22px)'
  }),
}))

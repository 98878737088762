import InfoDrawer from 'components/InfoDrawer';
import { useState } from 'react';
import InfoButton from '../InfoButton';
import { Trans } from 'react-i18next';
import { Root, DefinitionList, DefinitionListItem } from './styles';

const Info = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  return (
    <Root>
      <InfoButton onClick={toggleDrawer} />
      <InfoDrawer open={open} toggleDrawer={toggleDrawer}>
        <Trans i18nKey='infoBLock.list'>
          <DefinitionList>
            <DefinitionListItem>
              <strong>Asset/Project Examples:</strong> Home, Car, Vehicles,
              Building, Business, Equipment, Land, Livestock, Inventory
              Furniture, Bank Account, etc. If you are making payments on an
              asset, it is not a liability. Even though it may feel like a house
              or car with payments is a Liability, the house or car is not the
              liability. The liability is the loan which you used to purchase
              the House or Car Asset.
            </DefinitionListItem>
            <DefinitionListItem>
              <strong>Liability Examples:</strong> Loan, Mortgage, Debt, Money
              owed, Taxes owed.
            </DefinitionListItem>
            <DefinitionListItem>
              <strong>Money In Examples:</strong> Rental Income, Business
              Profit, Sales, Bonus, Salary received, or Money received into the
              Asset/Project.
            </DefinitionListItem>
            <DefinitionListItem>
              <strong>Money Out Examples:</strong> Ongoing Expenses, Interest on
              Loan, Rent Paid, Utilities, Wages paid, Salaries Paid,
              Maintenance, Insurance, Cost of Goods Sold, Repairs, Advertising,
              Travel, Fuel, or Money Paid Out.
            </DefinitionListItem>
          </DefinitionList>
        </Trans>
      </InfoDrawer>
    </Root>
  );
};

export default Info;

import i18n from 'i18n';

export const formatError = error => {
  if (!error || !error.code) {
    return i18n.t('errors.error');
  }
  switch (error.code.trim()) {
    case 'auth/email-already-in-use':
      return i18n.t('errors.emailInUse');
    case 'auth/invalid-email':
      return i18n.t('errors.invalidEmail');
    case 'auth/operation-not-allowed':
      return i18n.t('errors.operationNotAllowed');
    case 'auth/weak-password':
      return i18n.t('errors.weakPassword');
    case 'auth/user-not-found':
      return i18n.t('errors.userNotFound');
    case 'auth/missing-email':
      return i18n.t('errors.missingEmail');
    case 'auth/popup-closed-by-user':
      return i18n.t('errors.popupClosedByUser');
    case 'auth/cancelled-popup-request':
      return i18n.t('errors.popupClosed');
    case 'auth/wrong-password':
      return i18n.t('errors.wrongPassword');
    default:
      return error.code;
  }
};

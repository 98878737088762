import moment from 'moment';

const formatDate = date => {
  if (!date) return null;

  return moment(date.toDate()).format('ll');
};

export const formatMonthDate = date => {
  if (!date) return null;

  return moment(date.toDate()).format('MMM DD');
};

export const fromNow = date => {
  if (!date) return null;

  return moment() - moment(date);
};

export const getCurrentYear = () => {
  return moment().year();
};

export const getCurrentDate = (date = null) => {
  return date
    ? moment(date.toDate()).format('MM.DD.YYYY')
    : moment().format('MM.DD.YYYY');
};

export default formatDate;

import { styled } from '@mui/material/styles';

export const Root = styled('div')({
  position: 'fixed',
  zIndex: 50,
  bottom: 88,
  right: 16,
});

export const DefinitionList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: 24
}));

export const DefinitionListItem = styled('li')(({ theme }) => ({
  '&::marker': {
    color: theme.palette.primary.main,
    fontSize: 20
  },
}));

import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { deleteAssets } from 'store/slices/assetsSlice';
import { updateDeletePortfolio } from 'store/slices/portfolioSlice';
import {
  updateDeleteCategory,
  updateDeleteUsersCategory,
} from 'store/slices/categorySlice';
import {
  updateDeleteActivity,
  updateDeleteUsersActivity,
} from 'store/slices/activitySlice';
import { updateDeleteTag, updateDeleteUsersTag } from 'store/slices/tagSlice';

const initialState = {
  uid: null,
  transactions: null,
  portfolios: null,
  categories: null,
  activities: null,
  tags: null,
  assetsActivationDate: null,
  transactionsPaymentDate: null,
  notificationsCreatedDate: null,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.uid = payload;
      state.transactions =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.transactionsFilters ?? null;
      state.portfolios =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.portfoliosFilters ?? null;
      state.categories =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.categoriesFilters ?? null;
      state.activities =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.activitiesFilters ?? null;
      state.tags =
        JSON.parse(localStorage.getItem(`filters_${payload}`))?.tagsFilters ??
        null;
      state.assetsActivationDate =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.assetsActivationDateFilters ?? null;
      state.transactionsPaymentDate =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.transactionsPaymentDateFilters ?? null;
      state.notificationsCreatedDate =
        JSON.parse(localStorage.getItem(`filters_${payload}`))
          ?.notificationsCreatedDateFilters ?? null;
    },
    addFilters: (state, { payload }) => {
      state[payload.name] = payload.data;
      const filtersData =
        JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

      localStorage.setItem(
        `filters_${state.uid}`,
        JSON.stringify({
          ...filtersData,
          [`${payload.name}Filters`]: payload.data,
        })
      );
    },
    clearFilters: (state, { payload }) => {
      localStorage.removeItem(`filters_${payload}`);
      return { ...initialState, uid: payload };
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isFulfilled(deleteAssets), (state, { payload }) => {
        const filtersData =
          JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

        if (!!filtersData.transactionsFilters) {
          const filterTransactions = filtersData.transactionsFilters.filter(
            transaction => transaction !== payload
          );

          if (filterTransactions.length === 0) {
            state.transactions = null;
            localStorage.setItem(
              `filters_${state.uid}`,
              JSON.stringify({
                ...filtersData,
                transactionsFilters: null,
              })
            );
          } else {
            state.transactions = filterTransactions;
            localStorage.setItem(
              `filters_${state.uid}`,
              JSON.stringify({
                ...filtersData,
                transactionsFilters: filterTransactions,
              })
            );
          }
        }
      })
      .addMatcher(isFulfilled(updateDeletePortfolio), (state, { payload }) => {
        const filtersData =
          JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

        if (!!filtersData.portfoliosFilters) {
          const filterPortfolios = filtersData.portfoliosFilters.filter(
            portfolio => payload.includes(portfolio)
          );

          if (filterPortfolios.length === 0) {
            state.portfolios = null;
            localStorage.setItem(
              `filters_${state.uid}`,
              JSON.stringify({
                ...filtersData,
                portfoliosFilters: null,
              })
            );
          } else {
            state.portfolios = filterPortfolios;
            localStorage.setItem(
              `filters_${state.uid}`,
              JSON.stringify({
                ...filtersData,
                portfoliosFilters: filterPortfolios,
              })
            );
          }
        }
      })
      .addMatcher(
        isFulfilled(updateDeleteCategory, updateDeleteUsersCategory),
        (state, { payload }) => {
          const filtersData =
            JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

          if (!!filtersData.categoriesFilters) {
            const filterCategories = filtersData.categoriesFilters.filter(
              category => payload.includes(category)
            );

            if (filterCategories.length === 0) {
              state.categories = null;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  categoriesFilters: null,
                })
              );
            } else {
              state.categories = filterCategories;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  categoriesFilters: filterCategories,
                })
              );
            }
          }
        }
      )
      .addMatcher(
        isFulfilled(updateDeleteActivity, updateDeleteUsersActivity),
        (state, { payload }) => {
          const filtersData =
            JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

          if (!!filtersData.activitiesFilters) {
            const filterActivities = filtersData.activitiesFilters.filter(
              activity => payload.includes(activity)
            );

            if (filterActivities.length === 0) {
              state.activities = null;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  activitiesFilters: null,
                })
              );
            } else {
              state.activities = filterActivities;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  activitiesFilters: filterActivities,
                })
              );
            }
          }
        }
      )
      .addMatcher(
        isFulfilled(updateDeleteTag, updateDeleteUsersTag),
        (state, { payload }) => {
          const filtersData =
            JSON.parse(localStorage.getItem(`filters_${state.uid}`)) ?? {};

          if (!!filtersData.tagsFilters) {
            const filterTags = filtersData.tagsFilters.filter(tag =>
              payload.includes(tag)
            );

            if (filterTags.length === 0) {
              state.tags = null;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  tagsFilters: null,
                })
              );
            } else {
              state.tags = filterTags;
              localStorage.setItem(
                `filters_${state.uid}`,
                JSON.stringify({
                  ...filtersData,
                  tagsFilters: filterTags,
                })
              );
            }
          }
        }
      );
  },
});

export const { addFilters, setFilters, clearFilters } = filtersSlice.actions;

export default filtersSlice.reducer;

import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'store/slices/authSlice';
import { selectAuth } from 'store/selectors';
import UserMenu from 'components/UserMenu';
import { useContentShrink } from 'context/ContentShrinkContext';
import { HeaderAppBar } from './styles';
import { useAllListeners } from 'context/AllListenersContext';
import LanguagesMenu from 'components/LanguagesMenu';
import useAnchorMenu from 'hooks/UseAnchorMenu';
import userNavigation from 'constants/userNavigation';
import { languages } from 'constants/languages';
import { useWelcome } from 'context/WelcomeContext';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { StyledButton } from 'components/common/styles';
import { ReactComponent as FiltersIcon } from 'assets/images/filters-icon.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = ({ title, colorWhite, button, fixed, showFilters }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector(selectAuth);
  const { shrink } = useContentShrink();
  const { unsubscribe } = useAllListeners();
  const anchorUser = useAnchorMenu();
  const anchorLanguages = useAnchorMenu();
  const { isShowWelcome } = useWelcome();
  const {
    t,
    i18n: { changeLanguage, resolvedLanguage },
  } = useTranslation();
  const matches = useMediaQuery('(min-width:480px)');

  const handleNavigate = useCallback(
    path => {
      anchorUser.handleCloseMenu();
      navigate(location.pathname + path, { state: { background: location } });
    },
    [anchorUser, location, navigate]
  );

  const handleSignOut = useCallback(() => {
    unsubscribe();
    anchorUser.handleCloseMenu();
    dispatch(signOut());
  }, [anchorUser, dispatch, unsubscribe]);

  const handleChangeLanguage = useCallback(
    language => {
      anchorLanguages.handleCloseMenu();
      changeLanguage(language);
    },
    [anchorLanguages, changeLanguage]
  );

  const renderLanguagesMenu = useMemo(() => {
    return languages.map(({ code, name }) => (
      <MenuItem
        onClick={() => handleChangeLanguage(code)}
        key={code}
        active={code === resolvedLanguage ? 'true' : 'false'}
      >
        <Typography textAlign='center'>{name}</Typography>
      </MenuItem>
    ));
  }, [handleChangeLanguage, resolvedLanguage]);

  const renderUserMenu = useMemo(() => {
    return userNavigation.map(({ title, to, divider }, idx) =>
      divider ? (
        <Divider key={idx} />
      ) : (
        <MenuItem onClick={() => handleNavigate(to)} key={to}>
          <Typography textAlign='center'>{t(title)}</Typography>
        </MenuItem>
      )
    );
  }, [handleNavigate, t]);

  return (
    <HeaderAppBar
      shrink={shrink ? 'true' : 'false'}
      fixed={fixed ? 'true' : 'false'}
    >
      <Container>
        <Toolbar disableGutters>
          <Typography
            variant='h3'
            noWrap
            sx={{ flexGrow: 1, color: colorWhite && 'common.white'}}
          >
            {title}
          </Typography>
          {user && isShowWelcome === 'false' && (
            <>
              {button && button}
              {showFilters && (
                <Tooltip title={t('tooltip.filters')}>
                  <StyledButton
                    onClick={() => handleNavigate('/filters')}
                    sx={{ marginInlineStart: matches ? 2.25 : 0.5 }}
                    disableRipple={true}
                  >
                    <IconButton component='div'>
                      <SvgIcon
                        component={FiltersIcon}
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        svgstroke='true'
                        color={colorWhite ? 'white' : 'text'}
                      />
                    </IconButton>
                    <Typography
                      sx={{
                        marginTop: -0.25,
                        color: colorWhite ? 'white.main' : 'text.main',
                      }}
                      variant='body6'
                      component='span'
                    >
                      {t('tooltip.filters')}
                    </Typography>
                  </StyledButton>
                </Tooltip>
              )}
              <LanguagesMenu
                colorWhite={colorWhite}
                anchorEl={anchorLanguages.anchorEl}
                onOpen={anchorLanguages.handleOpenMenu}
                onClose={anchorLanguages.handleCloseMenu}
                matches={matches}
              >
                {renderLanguagesMenu}
              </LanguagesMenu>
              <UserMenu
                user={user}
                anchorEl={anchorUser.anchorEl}
                onOpen={anchorUser.handleOpenMenu}
                onClose={anchorUser.handleCloseMenu}
                matches={matches}
              >
                {renderUserMenu}
                <Divider />
                <MenuItem onClick={handleSignOut}>
                  <Typography textAlign='center' color='error'>
                    {t('action.signOut')}
                  </Typography>
                </MenuItem>
              </UserMenu>
            </>
          )}
        </Toolbar>
      </Container>
    </HeaderAppBar>
  );
};
export default Header;

import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {
  doc,
  getDoc,
  query,
  collection,
  where,
  setDoc,
  orderBy,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import { auth, db } from 'firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, updateLastActivityDate } from 'store/slices/authSlice';
import { setAssets, setAssetsMember } from 'store/slices/assetsSlice';
import { setLiabilities } from 'store/slices/liabilitiesSlice';
import { setPortfolio } from 'store/slices/portfolioSlice';
import {
  setCategory,
  setMembersCategory,
  getMembersCategoryTransactions,
} from 'store/slices/categorySlice';
import { setActivity } from 'store/slices/activitySlice';
import { setTag } from 'store/slices/tagSlice';
import {
  setTransaction,
  setTransactionMember,
} from 'store/slices/transactionSlice';
import { setPosts } from 'store/slices/postsSlice';
import { setMembers, setUsers } from 'store/slices/usersSlice';
import { setCurrency, getCurrency } from 'store/slices/currencySlice';
import { sortArr } from 'utils/sortArr';
import { useAllListeners } from 'context/AllListenersContext';
import { getCurrencyRates } from 'store/slices/currencySlice';
import { setLikes } from 'store/slices/likesSlice';
import { selectAuth } from 'store/selectors';
import { setFilters } from 'store/slices/filtersSlice';

const useListeners = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { setListeners } = useAllListeners();
  const currentAuth = useSelector(selectAuth);

  useEffect(() => {
    dispatch(setFilters(currentAuth?.user?.uid));
  }, [currentAuth?.user?.uid, dispatch]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const { uid, displayName, email, photoURL } = user;
        const userRef = doc(db, 'users', uid);
        const userMetadataRef = doc(db, 'users', uid, 'metadata', 'metadata');
        const assetsRef = query(
          collection(db, 'assets'),
          where('usersId', 'array-contains', uid)
        );
        const assetsMemberRef = query(
          collection(db, 'assets'),
          where('associatedMemberUid', 'array-contains', uid)
        );
        const liabilitiesRef = query(
          collection(db, 'liabilities'),
          where('uid', '==', uid)
        );
        const portfolioRef = query(
          collection(db, 'portfolio'),
          where('uid', '==', uid)
        );
        const categoryRef = query(
          collection(db, 'category'),
          where('uid', '==', uid)
        );
        const activityRef = query(
          collection(db, 'activity'),
          where('uid', '==', uid)
        );
        const tagRef = query(collection(db, 'tag'), where('uid', '==', uid));
        const transactionRef = query(
          collection(db, 'transaction'),
          where('usersId', 'array-contains', uid)
        );
        const transactionMemberRef = query(
          collection(db, 'transaction'),
          where('associatedMemberUid', '==', uid)
        );
        const postsRef = query(
          collection(db, 'posts'),
          where('usersId', 'array-contains', uid),
          orderBy('createdDate', 'desc')
        );
        const contractorsRef = query(
          collection(db, 'users', uid, 'contractors')
        );
        const membersRef = query(
          collection(db, 'users'),
          where('memberAt', 'array-contains', uid)
        );
        const likesRef = query(
          collection(db, 'likes'),
          where('usersId', 'array-contains', uid)
        );
        const membersCategoryRef = query(
          collection(db, 'category'),
          where('membersId', 'array-contains', uid)
        );

        const docSnap = await getDoc(userRef);

        if (!docSnap.exists()) {
          const userData = {
            uid: uid,
            displayName,
            firstName: displayName ? displayName.split(' ')[0] : '',
            lastName: displayName ? displayName.split(' ')[1] : '',
            email: email ? email : '',
            photoURL,
          };

          await setDoc(userRef, userData, { merge: true });
          await setDoc(
            userMetadataRef,
            {
              registrationDate: serverTimestamp(),
              lastActivityDate: serverTimestamp(),
            },
            { merge: true }
          );
        } else {
          dispatch(updateLastActivityDate());
        }

        const assetsUnsubscribe = onSnapshot(assetsRef, querySnapshot => {
          const assets = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setAssets(sortArr(assets, 'projectName')));
          dispatch(getCurrencyRates());
          dispatch(updateLastActivityDate());
        });

        const assetsMemberUnsubscribe = onSnapshot(
          assetsMemberRef,
          querySnapshot => {
            const assets = querySnapshot.docs.map(doc => {
              return doc.data();
            });
            dispatch(setAssetsMember(sortArr(assets, 'projectName')));
            dispatch(getCurrencyRates());
            dispatch(updateLastActivityDate());
          }
        );

        const liabilitiesUnsubscribe = onSnapshot(
          liabilitiesRef,
          querySnapshot => {
            const liabilities = querySnapshot.docs.map(doc => {
              return doc.data();
            });
            dispatch(setLiabilities(sortArr(liabilities, 'displayName')));
            dispatch(updateLastActivityDate());
          }
        );

        const portfolioUnsubscribe = onSnapshot(portfolioRef, querySnapshot => {
          const portfolio = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setPortfolio(sortArr(portfolio, 'title')));
          dispatch(updateLastActivityDate());
        });

        const categoryUnsubscribe = onSnapshot(categoryRef, querySnapshot => {
          const category = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setCategory(sortArr(category, 'title')));
          dispatch(updateLastActivityDate());
        });

        const membersCategoryUnsubscribe = onSnapshot(
          membersCategoryRef,
          querySnapshot => {
            const membersCategory = querySnapshot.docs.map(doc => {
              return doc.data();
            });

            dispatch(setMembersCategory(sortArr(membersCategory, 'title')));
            dispatch(getMembersCategoryTransactions(membersCategory));
            dispatch(updateLastActivityDate());
          }
        );

        const activityUnsubscribe = onSnapshot(activityRef, querySnapshot => {
          const activity = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setActivity(sortArr(activity, 'title')));
          dispatch(updateLastActivityDate());
        });

        const tagUnsubscribe = onSnapshot(tagRef, querySnapshot => {
          const tag = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setTag(sortArr(tag, 'title')));
          dispatch(updateLastActivityDate());
        });

        const transactionUnsubscribe = onSnapshot(
          transactionRef,
          querySnapshot => {
            const transaction = querySnapshot.docs.map(doc => {
              return doc.data();
            });
            dispatch(setTransaction(transaction));
            dispatch(getCurrencyRates());
            dispatch(updateLastActivityDate());
          }
        );

        const transactionMemberUnsubscribe = onSnapshot(
          transactionMemberRef,
          querySnapshot => {
            const transaction = querySnapshot.docs.map(doc => {
              return doc.data();
            });
            dispatch(setTransactionMember(transaction));
            dispatch(getCurrencyRates());
            dispatch(updateLastActivityDate());
          }
        );

        const postsUnsubscribe = onSnapshot(postsRef, querySnapshot => {
          const posts = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setPosts(posts));
          dispatch(updateLastActivityDate());
        });

        const likesUnsubscribe = onSnapshot(likesRef, querySnapshot => {
          const likes = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setLikes(likes));
          dispatch(updateLastActivityDate());
        });

        const contractorsUnsubscribe = onSnapshot(
          contractorsRef,
          querySnapshot => {
            const contractors = querySnapshot.docs.map(doc => {
              return doc.data();
            });
            dispatch(setUsers(sortArr(contractors, 'displayName')));
            dispatch(updateLastActivityDate());
          }
        );

        const membersUnsubscribe = onSnapshot(membersRef, querySnapshot => {
          const members = querySnapshot.docs.map(doc => {
            return doc.data();
          });
          dispatch(setMembers(sortArr(members, 'displayName')));
          dispatch(updateLastActivityDate());
        });

        const userUnsubscribe = onSnapshot(userRef, doc => {
          const data = doc.data();

          if (data && data.currency) {
            dispatch(setCurrency(data.currency));
          } else {
            dispatch(getCurrency(uid));
          }

          dispatch(setUser(data));
          dispatch(getCurrencyRates());
          dispatch(updateLastActivityDate());
          setLoading(false);
        });

        setListeners([
          assetsUnsubscribe,
          assetsMemberUnsubscribe,
          liabilitiesUnsubscribe,
          portfolioUnsubscribe,
          categoryUnsubscribe,
          activityUnsubscribe,
          tagUnsubscribe,
          transactionUnsubscribe,
          transactionMemberUnsubscribe,
          postsUnsubscribe,
          contractorsUnsubscribe,
          membersUnsubscribe,
          userUnsubscribe,
          likesUnsubscribe,
          membersCategoryUnsubscribe,
        ]);
      } else {
        dispatch(setUser(null));
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, setListeners]);

  return { loading };
};

export default useListeners;

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  getDocs,
  where,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';

export const addTag = createAsyncThunk(
  'tag/addTag',
  async ({ tag, transactionId, uid }) => {
    const tagArr = await Promise.all(
      tag.map(async ({ id, title }) => {
        const docRef = doc(db, 'tag', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'tag'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return tagArr;
  }
);

export const updateDeleteTag = createAsyncThunk(
  'tag/updateDeleteTag',
  async id => {
    const tagRef = query(
      collection(db, 'tag'),
      where('transactionId', 'array-contains', id)
    );

    const tagSnapshot = await getDocs(tagRef);

    const newTags = await Promise.all(
      tagSnapshot.docs.map(async doc => {
        const tagData = doc.data();

        if (tagData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: tagData.transactionId.filter(
              transactionId => transactionId !== id
            ),
          });
          return tagData.id;
        }
      })
    );

    return newTags;
  }
);

export const updateDeleteUsersTag = createAsyncThunk(
  'tag/updateDeleteUsersTag',
  async ({ tag, transactionId, uid }) => {
    const tagRef = query(
      collection(db, 'tag'),
      where('uid', '==', uid),
      where('transactionId', 'array-contains', transactionId)
    );

    const tagSnapshot = await getDocs(tagRef);

    await Promise.all(
      tagSnapshot.docs.map(async doc => {
        const tagData = doc.data();

        if (tagData.transactionId.length === 1) {
          await deleteDoc(doc.ref);
          return null;
        } else {
          await updateDoc(doc.ref, {
            transactionId: tagData.transactionId.filter(
              transactionItemId => transactionItemId !== transactionId
            ),
          });
          return tagData.id;
        }
      })
    );

    const tagArr = await Promise.all(
      tag.map(async ({ id, title }) => {
        const docRef = doc(db, 'tag', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            transactionId: arrayUnion(transactionId),
          });
          return docRef.id;
        } else {
          const docRef = doc(collection(db, 'tag'));

          await setDoc(docRef, {
            id: docRef.id,
            uid,
            title,
            transactionId: [transactionId],
            createdDate: serverTimestamp(),
          });

          return docRef.id;
        }
      })
    );

    return tagArr;
  }
);

export const editTagTitle = createAsyncThunk(
  'tag/editTagTitle',
  async ({ id, title }) => {
    const docRef = doc(db, 'tag', id);

    return updateDoc(docRef, { title });
  }
);

const initialState = {
  tag: [],
  loading: false,
  error: null,
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTag: (state, { payload }) => {
      state.tag = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          addTag.pending,
          updateDeleteTag.pending,
          updateDeleteUsersTag.pending,
          editTagTitle.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addTag.fulfilled,
          updateDeleteTag.fulfilled,
          updateDeleteUsersTag.fulfilled,
          editTagTitle.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addTag.rejected,
          updateDeleteTag.rejected,
          updateDeleteUsersTag.rejected,
          editTagTitle.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setTag } = tagSlice.actions;

export default tagSlice.reducer;

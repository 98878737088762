import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Tooltip from '@mui/material/Tooltip';
import NavBtn from 'components/navigation/NavBtn';
import { NavWrapper } from './styles';
import navigation from 'constants/navigation';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/selectors';
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { user } = useSelector(selectAuth);
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <NavWrapper>
      <BottomNavigation showLabels>
        {navigation.map(({ actionBtn, title, ...rest }) => (
          <Tooltip title={t(title)} key={title}>
            <BottomNavigationAction
              component={actionBtn ? NavBtn : NavLink}
              {...rest}
              label={t(title)}
            />
          </Tooltip>
        ))}
      </BottomNavigation>
    </NavWrapper>
  );
};

export default memo(Nav);

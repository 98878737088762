const userNavigation = [
  {
    title: 'pages.profile',
    to: '/profile',
  },
  {
    title: 'pages.portfolio',
    to: '/portfolio',
  },
  {
    title: 'pages.category',
    to: '/category',
  },
  {
    title: 'pages.activity',
    to: '/activity',
  },
  {
    title: 'pages.tag',
    to: '/tag',
  },
  {
    title: 'pages.categoriesByActivities',
    to: '/categories-by-activities',
  },
  {
    title: 'pages.categoriesByTags',
    to: '/categories-by-tags',
  },
  {
    title: 'pages.users',
    to: '/users',
  },
  {
    title: 'pages.members',
    to: '/members',
  },
  { divider: true },
  {
    title: 'pages.privacyPolicy',
    to: '/privacy-policy',
  },
  {
    title: 'pages.termsAndConditions',
    to: '/terms-and-conditions',
  },
];

export default userNavigation;

import { memo } from 'react';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as InfoIcon } from 'assets/images/info-letter-icon.svg';
import { useTranslation } from 'react-i18next';

const InfoButton = memo(({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('infoBLock.title')} placement='top'>
      <Fab color='primary' onClick={onClick(true)}>
        <SvgIcon
          component={InfoIcon}
          color='white'
          sx={{ width: 32, height: 32 }}
        />
      </Fab>
    </Tooltip>
  );
});

export default InfoButton;

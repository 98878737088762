import { createSelector } from '@reduxjs/toolkit';
import { sortArr } from 'utils/sortArr';
import i18n from 'i18n';
import filterDate from 'utils/filterDate';
import dates from 'constants/dates';

export const selectCurrency = state => state.currency.currency;
export const selectCurrencyRates = state => state.currency.currencyRates;
export const selectAuth = state => state.auth;
export const selectAuthUser = state => state.auth.user;
export const selectUid = state => state.auth.user.uid;
export const selectNotice = state => state.notice;
export const selectPortfolio = state => state.portfolio.portfolio;
export const selectCategory = state => state.category.category;
export const selectMembersCategory = state => state.category.membersCategory;
export const selectMembersCategoryTransaction = state =>
  state.category.membersCategoryTransaction;
export const selectActivity = state => state.activity.activity;
export const selectTag = state => state.tag.tag;
export const selectAssetsOwn = state => state.assets.assets;
export const selectAssetsMember = state => state.assets.assetsMember;
export const selectLiabilities = state => state.liabilities.liabilities;
export const selectTransactionOwn = state => state.transaction.transaction;
export const selectTransactionMember = state =>
  state.transaction.transactionMember;
export const selectPosts = state => state.posts.posts;
export const selectLikes = state => state.likes.likes;
export const selectUser = state => state.users.user;
export const selectUsers = state => state.users.users;
export const selectMembers = state => state.users.members;
export const selectFilters = state => state.filters;
export const selectFiltersTransactions = state => state.filters.transactions;
export const selectFiltersPortfolios = state => state.filters.portfolios;
export const selectFiltersCategories = state => state.filters.categories;
export const selectFiltersActivities = state => state.filters.activities;
export const selectFiltersTags = state => state.filters.tags;
export const selectFiltersAssetsActivationDate = state =>
  state.filters.assetsActivationDate;
export const selectFiltersTransactionsPaymentDate = state =>
  state.filters.transactionsPaymentDate;
export const selectFiltersNotificationsCreatedDate = state =>
  state.filters.notificationsCreatedDate;
export const selectAssetsLiabilitiesSortFilter = state =>
  state.assetsLiabilitiesSortFilter;
export const selectAssetsLiabilitiesSort = state =>
  state.assetsLiabilitiesSortFilter.sortBy;
export const selectAssetsLiabilitiesSearch = state =>
  state.assetsLiabilitiesSortFilter.search;
export const selectTransactionSortFilter = state => state.transactionSortFilter;
export const selectTransactionSort = state =>
  state.transactionSortFilter.sortBy;
export const selectTransactionSearch = state =>
  state.transactionSortFilter.search;
export const selectLanguage = state => state.language.language;

export const selectDates = createSelector([selectLanguage], language => {
  return dates(i18n);
});

export const selectAssets = createSelector(
  [selectAssetsOwn, selectAssetsMember],
  (assets, assetsMember) => {
    return [
      ...new Map(
        [...assets, ...assetsMember].map(item => [item['id'], item])
      ).values(),
    ];
  }
);

export const selectFilterAssets = createSelector(
  [selectAssets, selectFiltersTransactions, selectLanguage],
  (assets, filterAssetsId, language) => {
    if (!filterAssetsId) return i18n.t('allProjects');

    const filterAssets = assets
      .filter(asset => filterAssetsId.includes(asset.id))
      .map(asset => asset.projectName);

    return filterAssets.join(', ');
  }
);

export const selectTransaction = createSelector(
  [selectTransactionOwn, selectTransactionMember],
  (transaction, transactionMember) => {
    return [
      ...new Map(
        [...transaction, ...transactionMember].map(item => [item['id'], item])
      ).values(),
    ];
  }
);

export const selectPersonalAssets = createSelector(
  [selectUid, selectAssets],
  (uid, assets) => {
    return assets ? assets.filter(asset => asset.uid === uid) : [];
  }
);

export const selectAvailableAssets = createSelector(
  [selectUid, selectAssets],
  (uid, assets) => {
    return assets
      ? assets.filter(asset =>
          asset.uid !== uid
            ? asset?.usersId?.includes(uid)
              ? asset
              : false
            : asset
        )
      : [];
  }
);

export const selectTransactionAmount = createSelector(
  [
    selectUid,
    selectAssets,
    selectTransaction,
    selectCurrency,
    selectCurrencyRates,
    selectFiltersTransactions,
    selectFiltersTransactionsPaymentDate,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
    selectCategory,
    selectFiltersCategories,
    selectActivity,
    selectFiltersActivities,
    selectTag,
    selectFiltersTags,
  ],
  (
    uid,
    assets,
    transaction,
    currency,
    currencyRates,
    transactionsId,
    filtersDate,
    filtersAssetsDate,
    portfolio,
    portfoliosId,
    category,
    categoriesId,
    activity,
    activitiesId,
    tag,
    tagsId
  ) => {
    const moneyInArr = transaction
      .filter(
        transaction =>
          transaction.money === 'in' &&
          transaction.associatedMemberUid !== uid &&
          filterDate(
            assets.find(asset => asset.id === transaction.assetsId),
            'activationDate',
            filtersAssetsDate
          ) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(transaction.assetsId)
            : transaction) &&
          (categoriesId
            ? category
                .find(categoryItem => categoriesId.includes(categoryItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (activitiesId
            ? activity
                .find(activityItem => activitiesId.includes(activityItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (tagsId
            ? tag
                .find(tagItem => tagsId.includes(tagItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (transactionsId
            ? transactionsId.includes(transaction.assetsId)
            : transaction) &&
          filterDate(transaction, 'paymentDate', filtersDate)
      )
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      });

    const moneyOutArr = transaction
      .filter(
        transaction =>
          (transaction.money === 'out' ||
            (transaction.money === 'in' &&
              transaction.associatedMemberUid === uid)) &&
          filterDate(
            assets.find(asset => asset.id === transaction.assetsId),
            'activationDate',
            filtersAssetsDate
          ) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(transaction.assetsId)
            : transaction) &&
          (categoriesId
            ? category
                .find(categoryItem => categoriesId.includes(categoryItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (activitiesId
            ? activity
                .find(activityItem => activitiesId.includes(activityItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (tagsId
            ? tag
                .find(tagItem => tagsId.includes(tagItem.id))
                ?.transactionId.includes(transaction.id)
            : transaction) &&
          (transactionsId
            ? transactionsId.includes(transaction.assetsId)
            : transaction) &&
          filterDate(transaction, 'paymentDate', filtersDate)
      )
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      });

    const moneyInAmount = moneyInArr.reduce((accumulator, currentValue) => {
      return uid === currentValue.assetsOwnerId ||
        uid === currentValue.associatedMemberUid
        ? accumulator + currentValue.amount
        : accumulator;
    }, 0);

    const moneyOutAmount = moneyOutArr.reduce((accumulator, currentValue) => {
      return uid === currentValue.assetsOwnerId ||
        uid === currentValue.associatedMemberUid
        ? accumulator + currentValue.amount
        : accumulator;
    }, 0);
    const netAmount = moneyInAmount - moneyOutAmount;

    const assetsMoneyInAmount = moneyInArr
      .filter(transaction => !transaction.liabilitiesId)
      .reduce((accumulator, currentValue) => {
        return uid === currentValue.assetsOwnerId ||
          uid === currentValue.associatedMemberUid
          ? accumulator + currentValue.amount
          : accumulator;
      }, 0);
    const assetsMoneyOutAmount = moneyOutArr
      .filter(transaction => !transaction.liabilitiesId)
      .reduce((accumulator, currentValue) => {
        return uid === currentValue.assetsOwnerId ||
          uid === currentValue.associatedMemberUid
          ? accumulator + currentValue.amount
          : accumulator;
      }, 0);
    const assetsNetAmount = assetsMoneyInAmount - assetsMoneyOutAmount;

    const liabilitiesMoneyInAmount = moneyInArr
      .filter(transaction => !!transaction.liabilitiesId)
      .reduce((accumulator, currentValue) => {
        return uid === currentValue.assetsOwnerId ||
          uid === currentValue.associatedMemberUid
          ? accumulator + currentValue.amount
          : accumulator;
      }, 0);
    const liabilitiesMoneyOutAmount = moneyOutArr
      .filter(transaction => !!transaction.liabilitiesId)
      .reduce((accumulator, currentValue) => {
        return uid === currentValue.assetsOwnerId ||
          uid === currentValue.associatedMemberUid
          ? accumulator + currentValue.amount
          : accumulator;
      }, 0);
    const liabilitiesNetAmount =
      liabilitiesMoneyOutAmount - liabilitiesMoneyInAmount;

    return {
      moneyInAmount,
      moneyOutAmount,
      netAmount,
      assetsNetAmount,
      liabilitiesNetAmount,
    };
  }
);

export const selectAssetsLiabilitiesAmount = createSelector(
  [
    selectUid,
    selectAssets,
    selectLiabilities,
    selectTransactionAmount,
    selectFiltersTransactions,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
  ],
  (
    uid,
    assets,
    liabilities,
    { assetsNetAmount, liabilitiesNetAmount },
    transactionsId,
    filtersAssetsDate,
    portfolio,
    portfoliosId
  ) => {
    const assetsAmount = assets
      .filter(
        asset =>
          (transactionsId ? transactionsId.includes(asset.id) : asset) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(asset.id)
            : asset) &&
          filterDate(asset, 'activationDate', filtersAssetsDate)
      )
      .reduce((accumulator, currentValue) => {
        if (uid === currentValue.uid) {
          return accumulator + currentValue.amount;
        }
        return accumulator;
      }, assetsNetAmount);
    const liabilitiesAmount = liabilities
      .filter(
        liability =>
          (transactionsId
            ? transactionsId.includes(liability.assetsId)
            : liability) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(liability.assetsId)
            : liability) &&
          filterDate(
            assets.find(asset => asset.id === liability.assetsId),
            'activationDate',
            filtersAssetsDate
          )
      )
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        liabilitiesNetAmount
      );
    const netWorth = assetsAmount - liabilitiesAmount;

    return { assetsAmount, liabilitiesAmount, netWorth };
  }
);

export const selectTransactionList = createSelector(
  [
    selectUid,
    selectAssets,
    selectTransaction,
    selectCurrency,
    selectCurrencyRates,
    selectFiltersTransactions,
    selectFiltersTransactionsPaymentDate,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
    selectCategory,
    selectFiltersCategories,
    selectActivity,
    selectFiltersActivities,
    selectTag,
    selectFiltersTags,
    selectLanguage,
  ],
  (
    uid,
    assets,
    transaction,
    currency,
    currencyRates,
    transactionsId,
    filtersDate,
    filtersAssetsDate,
    portfolio,
    portfoliosId,
    category,
    categoriesId,
    activity,
    activitiesId,
    tag,
    tagsId,
    language
  ) => {
    const allList = [
      ...assets
        .filter(
          asset =>
            (transactionsId ? transactionsId.includes(asset.id) : asset) &&
            (portfoliosId
              ? portfolio
                  .find(portfolioItem =>
                    portfoliosId.includes(portfolioItem.id)
                  )
                  ?.assetsId.includes(asset.id)
              : asset) &&
            filterDate(asset, 'activationDate', filtersAssetsDate)
        )
        .map(asset => {
          const transactionFilter = transaction
            .filter(
              transaction =>
                transaction.assetsId === asset.id &&
                (categoriesId
                  ? category
                      .find(categoryItem =>
                        categoriesId.includes(categoryItem.id)
                      )
                      ?.transactionId.includes(transaction.id)
                  : transaction) &&
                (activitiesId
                  ? activity
                      .find(activityItem =>
                        activitiesId.includes(activityItem.id)
                      )
                      ?.transactionId.includes(transaction.id)
                  : transaction) &&
                (tagsId
                  ? tag
                      .find(tagItem => tagsId.includes(tagItem.id))
                      ?.transactionId.includes(transaction.id)
                  : transaction) &&
                filterDate(transaction, 'paymentDate', filtersDate)
            )
            .map(transaction => {
              if (transaction.currency === currency) return transaction;

              const rate = currencyRates?.find(
                currency => currency.code === transaction.currency
              )?.rates[currency];

              return {
                ...transaction,
                amount: rate * transaction.amount,
                currency,
              };
            });

          return [
            ...transactionFilter.map(transactionItem => {
              const filteredTransaction = transactionFilter.filter(
                transaction =>
                  transaction.id !== transactionItem.id &&
                  transaction.paymentDate.seconds <=
                    transactionItem.paymentDate.seconds
              );

              const isMember = transactionItem.associatedMemberUid === uid;

              const moneyInAmount = filteredTransaction
                .filter(
                  transaction =>
                    transaction.money === 'in' &&
                    transaction.associatedMemberUid !== uid
                )
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.amount,
                  transactionItem.money === 'in' && !isMember
                    ? transactionItem.amount
                    : 0
                );
              const moneyOutAmount = filteredTransaction
                .filter(
                  transaction =>
                    transaction.money === 'out' ||
                    (transaction.money === 'in' &&
                      transaction.associatedMemberUid === uid)
                )
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.amount,
                  transactionItem.money === 'out' ||
                    (transactionItem.money === 'in' && isMember)
                    ? transactionItem.amount
                    : 0
                );
              const amountList = [
                {
                  label: i18n.t('transactionInfo.moneyIn'),
                  value: moneyInAmount,
                },
                {
                  label: i18n.t('transactionInfo.moneyOut'),
                  value: moneyOutAmount,
                },
                {
                  label: i18n.t('transactionInfo.net'),
                  value: moneyInAmount - moneyOutAmount,
                },
              ];

              return {
                ...transactionItem,
                amountList,
              };
            }),
          ];
        }),
    ];

    return allList.flat();
  }
);

export const selectTransactionFilteredList = createSelector(
  [
    selectUid,
    selectTransaction,
    selectCurrency,
    selectCurrencyRates,
    selectFiltersTransactionsPaymentDate,
    selectLanguage,
  ],
  (uid, transaction, currency, currencyRates, filtersDate, language) => {
    const transactionFilter = transaction
      .filter(transaction =>
        filterDate(transaction, 'paymentDate', filtersDate)
      )
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      });

    return transactionFilter;
  }
);

export const selectTransactionListNotFiltered = createSelector(
  [
    selectUid,
    selectAssets,
    selectTransaction,
    selectCurrency,
    selectCurrencyRates,
    selectLanguage,
  ],
  (uid, assets, transaction, currency, currencyRates, language) => {
    const allList = [
      ...assets.map(asset => {
        const transactionFilter = transaction
          .filter(transaction => transaction.assetsId === asset.id)
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          });

        return [
          ...transactionFilter.map(transactionItem => {
            const filteredTransaction = transactionFilter.filter(
              transaction =>
                transaction.id !== transactionItem.id &&
                transaction.paymentDate.seconds <=
                  transactionItem.paymentDate.seconds
            );

            const isMember = transactionItem.associatedMemberUid === uid;

            const moneyInAmount = filteredTransaction
              .filter(
                transaction =>
                  transaction.money === 'in' &&
                  transaction.associatedMemberUid !== uid
              )
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.amount,
                transactionItem.money === 'in' && !isMember
                  ? transactionItem.amount
                  : 0
              );
            const moneyOutAmount = filteredTransaction
              .filter(
                transaction =>
                  transaction.money === 'out' ||
                  (transaction.money === 'in' &&
                    transaction.associatedMemberUid === uid)
              )
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.amount,
                transactionItem.money === 'out' ||
                  (transactionItem.money === 'in' && isMember)
                  ? transactionItem.amount
                  : 0
              );
            const amountList = [
              {
                label: i18n.t('transactionInfo.moneyIn'),
                value: moneyInAmount,
              },
              {
                label: i18n.t('transactionInfo.moneyOut'),
                value: moneyOutAmount,
              },
              {
                label: i18n.t('transactionInfo.net'),
                value: moneyInAmount - moneyOutAmount,
              },
            ];

            return {
              ...transactionItem,
              amountList,
            };
          }),
        ];
      }),
    ];

    return allList.flat();
  }
);

export const selectAssetsLiabilitiesList = createSelector(
  [
    selectUid,
    selectAvailableAssets,
    selectLiabilities,
    selectTransactionList,
    selectCurrency,
    selectFiltersTransactions,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
    selectLanguage,
  ],
  (
    uid,
    assets,
    liabilities,
    transactions,
    currency,
    transactionsId,
    filtersAssetsDate,
    portfolio,
    portfoliosId,
    language
  ) => {
    const allList = [
      ...assets
        .filter(
          asset =>
            (transactionsId ? transactionsId.includes(asset.id) : asset) &&
            (portfoliosId
              ? portfolio
                  .find(portfolioItem =>
                    portfoliosId.includes(portfolioItem.id)
                  )
                  ?.assetsId.includes(asset.id)
              : asset) &&
            filterDate(asset, 'activationDate', filtersAssetsDate)
        )
        .map(asset => {
          const assetsTransactionAmount = transactions
            .filter(
              transaction =>
                transaction.assetsId === asset.id && !transaction.liabilitiesId
            )
            .reduce(
              (accumulator, currentValue) =>
                currentValue.money === 'in'
                  ? accumulator + currentValue.amount
                  : accumulator - currentValue.amount,
              0
            );

          const liabilitiesTransactionAmount = transactions
            .filter(
              transaction =>
                transaction.assetsId === asset.id && !!transaction.liabilitiesId
            )
            .reduce(
              (accumulator, currentValue) =>
                currentValue.money === 'in'
                  ? accumulator - currentValue.amount
                  : accumulator + currentValue.amount,
              0
            );

          const liabilitiesFilter = liabilities.filter(
            liability => liability.assetsId === asset.id
          );
          const liabilitiesAmount =
            liabilitiesFilter.reduce(
              (accumulator, currentValue) => accumulator + currentValue.amount,
              0
            ) + liabilitiesTransactionAmount;

          const getAssetAmount = () => {
            return asset.uid === uid ? asset.amount : 0;
          };

          const getAssetCurrency = () => {
            return currency;
          };

          const assetAmount = getAssetAmount() + assetsTransactionAmount;
          const amountList =
            uid === asset.uid
              ? [
                  {
                    label: i18n.t('assetsInfo.assets'),
                    value: assetAmount,
                  },
                  {
                    label: i18n.t('assetsInfo.liabilities'),
                    value: liabilitiesAmount,
                  },
                  {
                    label: i18n.t('assetsInfo.netWorth'),
                    value: assetAmount - liabilitiesAmount,
                  },
                ]
              : [
                  {
                    label: i18n.t('assetsInfo.assets'),
                    value: assetAmount,
                  },
                ];

          return [
            {
              ...asset,
              amount: getAssetAmount(),
              currency: getAssetCurrency(),
              amountList,
              status: 'asset',
            },
            ...liabilitiesFilter.map(liability => ({
              ...liability,
              amountList,
              assetsName: asset.projectName,
              status: 'liability',
            })),
          ];
        }),
    ];

    return allList.flat();
  }
);

export const selectAssetsLiabilitiesListNotFiltered = createSelector(
  [
    selectUid,
    selectAvailableAssets,
    selectLiabilities,
    selectTransactionList,
    selectCurrency,
    selectLanguage,
  ],
  (uid, assets, liabilities, transactions, currency, language) => {
    const allList = [
      ...assets.map(asset => {
        const assetsTransactionAmount = transactions
          .filter(
            transaction =>
              transaction.assetsId === asset.id && !transaction.liabilitiesId
          )
          .reduce(
            (accumulator, currentValue) =>
              currentValue.money === 'in'
                ? accumulator + currentValue.amount
                : accumulator - currentValue.amount,
            0
          );

        const liabilitiesTransactionAmount = transactions
          .filter(
            transaction =>
              transaction.assetsId === asset.id && !!transaction.liabilitiesId
          )
          .reduce(
            (accumulator, currentValue) =>
              currentValue.money === 'in'
                ? accumulator - currentValue.amount
                : accumulator + currentValue.amount,
            0
          );

        const liabilitiesFilter = liabilities.filter(
          liability => liability.assetsId === asset.id
        );
        const liabilitiesAmount =
          liabilitiesFilter.reduce(
            (accumulator, currentValue) => accumulator + currentValue.amount,
            0
          ) + liabilitiesTransactionAmount;

        const getAssetAmount = () => {
          return asset.uid === uid ? asset.amount : 0;
        };

        const getAssetCurrency = () => {
          return currency;
        };

        const assetAmount = getAssetAmount() + assetsTransactionAmount;
        const amountList =
          uid === asset.uid
            ? [
                {
                  label: i18n.t('assetsInfo.assets'),
                  value: assetAmount,
                },
                {
                  label: i18n.t('assetsInfo.liabilities'),
                  value: liabilitiesAmount,
                },
                {
                  label: i18n.t('assetsInfo.netWorth'),
                  value: assetAmount - liabilitiesAmount,
                },
              ]
            : [
                {
                  label: i18n.t('assetsInfo.assets'),
                  value: assetAmount,
                },
              ];

        return [
          {
            ...asset,
            amount: getAssetAmount(),
            currency: getAssetCurrency(),
            amountList,
            status: 'asset',
          },
          ...liabilitiesFilter.map(liability => ({
            ...liability,
            amountList,
            assetsName: asset.projectName,
            status: 'liability',
          })),
        ];
      }),
    ];

    return allList.flat();
  }
);

export const selectPortfolioList = createSelector(
  [
    selectAssetsLiabilitiesListNotFiltered,
    selectTransactionListNotFiltered,
    selectPortfolio,
    selectFiltersPortfolios,
    selectLanguage,
  ],
  (assets, transaction, portfolio, portfoliosId, language) => {
    return portfolio
      .filter(portfolioItem =>
        portfoliosId ? portfoliosId.includes(portfolioItem.id) : portfolioItem
      )
      .map(portfolioItem => {
        const defaultAmount = {
          amountList: [
            {
              label: i18n.t('assetsInfo.assets'),
              value: 0,
            },
            {
              label: i18n.t('assetsInfo.liabilities'),
              value: 0,
            },
            {
              label: i18n.t('assetsInfo.netWorth'),
              value: 0,
            },
          ],
        };
        const defaultTransaction = {
          amountList: [
            {
              label: i18n.t('transactionInfo.moneyIn'),
              value: 0,
            },
            {
              label: i18n.t('transactionInfo.moneyOut'),
              value: 0,
            },
            {
              label: i18n.t('transactionInfo.net'),
              value: 0,
            },
          ],
        };
        const assetsFiltered = assets.filter(
          asset => portfolioItem.assetsId.indexOf(asset.id) !== -1
        );
        const assetsList = assetsFiltered.map(({ id, projectName }) => ({
          id,
          projectName,
        }));
        const assetsAmount = assetsFiltered.reduce(
          (accumulator, currentValue) => {
            return {
              amountList: [
                {
                  label: i18n.t('assetsInfo.assets'),
                  value:
                    accumulator.amountList[0].value +
                    currentValue.amountList[0].value,
                },
                {
                  label: i18n.t('assetsInfo.liabilities'),
                  value:
                    accumulator.amountList[1].value +
                    currentValue.amountList[1].value,
                },
                {
                  label: i18n.t('assetsInfo.netWorth'),
                  value:
                    accumulator.amountList[2].value +
                    currentValue.amountList[2].value,
                },
              ],
            };
          },
          defaultAmount
        );
        const transactionAmount = portfolioItem.assetsId
          .map(
            assetId =>
              transaction
                .filter(transaction => transaction.assetsId === assetId)
                .sort((a, b) => {
                  if (a.paymentDate.seconds < b.paymentDate.seconds) {
                    return 1;
                  }
                  if (a.paymentDate.seconds > b.paymentDate.seconds) {
                    return -1;
                  }
                  return 0;
                })[0]
          )
          .filter(transaction => !!transaction)
          .reduce((accumulator, currentValue) => {
            return {
              amountList: [
                {
                  label: i18n.t('transactionInfo.moneyIn'),
                  value:
                    accumulator.amountList[0].value +
                    currentValue.amountList[0].value,
                },
                {
                  label: i18n.t('transactionInfo.moneyOut'),
                  value:
                    accumulator.amountList[1].value +
                    currentValue.amountList[1].value,
                },
                {
                  label: i18n.t('transactionInfo.net'),
                  value:
                    accumulator.amountList[2].value +
                    currentValue.amountList[2].value,
                },
              ],
            };
          }, defaultTransaction);

        return {
          ...portfolioItem,
          assets: assetsList,
          projectAmount: assetsAmount.amountList,
          transactionAmount: transactionAmount.amountList,
        };
      });
  }
);

export const selectCategoryList = createSelector(
  [
    selectTransactionList,
    selectCurrency,
    selectCurrencyRates,
    selectCategory,
    selectFiltersCategories,
    selectLanguage,
    selectFiltersTransactionsPaymentDate,
  ],
  (
    transaction,
    currency,
    currencyRates,
    category,
    categoriesId,
    language,
    filtersDate
  ) => {
    const list = category
      .filter(categoryItem =>
        categoriesId ? categoriesId.includes(categoryItem.id) : categoryItem
      )
      .map(categoryItem => {
        const transactionFiltered = transaction.filter(
          transaction =>
            categoryItem.transactionId.indexOf(transaction.id) !== -1 &&
            filterDate(transaction, 'paymentDate', filtersDate)
        );

        const transactionList = transactionFiltered.map(({ id }) => ({
          id,
        }));

        const moneyInAmount = transactionFiltered
          .filter(transaction => transaction.money === 'in')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const moneyOutAmount = transactionFiltered
          .filter(transaction => transaction.money === 'out')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const netAmount = moneyInAmount - moneyOutAmount;

        const transactionAmount = [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value: moneyInAmount,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value: moneyOutAmount,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value: netAmount,
          },
        ];

        return {
          ...categoryItem,
          transaction: transactionList,
          transactionAmount,
        };
      })
      .filter(categoryItem => categoryItem.transaction.length);

    const transactionId = category
      .map(categoryItem => categoryItem.transactionId)
      .flat();

    const noListTransactionList = transaction.filter(
      transaction =>
        transactionId.indexOf(transaction.id) === -1 &&
        filterDate(transaction, 'paymentDate', filtersDate)
    );

    const noListMoneyInAmount = noListTransactionList
      .filter(transaction => transaction.money === 'in')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListMoneyOutAmount = noListTransactionList
      .filter(transaction => transaction.money === 'out')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListNetAmount = noListMoneyInAmount - noListMoneyOutAmount;

    const noListTransaction = {
      title: i18n.t('notFound.category'),
      transactionAmount: [
        {
          label: i18n.t('transactionInfo.moneyIn'),
          value: noListMoneyInAmount,
        },
        {
          label: i18n.t('transactionInfo.moneyOut'),
          value: noListMoneyOutAmount,
        },
        {
          label: i18n.t('transactionInfo.net'),
          value: noListNetAmount,
        },
      ],
      noLabel: true,
    };

    const totalTransaction = {
      title: i18n.t('total'),
      transactionAmount: list.reduce((accumulator, currentValue) => {
        return [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value:
              accumulator[0].value + currentValue.transactionAmount[0].value,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value:
              accumulator[1].value + currentValue.transactionAmount[1].value,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value:
              accumulator[2].value + currentValue.transactionAmount[2].value,
          },
        ];
      }, noListTransaction.transactionAmount),
      noLabel: true,
    };

    return { list, noListTransaction, totalTransaction };
  }
);

export const selectMembersCategoryList = createSelector(
  [
    selectMembersCategoryTransaction,
    selectCurrency,
    selectCurrencyRates,
    selectMembersCategory,
    selectLanguage,
  ],
  (transaction, currency, currencyRates, category, language) => {
    return category.map(categoryItem => {
      const transactionFiltered = transaction.filter(
        transaction => categoryItem.transactionId.indexOf(transaction.id) !== -1
      );

      const transactionList = transactionFiltered.map(({ id }) => ({
        id,
      }));

      const moneyInAmount = transactionFiltered
        .filter(transaction => transaction.money === 'in')
        .map(transaction => {
          if (transaction.currency === currency) return transaction;

          const rate = currencyRates?.find(
            currency => currency.code === transaction.currency
          )?.rates[currency];

          return {
            ...transaction,
            amount: rate * transaction.amount,
            currency,
          };
        })
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue.amount;
        }, 0);

      const moneyOutAmount = transactionFiltered
        .filter(transaction => transaction.money === 'out')
        .map(transaction => {
          if (transaction.currency === currency) return transaction;

          const rate = currencyRates?.find(
            currency => currency.code === transaction.currency
          )?.rates[currency];

          return {
            ...transaction,
            amount: rate * transaction.amount,
            currency,
          };
        })
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue.amount;
        }, 0);

      const netAmount = moneyInAmount - moneyOutAmount;

      const transactionAmount = [
        {
          label: i18n.t('transactionInfo.moneyIn'),
          value: moneyInAmount,
        },
        {
          label: i18n.t('transactionInfo.moneyOut'),
          value: moneyOutAmount,
        },
        {
          label: i18n.t('transactionInfo.net'),
          value: netAmount,
        },
      ];

      return {
        ...categoryItem,
        transaction: transactionList,
        transactionAmount,
      };
    });
  }
);

export const selectActivityList = createSelector(
  [
    selectTransactionList,
    selectCurrency,
    selectCurrencyRates,
    selectActivity,
    selectFiltersActivities,
    selectLanguage,
    selectFiltersTransactionsPaymentDate,
  ],
  (
    transaction,
    currency,
    currencyRates,
    activity,
    activitiesId,
    language,
    filtersDate
  ) => {
    const list = activity
      .filter(activityItem =>
        activitiesId ? activitiesId.includes(activityItem.id) : activityItem
      )
      .map(activityItem => {
        const transactionFiltered = transaction.filter(
          transaction =>
            activityItem.transactionId.indexOf(transaction.id) !== -1 &&
            filterDate(transaction, 'paymentDate', filtersDate)
        );

        const transactionList = transactionFiltered.map(({ id }) => ({
          id,
        }));

        const moneyInAmount = transactionFiltered
          .filter(transaction => transaction.money === 'in')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const moneyOutAmount = transactionFiltered
          .filter(transaction => transaction.money === 'out')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const netAmount = moneyInAmount - moneyOutAmount;

        const transactionAmount = [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value: moneyInAmount,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value: moneyOutAmount,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value: netAmount,
          },
        ];

        return {
          ...activityItem,
          transaction: transactionList,
          transactionAmount,
        };
      })
      .filter(activityItem => activityItem.transaction.length);

    const transactionId = activity
      .map(activityItem => activityItem.transactionId)
      .flat();

    const noListTransactionList = transaction.filter(
      transaction =>
        transactionId.indexOf(transaction.id) === -1 &&
        filterDate(transaction, 'paymentDate', filtersDate)
    );

    const noListMoneyInAmount = noListTransactionList
      .filter(transaction => transaction.money === 'in')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListMoneyOutAmount = noListTransactionList
      .filter(transaction => transaction.money === 'out')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListNetAmount = noListMoneyInAmount - noListMoneyOutAmount;

    const noListTransaction = {
      title: i18n.t('notFound.activity'),
      transactionAmount: [
        {
          label: i18n.t('transactionInfo.moneyIn'),
          value: noListMoneyInAmount,
        },
        {
          label: i18n.t('transactionInfo.moneyOut'),
          value: noListMoneyOutAmount,
        },
        {
          label: i18n.t('transactionInfo.net'),
          value: noListNetAmount,
        },
      ],
      noLabel: true,
    };

    const totalTransaction = {
      title: i18n.t('total'),
      transactionAmount: list.reduce((accumulator, currentValue) => {
        return [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value:
              accumulator[0].value + currentValue.transactionAmount[0].value,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value:
              accumulator[1].value + currentValue.transactionAmount[1].value,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value:
              accumulator[2].value + currentValue.transactionAmount[2].value,
          },
        ];
      }, noListTransaction.transactionAmount),
      noLabel: true,
    };

    return { list, noListTransaction, totalTransaction };
  }
);

export const selectCategoriesByActivitiesList = createSelector(
  [
    selectActivityList,
    selectTransactionList,
    selectCurrency,
    selectCurrencyRates,
    selectCategory,
    selectFiltersCategories,
    selectLanguage,
    selectFiltersTransactionsPaymentDate,
  ],
  (
    activity,
    transaction,
    currency,
    currencyRates,
    category,
    categoriesId,
    language,
    filtersDate
  ) => {
    const activities = activity.list
      .map(activityItem => {
        const categories = category
          .filter(categoryItem =>
            categoriesId ? categoriesId.includes(categoryItem.id) : categoryItem
          )
          .map(categoryItem => {
            const transactionFiltered = transaction.filter(
              transaction =>
                categoryItem.transactionId.indexOf(transaction.id) !== -1 &&
                activityItem.transactionId.includes(transaction.id) &&
                filterDate(transaction, 'paymentDate', filtersDate)
            );

            const transactionList = transactionFiltered.map(({ id }) => id);

            const moneyInAmount = transactionFiltered
              .filter(transaction => transaction.money === 'in')
              .map(transaction => {
                if (transaction.currency === currency) return transaction;

                const rate = currencyRates?.find(
                  currency => currency.code === transaction.currency
                )?.rates[currency];

                return {
                  ...transaction,
                  amount: rate * transaction.amount,
                  currency,
                };
              })
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
              }, 0);

            const moneyOutAmount = transactionFiltered
              .filter(transaction => transaction.money === 'out')
              .map(transaction => {
                if (transaction.currency === currency) return transaction;

                const rate = currencyRates?.find(
                  currency => currency.code === transaction.currency
                )?.rates[currency];

                return {
                  ...transaction,
                  amount: rate * transaction.amount,
                  currency,
                };
              })
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
              }, 0);

            const netAmount = moneyInAmount - moneyOutAmount;

            const transactionAmount = [
              {
                label: i18n.t('transactionInfo.moneyIn'),
                value: moneyInAmount,
              },
              {
                label: i18n.t('transactionInfo.moneyOut'),
                value: moneyOutAmount,
              },
              {
                label: i18n.t('transactionInfo.net'),
                value: netAmount,
              },
            ];

            return {
              ...categoryItem,
              transaction: transactionList,
              transactionAmount,
            };
          })
          .filter(categoryItem => categoryItem.transaction.length);

        return {
          ...activityItem,
          categories,
        };
      })
      .filter(activityItem => activityItem.categories.length);

    return activities;
  }
);

export const selectTagList = createSelector(
  [
    selectTransactionList,
    selectCurrency,
    selectCurrencyRates,
    selectTag,
    selectFiltersTags,
    selectLanguage,
    selectFiltersTransactionsPaymentDate,
  ],
  (
    transaction,
    currency,
    currencyRates,
    tag,
    tagsId,
    language,
    filtersDate
  ) => {
    const list = tag
      .filter(tagItem => (tagsId ? tagsId.includes(tagItem.id) : tagItem))
      .map(tagItem => {
        const transactionFiltered = transaction.filter(
          transaction => tagItem.transactionId.indexOf(transaction.id) !== -1
        );

        const transactionList = transactionFiltered.map(({ id }) => ({
          id,
        }));

        const moneyInAmount = transactionFiltered
          .filter(transaction => transaction.money === 'in')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const moneyOutAmount = transactionFiltered
          .filter(transaction => transaction.money === 'out')
          .map(transaction => {
            if (transaction.currency === currency) return transaction;

            const rate = currencyRates?.find(
              currency => currency.code === transaction.currency
            )?.rates[currency];

            return {
              ...transaction,
              amount: rate * transaction.amount,
              currency,
            };
          })
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
          }, 0);

        const netAmount = moneyInAmount - moneyOutAmount;

        const transactionAmount = [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value: moneyInAmount,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value: moneyOutAmount,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value: netAmount,
          },
        ];

        return {
          ...tagItem,
          transaction: transactionList,
          transactionAmount,
        };
      })
      .filter(tagItem => tagItem.transaction.length);

    const transactionId = tag
      .map(activityItem => activityItem.transactionId)
      .flat();

    const noListTransactionList = transaction.filter(
      transaction =>
        transactionId.indexOf(transaction.id) === -1 &&
        filterDate(transaction, 'paymentDate', filtersDate)
    );

    const noListMoneyInAmount = noListTransactionList
      .filter(transaction => transaction.money === 'in')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListMoneyOutAmount = noListTransactionList
      .filter(transaction => transaction.money === 'out')
      .map(transaction => {
        if (transaction.currency === currency) return transaction;

        const rate = currencyRates?.find(
          currency => currency.code === transaction.currency
        )?.rates[currency];

        return {
          ...transaction,
          amount: rate * transaction.amount,
          currency,
        };
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0);

    const noListNetAmount = noListMoneyInAmount - noListMoneyOutAmount;

    const noListTransaction = {
      title: i18n.t('notFound.tag'),
      transactionAmount: [
        {
          label: i18n.t('transactionInfo.moneyIn'),
          value: noListMoneyInAmount,
        },
        {
          label: i18n.t('transactionInfo.moneyOut'),
          value: noListMoneyOutAmount,
        },
        {
          label: i18n.t('transactionInfo.net'),
          value: noListNetAmount,
        },
      ],
      noLabel: true,
    };

    const totalTransaction = {
      title: i18n.t('total'),
      transactionAmount: list.reduce((accumulator, currentValue) => {
        return [
          {
            label: i18n.t('transactionInfo.moneyIn'),
            value:
              accumulator[0].value + currentValue.transactionAmount[0].value,
          },
          {
            label: i18n.t('transactionInfo.moneyOut'),
            value:
              accumulator[1].value + currentValue.transactionAmount[1].value,
          },
          {
            label: i18n.t('transactionInfo.net'),
            value:
              accumulator[2].value + currentValue.transactionAmount[2].value,
          },
        ];
      }, noListTransaction.transactionAmount),
      noLabel: true,
    };

    return { list, noListTransaction, totalTransaction };
  }
);

export const selectCategoriesByTagsList = createSelector(
  [
    selectTagList,
    selectTransactionList,
    selectCurrency,
    selectCurrencyRates,
    selectCategory,
    selectFiltersCategories,
    selectLanguage,
    selectFiltersTransactionsPaymentDate,
  ],
  (
    tag,
    transaction,
    currency,
    currencyRates,
    category,
    categoriesId,
    language,
    filtersDate
  ) => {
    const tags = tag.list
      .map(tagItem => {
        const categories = category
          .filter(categoryItem =>
            categoriesId ? categoriesId.includes(categoryItem.id) : categoryItem
          )
          .map(categoryItem => {
            const transactionFiltered = transaction.filter(
              transaction =>
                categoryItem.transactionId.indexOf(transaction.id) !== -1 &&
                tagItem.transactionId.includes(transaction.id) &&
                filterDate(transaction, 'paymentDate', filtersDate)
            );

            const transactionList = transactionFiltered.map(({ id }) => id);

            const moneyInAmount = transactionFiltered
              .filter(transaction => transaction.money === 'in')
              .map(transaction => {
                if (transaction.currency === currency) return transaction;

                const rate = currencyRates?.find(
                  currency => currency.code === transaction.currency
                )?.rates[currency];

                return {
                  ...transaction,
                  amount: rate * transaction.amount,
                  currency,
                };
              })
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
              }, 0);

            const moneyOutAmount = transactionFiltered
              .filter(transaction => transaction.money === 'out')
              .map(transaction => {
                if (transaction.currency === currency) return transaction;

                const rate = currencyRates?.find(
                  currency => currency.code === transaction.currency
                )?.rates[currency];

                return {
                  ...transaction,
                  amount: rate * transaction.amount,
                  currency,
                };
              })
              .reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount;
              }, 0);

            const netAmount = moneyInAmount - moneyOutAmount;

            const transactionAmount = [
              {
                label: i18n.t('transactionInfo.moneyIn'),
                value: moneyInAmount,
              },
              {
                label: i18n.t('transactionInfo.moneyOut'),
                value: moneyOutAmount,
              },
              {
                label: i18n.t('transactionInfo.net'),
                value: netAmount,
              },
            ];

            return {
              ...categoryItem,
              transaction: transactionList,
              transactionAmount,
            };
          })
          .filter(categoryItem => categoryItem.transaction.length);

        return {
          ...tagItem,
          categories,
        };
      })
      .filter(tagItem => tagItem.categories.length);

    return tags;
  }
);

export const selectAssetsLiabilities = createSelector(
  [
    selectAssetsLiabilitiesList,
    selectAssetsLiabilitiesSort,
    selectAssetsLiabilitiesSearch,
  ],
  (assetsLiabilities, sortBy, search) => {
    const searchParam = ['projectName', 'displayName', 'amount', 'assetsName'];

    const assetsLiabilitiesFiltered = !search
      ? [...assetsLiabilities]
      : [...assetsLiabilities].filter(item =>
          searchParam.some(
            newItem =>
              item[newItem] &&
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1
          )
        );

    if (sortBy === 'name') {
      return assetsLiabilitiesFiltered.sort((a, b) => {
        if (
          (a.projectName
            ? a.projectName.toString().toLowerCase()
            : a.displayName.toString().toLowerCase()) <
          (b.projectName
            ? b.projectName.toString().toLowerCase()
            : b.displayName.toString().toLowerCase())
        ) {
          return -1;
        }
        if (
          (a.projectName
            ? a.projectName.toString().toLowerCase()
            : a.displayName.toString().toLowerCase()) >
          (b.projectName
            ? b.projectName.toString().toLowerCase()
            : b.displayName.toString().toLowerCase())
        ) {
          return 1;
        }
        return 0;
      });
    }

    if (sortBy === 'date') {
      return assetsLiabilitiesFiltered.sort((a, b) => {
        if (a.activationDate.seconds < b.activationDate.seconds) {
          return 1;
        }
        if (a.activationDate.seconds > b.activationDate.seconds) {
          return -1;
        }
        return 0;
      });
    }

    if (sortBy === 'assets') {
      return assetsLiabilitiesFiltered.sort((a, b) => {
        if (
          (a.projectName
            ? a.projectName.toString().toLowerCase()
            : a.assetsName.toString().toLowerCase()) <
          (b.projectName
            ? b.projectName.toString().toLowerCase()
            : b.assetsName.toString().toLowerCase())
        ) {
          return -1;
        }
        if (
          (a.projectName
            ? a.projectName.toString().toLowerCase()
            : a.assetsName.toString().toLowerCase()) >
          (b.projectName
            ? b.projectName.toString().toLowerCase()
            : b.assetsName.toString().toLowerCase())
        ) {
          return 1;
        }
        return 0;
      });
    }

    return assetsLiabilities;
  }
);

export const selectTransactionAll = createSelector(
  [selectTransactionList, selectTransactionSort, selectTransactionSearch],
  (transaction, sortBy, search) => {
    const searchParam = ['assetsProjectName', 'liabilitiesProjectName', 'amount'];

    const transactionFiltered = !search
      ? [...transaction]
      : [...transaction].filter(item =>
          searchParam.some(
            newItem =>
              item[newItem] &&
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1
          )
        );

    if (sortBy === 'name') {
      return transactionFiltered.sort((a, b) => {
        const titleA = a.liabilitiesProjectName ?? a.assetsProjectName;
        const titleB = b.liabilitiesProjectName ?? b.assetsProjectName;

        if (
          titleA.toString().toLowerCase() <
          titleB.toString().toLowerCase()
        ) {
          return -1;
        }
        if (
          titleA.toString().toLowerCase() >
          titleB.toString().toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
    }

    if (sortBy === 'date') {
      return transactionFiltered.sort((a, b) => {
        if (a.paymentDate.seconds < b.paymentDate.seconds) {
          return 1;
        }
        if (a.paymentDate.seconds > b.paymentDate.seconds) {
          return -1;
        }
        return 0;
      });
    }

    return transaction;
  }
);

export const selectPostsReplies = createSelector(
  [
    selectAssets,
    selectPosts,
    selectCurrency,
    selectCurrencyRates,
    selectLikes,
    selectFiltersTransactions,
    selectFiltersNotificationsCreatedDate,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
    selectCategory,
    selectFiltersCategories,
    selectActivity,
    selectFiltersActivities,
    selectTag,
    selectFiltersTags,
  ],
  (
    assets,
    posts,
    currency,
    currencyRates,
    likes,
    transactionsId,
    filtersDate,
    filtersAssetsDate,
    portfolio,
    portfoliosId,
    category,
    categoriesId,
    activity,
    activitiesId,
    tag,
    tagsId
  ) => {
    const postsReplies = {};

    const addLikes = post => {
      const postLikes = likes.find(like => like.postId === post.id);

      return {
        ...post,
        likes: postLikes,
      };
    };

    const newPosts = posts
      .filter(
        post =>
          (transactionsId ? transactionsId.includes(post.assetsId) : post) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(post.assetsId)
            : post) &&
          (categoriesId
            ? category
                .find(categoryItem => categoriesId.includes(categoryItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          (activitiesId
            ? activity
                .find(activityItem => activitiesId.includes(activityItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          (tagsId
            ? tag
                .find(tagItem => tagsId.includes(tagItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          filterDate(
            assets.find(asset => asset.id === post.assetsId),
            'activationDate',
            filtersAssetsDate
          ) &&
          filterDate(post, 'createdDate', filtersDate)
      )
      .map(post => {
        if (!post.transaction) return post;
        if (post.transaction.currency === currency) return post;

        const rate = currencyRates?.find(
          currency => currency.code === post.transaction.currency
        )?.rates[currency];

        return {
          ...post,
          transaction: {
            ...post.transaction,
            amount: rate * post.transaction.amount,
            currency,
          },
        };
      })
      .map(addLikes)
      .map(post => {
        const newPost = { ...post };
        postsReplies[post.id] = newPost;
        return newPost;
      });

    newPosts.forEach(post => {
      if (post.parentPostId) {
        const parent = postsReplies[post.parentPostId];

        if (parent) {
          (parent.replies = parent.replies || []).push(post);
        }
      }
    });

    return newPosts.filter(post => !post.parentPostId);
  }
);

export const selectPersonalPostsReplies = createSelector(
  [selectUid, selectPosts],
  (uid, posts) => {
    const postsReplies = {};
    const newPosts = posts
      .filter(post => post.uid === uid)
      .map(post => {
        const newPost = { ...post };
        postsReplies[post.id] = newPost;
        return newPost;
      });

    newPosts.forEach(post => {
      if (post.parentPostId) {
        const parent = postsReplies[post.parentPostId];

        if (parent) {
          (parent.replies = parent.replies || []).push(post);
        }
      }
    });

    return newPosts.filter(post => !post.parentPostId);
  }
);

export const selectBookmarkedPosts = createSelector(
  [
    selectUid,
    selectAssets,
    selectPosts,
    selectCurrency,
    selectCurrencyRates,
    selectLikes,
    selectFiltersTransactions,
    selectFiltersNotificationsCreatedDate,
    selectFiltersAssetsActivationDate,
    selectPortfolio,
    selectFiltersPortfolios,
    selectCategory,
    selectFiltersCategories,
    selectActivity,
    selectFiltersActivities,
    selectTag,
    selectFiltersTags,
  ],
  (
    uid,
    assets,
    posts,
    currency,
    currencyRates,
    likes,
    transactionsId,
    filtersDate,
    filtersAssetsDate,
    portfolio,
    portfoliosId,
    category,
    categoriesId,
    activity,
    activitiesId,
    tag,
    tagsId
  ) => {
    const addLikes = post => {
      const postLikes = likes.find(like => like.postId === post.id);

      return {
        ...post,
        likes: postLikes,
      };
    };

    const isBookmarked = post => post.bookmarkedId?.includes(uid);

    const bookmarkedPosts = posts
      .filter(
        post =>
          (transactionsId ? transactionsId.includes(post.assetsId) : post) &&
          (portfoliosId
            ? portfolio
                .find(portfolioItem => portfoliosId.includes(portfolioItem.id))
                ?.assetsId.includes(post.assetsId)
            : post) &&
          (categoriesId
            ? category
                .find(categoryItem => categoriesId.includes(categoryItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          (activitiesId
            ? activity
                .find(activityItem => activitiesId.includes(activityItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          (tagsId
            ? tag
                .find(tagItem => tagsId.includes(tagItem.id))
                ?.transactionId.includes(post?.transaction?.id)
            : post) &&
          filterDate(
            assets.find(asset => asset.id === post.assetsId),
            'activationDate',
            filtersAssetsDate
          ) &&
          filterDate(post, 'createdDate', filtersDate) &&
          isBookmarked(post)
      )
      .map(post => {
        if (!post.transaction) return post;
        if (post.transaction.currency === currency) return post;

        const rate = currencyRates?.find(
          currency => currency.code === post.transaction.currency
        )?.rates[currency];

        return {
          ...post,
          transaction: {
            ...post.transaction,
            amount: rate * post.transaction.amount,
            currency,
          },
        };
      })
      .map(addLikes);

    return bookmarkedPosts;
  }
);

export const selectUsersAssets = createSelector(
  [selectUsers, selectAssets],
  (users, assets) => {
    if (!users || !users.length) return [];

    const newUsers = users?.map(user => {
      const assetsList = user.assetsId.map(assetId => {
        const { id, projectName } = assets.find(asset => asset.id === assetId);
        return {
          id,
          projectName,
        };
      });
      return {
        ...user,
        assetsId: sortArr(assetsList, 'projectName'),
      };
    });

    return newUsers;
  }
);

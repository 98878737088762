export const isVideo = type => {
  return type?.includes('video/');
};

export const isImage = type => {
  return type?.includes('image/');
};

export const isObject = variable => {
  return (
    typeof variable === 'object' &&
    variable !== null &&
    !Array.isArray(variable)
  );
};

import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/selectors';

const WelcomeContext = createContext(null);

export const WelcomeProvider = ({ children }) => {
  const { user } = useSelector(selectAuth);
  const [isShowWelcome, setIsShowWelcome] = useState('true');

  useEffect(() => {
    if (user) {
      setIsShowWelcome('false');
    } else {
      setIsShowWelcome('true');
    }
  }, [user])

  const [activeStep, setActiveStep] = useState(0);
  const [stepLength, setStepLength] = useState(0);

  const hideWelcome = useCallback(() => {
    setIsShowWelcome('false');
  }, []);

  const value = useMemo(() => {
    return {
      isShowWelcome,
      hideWelcome,
      activeStep,
      setActiveStep,
      stepLength,
      setStepLength,
    };
  }, [activeStep, hideWelcome, isShowWelcome, stepLength]);

  return (
    <WelcomeContext.Provider value={value}>{children}</WelcomeContext.Provider>
  );
};

export const useWelcome = () => useContext(WelcomeContext);

export const sortArr = (arr, name) => {
  return [...arr].sort((a, b) => {
    if (a[name].toString().toLowerCase() < b[name].toString().toLowerCase()) {
      return -1;
    }
    if (a[name].toString().toLowerCase() > b[name].toString().toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { db, storage } from 'firebaseConfig';
import getExtension from 'utils/getExtension';

export const addLiability = createAsyncThunk(
  'liabilities/addLiability',
  async (data, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const currency = thunkAPI.getState().currency.currency;
    const docRef = doc(collection(db, 'liabilities'));

    const newData = {
      ...data,
      uid,
      id: docRef.id,
      currency,
      createdDate: serverTimestamp(),
    };

    if (data.photoURL) {
      const storageRef = ref(
        storage,
        `liabilitiesPictures/${docRef.id}.${getExtension(
          data.photoURL[0].name
        )}`
      );
      const snapshot = await uploadBytes(storageRef, data.photoURL[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      newData.photoURL = downloadURL;
    }

    return setDoc(docRef, newData);
  }
);

export const updateLiability = createAsyncThunk(
  'liabilities/updateLiability',
  async data => {
    const docRef = doc(db, 'liabilities', data.id);

    const newData = {
      ...data,
    };

    if (data.photoURL && typeof data.photoURL !== 'string') {
      const storageRef = ref(
        storage,
        `liabilitiesPictures/${data.id}.${getExtension(data.photoURL[0].name)}`
      );
      const snapshot = await uploadBytes(storageRef, data.photoURL[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      newData.photoURL = downloadURL;
    }

    return updateDoc(docRef, newData);
  }
);

export const deleteLiability = createAsyncThunk(
  'liabilities/deleteLiability',
  async ({ id, photoURL }) => {
    const docRef = doc(db, 'liabilities', id);
    const transactionRef = query(
      collection(db, 'transaction'),
      where('liabilitiesId', '==', id)
    );

    const transactionSnapshot = await getDocs(transactionRef);

    if (photoURL) {
      const storageRef = ref(storage, photoURL);
      await deleteObject(storageRef);
    }

    await Promise.all(
      transactionSnapshot.docs.map(doc => {
        return deleteDoc(doc.ref);
      })
    );

    return deleteDoc(docRef);
  }
);

const initialState = {
  liabilities: [],
  loading: false,
  error: null,
};

export const liabilitiesSlice = createSlice({
  name: 'liabilities',
  initialState,
  reducers: {
    setLiabilities: (state, { payload }) => {
      state.liabilities = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          addLiability.pending,
          deleteLiability.pending,
          updateLiability.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addLiability.fulfilled,
          deleteLiability.fulfilled,
          updateLiability.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addLiability.rejected,
          deleteLiability.rejected,
          updateLiability.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setLiabilities } = liabilitiesSlice.actions;

export default liabilitiesSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { fallbackLng } from 'constants/languages';

const initialState = {
  language: fallbackLng,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;

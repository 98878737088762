import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  updateDoc,
  writeBatch,
  query,
  collection,
  where,
  getDocs,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';
import * as currencyService from 'services/currencyService';
import { getCurrentDate } from 'utils/formatDate';

const initialState = {
  currency: '',
  currencyRates: null,
  loading: false,
  error: null,
};

const setCurrencyRate = async (currencyRef, currencyCode) => {
  const { data } = await currencyService.getCurrencyRates(currencyCode);

  await setDoc(currencyRef, {
    code: currencyCode,
    rates: data.conversion_rates,
  });

  const docSnap = await getDoc(currencyRef);
  return docSnap.data();
};

export const addCurrency = createAsyncThunk(
  'currency/addCurrency',
  async (newCurrency, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;
    const currency = thunkAPI.getState().currency.currency;
    const assets = thunkAPI.getState().assets.assets;
    const liabilities = thunkAPI.getState().liabilities.liabilities;
    const transaction = thunkAPI.getState().transaction.transaction;

    const docRef = doc(db, 'users', uid);
    const assetsRef = query(collection(db, 'assets'), where('uid', '==', uid));
    const liabilitiesRef = query(
      collection(db, 'liabilities'),
      where('uid', '==', uid)
    );
    const transactionRef = query(
      collection(db, 'transaction'),
      where('uid', '==', uid)
    );
    const postsRef = query(
      collection(db, 'posts'),
      where('uid', '==', uid),
      where('parentPostId', '==', null)
    );

    if (currency === newCurrency) return null;

    const assetsSnapshot = await getDocs(assetsRef);
    const liabilitiesSnapshot = await getDocs(liabilitiesRef);
    const transactionSnapshot = await getDocs(transactionRef);
    const postsSnapshot = await getDocs(postsRef);

    const currencyRef = doc(
      db,
      'currency',
      getCurrentDate(),
      'rates',
      currency
    );
    const currencySnap = await getDoc(currencyRef);

    const getCurrency = async () => {
      if (!currencySnap.exists()) {
        return await setCurrencyRate(currencyRef, currency);
      } else {
        const data = currencySnap.data();

        return data;
      }
    };
    const { rates } = await getCurrency();
    const currencyRate = rates[newCurrency];

    const updatePostTransaction = async postId => {
      const postRef = doc(db, 'posts', postId);
      const docSnap = await getDoc(postRef);
      const { repliesId, transaction } = docSnap.data();

      if (repliesId && repliesId.length) {
        await Promise.all(
          repliesId.map(async replyId => {
            return updatePostTransaction(replyId);
          })
        );
      }

      return updateDoc(postRef, {
        transaction: {
          ...transaction,
          currency: newCurrency,
          amount: transaction.amount * currencyRate,
        },
      });
    };

    postsSnapshot.forEach(async doc => {
      const { id, transaction } = doc.data();

      if (transaction) {
        await updatePostTransaction(id);
      }
    });

    assetsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        currency: newCurrency,
        amount: assets.find(asset => asset.id === doc.id).amount * currencyRate,
      });
    });

    liabilitiesSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        currency: newCurrency,
        amount:
          liabilities.find(liability => liability.id === doc.id).amount *
          currencyRate,
      });
    });

    transactionSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        currency: newCurrency,
        amount:
          transaction.find(transactionItem => transactionItem.id === doc.id)
            .amount * currencyRate,
      });
    });

    currentBatch.update(docRef, { currency: newCurrency });

    return await Promise.all(batches.map(batch => batch.commit()));
  }
);

export const getCurrency = createAsyncThunk(
  'currency/getCurrency',
  async uid => {
    const docRef = doc(db, 'users', uid);

    const {
      data: { currency },
    } = await currencyService.getCurrency();

    return updateDoc(docRef, { currency: currency ? currency : 'USD' });
  }
);

export const getCurrencyRates = createAsyncThunk(
  'currency/getCurrencyRates',
  async (_, thunkAPI) => {
    const user = thunkAPI.getState().auth.user;
    const transaction = thunkAPI.getState().transaction.transaction;

    if (!user) return null;

    const currencyList = Array.from(
      new Set(
        [...transaction].map(
          ({ currency, createdDate }) =>
            `${getCurrentDate(createdDate)}-${currency}`
        )
      )
    );

    if (currencyList.length === 0) return null;

    const currencyRates = await Promise.all(
      currencyList.map(async currencyDateCode => {
        const [date, currency] = currencyDateCode.split('-');
        const currencyRef = doc(
          db,
          'currency',
          date,
          'rates',
          currency
        );
        const docSnap = await getDoc(currencyRef);

        if (!docSnap.exists()) {
          return await setCurrencyRate(currencyRef, currency);
        } else {
          const data = docSnap.data();
          return data;
        }
      })
    );

    return currencyRates;
  }
);

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCurrencyRates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.currencyRates = payload;
      })
      .addMatcher(
        isAnyOf(
          addCurrency.pending,
          getCurrency.pending,
          getCurrencyRates.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(addCurrency.fulfilled, getCurrency.fulfilled),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addCurrency.rejected,
          getCurrency.rejected,
          getCurrencyRates.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;

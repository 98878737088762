import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ImageWhiteIcon } from 'assets/images/image-white-icon.svg';

export const stringToColor = string => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = ({ fName = '', lName = '', big = false }) => {
  const str1 = fName ? fName : 'string';
  const str2 = lName ? lName : 'string';

  return {
    sx: {
      bgcolor: stringToColor(`${str1} ${str2}`),
    },
    children:
      fName || lName ? (
        `${fName ? fName[0] : ''}${lName ? lName[0] : ''}`
      ) : (
        <SvgIcon
          component={ImageWhiteIcon}
          sx={{ fontSize: big ? 48 : 32, fill: 'none' }}
        />
      ),
  };
};

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

export const NavBtnWrapper = styled(Box)(({ theme }) => ({
  flexGrow: '0 !important',
  width: 'auto !important',
  flexDirection: 'column !important',
  minWidth: '76px !important',
  pointerEvents: 'none',
  '& > .MuiTouchRipple-root': {
    display: 'none',
  },
  '.MuiBottomNavigationAction-label': {
    paddingTop: 3,
    minHeight: 16,
    lineHeight: 1,
    fontSize: theme.typography.body4.fontSize,
    color: theme.palette.primary.main,
  },
}));

export const StyledFab = styled(Fab)({
  width: 42,
  height: 42,
  flexShrink: 0,
  pointerEvents: 'all',
});

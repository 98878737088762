import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  uploadString,
  getDownloadURL,
  listAll,
  deleteObject,
} from 'firebase/storage';
import { db, storage } from 'firebaseConfig';
import { isObject } from 'utils/isFormat';
import removeExtension from 'utils/removeExtension';
import emailjs from '@emailjs/browser';
import html2canvas from 'html2canvas';

export const addPost = createAsyncThunk(
  'posts/addPost',
  async ({ parentPostId, parentPostUid, ...data }, thunkAPI) => {
    const { uid, displayName, photoURL } = thunkAPI.getState().auth.user;
    const docRef = doc(collection(db, 'posts'));
    const isOwner = uid === data.assetsOwnerId;

    const newData = {
      ...data,
      id: docRef.id,
      uid,
      displayName,
      photoURL,
      parentPostId: parentPostId ? parentPostId : null,
      repliesId: null,
      createdDate: serverTimestamp(),
      usersId:
        parentPostUid && isOwner
          ? [uid, parentPostUid && parentPostUid]
          : isOwner
          ? [uid]
          : [uid, data.assetsOwnerId],
    };

    if (data.images) {
      const images = await Promise.all(
        data.images.map(async image => {
          const storageRef = ref(
            storage,
            `postsPictures/${data.assetsId}/${docRef.id}/${image.name}`
          );
          const posterRef = ref(
            storage,
            `postsPictures/${data.assetsId}/${
              docRef.id
            }/poster-${removeExtension(image.name)}.png`
          );
          const snapshot = await uploadBytes(storageRef, image);
          const downloadURL = await getDownloadURL(snapshot.ref);
          const posterSnapshot =
            image.poster &&
            (await uploadString(posterRef, image.poster, 'data_url'));
          const downloadPosterURL =
            image.poster && (await getDownloadURL(posterSnapshot.ref));
          return {
            src: downloadURL,
            name: removeExtension(image.name),
            type: image.type,
            poster: downloadPosterURL ?? null,
          };
        })
      );

      newData.images = images;
    }

    if (parentPostId) {
      const parentPostRef = doc(db, 'posts', parentPostId);
      await updateDoc(parentPostRef, {
        repliesId: arrayUnion(docRef.id),
      });
    }

    await setDoc(docRef, newData);

    return docRef.id;
  }
);

export const bookmarkPost = createAsyncThunk(
  'posts/bookmarkPost',
  async (postId, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const docRef = doc(db, 'posts', postId);
    const docSnapshot = await getDoc(docRef);
    const { bookmarkedId } = docSnapshot.data();

    if (bookmarkedId?.includes(uid)) {
      return updateDoc(docRef, {
        bookmarkedId: arrayRemove(uid),
      });
    } else {
      return updateDoc(docRef, {
        bookmarkedId: arrayUnion(uid),
      });
    }
  }
);

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async (data, thunkAPI) => {
    const docRef = doc(db, 'posts', data.id);
    const likesRef = doc(db, 'likes', data.id);
    const likesDocSnap = await getDoc(likesRef);
    const docSnapshot = await getDoc(docRef);
    const { repliesId } = docSnapshot.data();

    const newData = {
      ...data,
      createdDate: serverTimestamp(),
    };

    if (!data.images) {
      const storageListRef = ref(
        storage,
        `postsPictures/${data.assetsId}/${data.id}`
      );
      const { items } = await listAll(storageListRef);
      await Promise.all(
        items.map(async itemRef => {
          return await deleteObject(itemRef);
        })
      );
    }

    if (data.images) {
      if (data.images[0] instanceof File) {
        const images = await Promise.all(
          data.images.map(async image => {
            const storageRef = ref(
              storage,
              `postsPictures/${data.assetsId}/${docRef.id}/${image.name}`
            );
            const posterRef = ref(
              storage,
              `postsPictures/${data.assetsId}/${
                docRef.id
              }/poster-${removeExtension(image.name)}.png`
            );
            const snapshot = await uploadBytes(storageRef, image);
            const downloadURL = await getDownloadURL(snapshot.ref);
            const posterSnapshot =
              image.poster &&
              (await uploadString(posterRef, image.poster, 'data_url'));
            const downloadPosterURL =
              image.poster && (await getDownloadURL(posterSnapshot.ref));
            return {
              src: downloadURL,
              name: removeExtension(image.name),
              type: image.type,
              poster: downloadPosterURL ?? null,
            };
          })
        );

        newData.images = images;
      } else {
        const images = data.images.map(image => {
          if (isObject(image)) {
            return image;
          } else {
            return {
              src: image,
              name: ' ',
              type: 'image/*',
              poster: null,
            };
          }
        });
        newData.images = images;
      }
    }

    const updateReplies = async repliesId => {
      return await Promise.all(
        repliesId.map(async replyId => {
          const docRef = doc(db, 'posts', replyId);
          const likesRef = doc(db, 'likes', replyId);
          const likesDocSnap = await getDoc(likesRef);
          const docSnap = await getDoc(docRef);
          const { repliesId } = docSnap.data();

          await updateReplies(repliesId || []);
          await updateDoc(docRef, {
            assetsId: data.assetsId,
            projectName: data.projectName,
          });

          if (likesDocSnap.exists()) {
            return updateDoc(likesRef, {
              assetsId: data.assetsId,
            });
          }

          return null;
        })
      );
    };

    if (repliesId) {
      await updateReplies(repliesId);
    }

    if (likesDocSnap.exists()) {
      await updateDoc(likesRef, {
        assetsId: data.assetsId,
      });
    }

    return updateDoc(docRef, newData);
  }
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async ({ id, assetsId, images }) => {
    const docRef = doc(db, 'posts', id);
    const likesRef = doc(db, 'likes', id);
    const docSnapshot = await getDoc(docRef);
    const { parentPostId, repliesId, transaction } = docSnapshot.data();

    if (images) {
      const storageListRef = ref(storage, `postsPictures/${assetsId}/${id}`);
      const { items } = await listAll(storageListRef);
      await Promise.all(
        items.map(async itemRef => {
          return await deleteObject(itemRef);
        })
      );
    }

    if (parentPostId) {
      const postRef = doc(db, 'posts', parentPostId);
      await updateDoc(postRef, {
        repliesId: arrayRemove(id),
      });
    }

    if (!parentPostId && transaction) {
      const transactionRef = doc(db, 'transaction', transaction.id);
      await updateDoc(transactionRef, {
        relatedPostId: null,
      });
    }

    const deleteReplies = async repliesId => {
      return await Promise.all(
        repliesId.map(async replyId => {
          const docRef = doc(db, 'posts', replyId);
          const likesRef = doc(db, 'likes', replyId);
          const docSnap = await getDoc(docRef);
          const { id, repliesId, images } = docSnap.data();

          if (images) {
            const storageListRef = ref(
              storage,
              `postsPictures/${assetsId}/${id}`
            );
            const { items } = await listAll(storageListRef);
            await Promise.all(
              items.map(async itemRef => {
                return await deleteObject(itemRef);
              })
            );
          }

          await deleteReplies(repliesId || []);
          await deleteDoc(docRef);

          return deleteDoc(likesRef);
        })
      );
    };

    if (repliesId) {
      await deleteReplies(repliesId);
    }

    await deleteDoc(docRef);

    return deleteDoc(likesRef);
  }
);

export const sendPost = createAsyncThunk(
  'reports/sendPost',
  async ({ email, membersEmail, postRef, postName }, thunkAPI) => {
    const currentUser = thunkAPI.getState().auth.user;
    const members = thunkAPI.getState().users.members;

    const canvas = await html2canvas(postRef, { useCORS: true });
    const post = canvas.toDataURL('image/jpg');

    if (email.length) {
      await Promise.all(
        email.map(emailItem => {
          const user =
            currentUser.email === emailItem
              ? currentUser
              : members.find(member => member.email === emailItem);

          return emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_POST_TEMPLATE_ID,
            {
              toUser: user ? user.displayName : 'User',
              fromUser: currentUser.displayName,
              email: emailItem,
              post,
              postName,
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          );
        })
      );
    }

    if (membersEmail.length) {
      await Promise.all(
        membersEmail.map(memberEmail => {
          const user = members.find(member => member.email === memberEmail);

          return emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_POST_TEMPLATE_ID,
            {
              toUser: user ? user.displayName : 'User',
              fromUser: currentUser.displayName,
              email: memberEmail,
              post,
              postName,
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          );
        })
      );
    }

    return null;
  }
);

const initialState = {
  posts: [],
  loading: false,
  error: null,
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, { payload }) => {
      state.posts = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          addPost.pending,
          deletePost.pending,
          updatePost.pending,
          bookmarkPost.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          addPost.fulfilled,
          deletePost.fulfilled,
          updatePost.fulfilled,
          bookmarkPost.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          addPost.rejected,
          deletePost.rejected,
          updatePost.rejected,
          bookmarkPost.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setPosts } = postsSlice.actions;

export default postsSlice.reducer;

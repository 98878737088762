import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const WelcomeContent = styled('div')({
  marginTop: 'auto',
  textAlign: 'center',
  display: 'flex !important',
  flexDirection: 'column',
  alignItems: 'center',
});

export const WelcomeButton = styled(Stack)(({ theme }) => ({
  marginTop: 8,
  minHeight: 100,
  [theme.breakpoints.up('md')]: {
    width: '100%',
    maxWidth: 328,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

export const WelcomeImg = styled('div')(({ theme }) => ({
  margin: '0 auto',
  borderRadius: '28px 28px 0 0',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 35.3177px 88.2943px rgba(28, 28, 166, 0.1)',
  overflow: 'hidden',
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
}));

export const WelcomeText = styled('div')({
  minHeight: 128,
  paddingTop: 44,
  paddingBottom: 24,
});

export const WelcomeSlider = styled(Box)(({theme}) => ({
  marginTop: 'auto',
  '.slick-list': {
    margin: '0 -12px',
    overflow: 'visible',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    }
  },
  '.slick-slide': {
    padding: '0 12px',
  },
}));

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { useTranslation } from 'react-i18next';

const MenuDrawer = ({ open, toggleDrawer, children }) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer
      anchor='bottom'
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      drawersmall='true'
    >
      <Box
        sx={{ width: 'auto' }}
        role='presentation'
        onKeyDown={toggleDrawer(true)}
      >
        <Box sx={{ p: '0 20px 16px' }}>{children}</Box>
        <Divider />
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ borderRadius: '0 0 24px 24px', overflow: 'hidden' }}
              onClick={toggleDrawer(false)}
            >
              <ListItemIcon>
                <SvgIcon
                  component={CloseIcon}
                  svgstroke='true'
                  color='error.second'
                />
              </ListItemIcon>
              <ListItemText primary={t('action.close')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;

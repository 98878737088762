import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import {
  doc,
  collection,
  setDoc,
  getDocs,
  query,
  where,
  arrayUnion,
  arrayRemove,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage';
import { db, storage } from 'firebaseConfig';
import getExtension from 'utils/getExtension';
import {
  addPortfolio,
  updateDeletePortfolio,
} from 'store/slices/portfolioSlice';
import { updateDeleteCategory } from './categorySlice';
import { updateDeleteActivity } from './activitySlice';
import { updateDeleteTag } from './tagSlice';

export const addAssets = createAsyncThunk(
  'assets/addAssets',
  async ({ portfolio, ...data }, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const currency = thunkAPI.getState().currency.currency;
    const docRef = doc(collection(db, 'assets'));

    const newData = {
      ...data,
      id: docRef.id,
      uid,
      usersId: arrayUnion(uid),
      currency,
      createdDate: serverTimestamp(),
    };

    if (data.photoURL) {
      const storageRef = ref(
        storage,
        `assetsPictures/${docRef.id}.${getExtension(data.photoURL[0].name)}`
      );
      const snapshot = await uploadBytes(storageRef, data.photoURL[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      newData.photoURL = downloadURL;
    }

    if (portfolio) {
      const portfolioData = {
        portfolio,
        assetsId: docRef.id,
        uid,
      };
      await thunkAPI.dispatch(addPortfolio(portfolioData));
    }

    return setDoc(docRef, newData);
  }
);

export const updateAssets = createAsyncThunk(
  'assets/updateAssets',
  async ({ portfolio, ...data }, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const docRef = doc(db, 'assets', data.id);
    const transactionRef = query(
      collection(db, 'transaction'),
      where('assetsId', '==', data.id)
    );
    const postsRef = query(
      collection(db, 'posts'),
      where('assetsId', '==', data.id)
    );

    const transactionSnapshot = await getDocs(transactionRef);
    const postsSnapshot = await getDocs(postsRef);

    const newData = {
      ...data,
    };

    if (data.photoURL && typeof data.photoURL !== 'string') {
      const storageRef = ref(
        storage,
        `assetsPictures/${data.id}.${getExtension(data.photoURL[0].name)}`
      );
      const snapshot = await uploadBytes(storageRef, data.photoURL[0]);
      const downloadURL = await getDownloadURL(snapshot.ref);
      newData.photoURL = downloadURL;
    }

    await thunkAPI.dispatch(updateDeletePortfolio(data.id));

    if (portfolio) {
      const portfolioData = {
        portfolio,
        assetsId: data.id,
        uid: data.uid,
      };
      await thunkAPI.dispatch(addPortfolio(portfolioData));
    }

    transactionSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        assetsProjectName: data.projectName,
        assetsPhotoURL: newData.photoURL,
      });
    });

    postsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        projectName: data.projectName,
      });
    });

    currentBatch.update(docRef, newData);

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const deleteAssets = createAsyncThunk(
  'assets/deleteAssets',
  async ({ id, photoURL }, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;
    const docRef = doc(db, 'assets', id);
    const liabilitiesRef = query(
      collection(db, 'liabilities'),
      where('assetsId', '==', id)
    );
    const transactionRef = query(
      collection(db, 'transaction'),
      where('assetsId', '==', id)
    );
    const postsRef = query(
      collection(db, 'posts'),
      where('assetsId', '==', id)
    );
    const likesRef = query(
      collection(db, 'likes'),
      where('assetsId', '==', id)
    );
    const contractorsRef = query(
      collection(db, 'users', uid, 'contractors'),
      where('assetsId', 'array-contains', id)
    );
    const storageListRef = ref(storage, `postsPictures/${id}`);

    const liabilitiesSnapshot = await getDocs(liabilitiesRef);
    const transactionSnapshot = await getDocs(transactionRef);
    const postsSnapshot = await getDocs(postsRef);
    const likesSnapshot = await getDocs(likesRef);
    const contractorsSnapshot = await getDocs(contractorsRef);

    liabilitiesSnapshot.forEach(async doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      const { photoURL } = doc.data();

      if (photoURL) {
        const storageRef = ref(storage, photoURL);
        await deleteObject(storageRef);
      }

      currentBatch.delete(doc.ref);
    });

    const transactionId = transactionSnapshot.docs.map(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      const { id } = doc.data();

      currentBatch.delete(doc.ref);

      return id;
    });

    postsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.delete(doc.ref);
    });

    likesSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.delete(doc.ref);
    });

    contractorsSnapshot.forEach(contractorDoc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      const { assetsId, ...data } = contractorDoc.data();

      if (assetsId.length === 1) {
        const userRef = doc(db, 'users', data.uid);
        currentBatch.update(userRef, {
          contractorAt: arrayRemove(uid),
        });
        currentBatch.delete(contractorDoc.ref);
        currentBatchSize++;
      } else {
        currentBatch.update(contractorDoc.ref, {
          assetsId: assetsId.filter(assetId => assetId !== id),
        });
      }
    });

    if (photoURL) {
      const storageRef = ref(storage, photoURL);
      await deleteObject(storageRef);
    }

    const { prefixes } = await listAll(storageListRef);
    await Promise.all(
      prefixes.map(async prefixes => {
        const { items } = await listAll(prefixes);

        return items.map(async itemRef => {
          return await deleteObject(itemRef);
        });
      })
    );

    await thunkAPI.dispatch(updateDeletePortfolio(id));

    // последовательная итерация transaction (переделать?):
    for (const id of transactionId) {
      await Promise.all([
        await thunkAPI.dispatch(updateDeleteCategory(id)),
        await thunkAPI.dispatch(updateDeleteActivity(id)),
        await thunkAPI.dispatch(updateDeleteTag(id)),
      ]);
    }

    currentBatch.delete(docRef);

    await Promise.all(batches.map(batch => batch.commit()));

    return id;
  }
);

const initialState = {
  assets: [],
  assetsMember: [],
  loading: false,
  error: null,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssets: (state, { payload }) => {
      state.assets = payload;
      state.loading = false;
    },
    setAssetsMember: (state, { payload }) => {
      state.assetsMember = payload;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(addAssets.pending, deleteAssets.pending), state => {
        state.loading = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(addAssets.fulfilled, deleteAssets.fulfilled),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(addAssets.rejected, deleteAssets.rejected),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setAssets, setAssetsMember } = assetsSlice.actions;

export default assetsSlice.reducer;

import { createSlice, isAnyOf, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDocs,
  doc,
  query,
  collection,
  where,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  writeBatch,
} from 'firebase/firestore';
import { db } from 'firebaseConfig';

const initialState = {
  user: null,
  users: null,
  members: null,
  loading: false,
  error: null,
};

export const findUser = createAsyncThunk(
  'users/findUser',
  async (userEmail, thunkAPI) => {
    const { email } = thunkAPI.getState().auth.user;
    const userRef = query(
      collection(db, 'users'),
      where('email', '==', userEmail),
      where('email', '!=', email)
    );
    let user = null;

    const docSnap = await getDocs(userRef);
    docSnap.forEach(doc => {
      user = doc.data();
    });

    return user;
  }
);

export const addUser = createAsyncThunk(
  'users/addUser',
  async (data, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;

    if (data.assetsId) {
      const userRef = doc(db, 'users', uid, 'contractors', data.uid);
      const contractorRef = doc(db, 'users', data.uid);

      data.assetsId.forEach(assetId => {
        if (++currentBatchSize >= 500) {
          currentBatch = writeBatch(db);
          batches.push(currentBatch);
          currentBatchSize = 1;
        }

        const assetRef = doc(db, 'assets', assetId);
        currentBatch.update(assetRef, {
          usersId: arrayUnion(data.uid),
        });
      });

      currentBatch.update(contractorRef, {
        contractorAt: arrayUnion(uid),
      });

      currentBatch.set(userRef, data);
    }

    if (data.isMember) {
      const userMemberRef = doc(db, 'users', data.uid);
      const userRef = doc(db, 'users', uid);

      currentBatch.update(userMemberRef, {
        memberAt: arrayUnion(uid),
      });

      currentBatch.update(userRef, {
        memberAt: arrayUnion(data.uid),
      });
    }

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const addUserFromLink = createAsyncThunk(
  'users/addUserFromLink',
  async (data, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid, displayName, email, photoURL } = thunkAPI.getState().auth.user;

    if (data.assetsId) {
      const userRef = doc(db, 'users', data.uid, 'contractors', uid);
      const contractorRef = doc(db, 'users', uid);

      const userData = {
        assetsId: data.assetsId,
        uid,
        displayName,
        email,
        photoURL,
      };

      data.assetsId.forEach(assetId => {
        if (++currentBatchSize >= 500) {
          currentBatch = writeBatch(db);
          batches.push(currentBatch);
          currentBatchSize = 1;
        }

        const assetRef = doc(db, 'assets', assetId);
        currentBatch.update(assetRef, {
          usersId: arrayUnion(uid),
        });
      });

      currentBatch.update(contractorRef, {
        contractorAt: arrayUnion(data.uid),
      });

      currentBatch.set(userRef, userData);
    }

    if (data.isMember) {
      const userMemberRef = doc(db, 'users', data.uid);
      const userRef = doc(db, 'users', uid);

      currentBatch.update(userMemberRef, {
        memberAt: arrayUnion(uid),
      });

      currentBatch.update(userRef, {
        memberAt: arrayUnion(data.uid),
      });
    }

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({ id, authorUid }, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;
    const assetRef = doc(db, 'assets', id);
    const transactionRef = query(
      collection(db, 'transaction'),
      where('assetsId', '==', id)
    );
    const postsRef = query(
      collection(db, 'posts'),
      where('assetsId', '==', id)
    );
    const userContractorRef = doc(db, 'users', authorUid, 'contractors', uid);
    const userRef = doc(db, 'users', uid);

    const transactionSnapshot = await getDocs(transactionRef);
    const postsSnapshot = await getDocs(postsRef);
    const userContractorSnapshot = await getDoc(userContractorRef);
    const userContractorData = userContractorSnapshot.data();

    if (userContractorData.assetsId.length === 1) {
      currentBatch.delete(userContractorRef);
      currentBatch.update(userRef, {
        contractorAt: arrayRemove(authorUid),
      });
      currentBatchSize = 2;
    } else {
      currentBatch.update(userContractorRef, {
        assetsId: arrayRemove(id),
      });
      currentBatchSize = 1;
    }

    transactionSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        usersId: arrayRemove(uid),
      });
    });

    postsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        usersId: arrayRemove(uid),
      });
    });

    currentBatch.update(assetRef, {
      usersId: arrayRemove(uid),
    });

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const deleteUserAll = createAsyncThunk(
  'users/deleteUserAll',
  async (userId, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;
    const assetsRef = query(
      collection(db, 'assets'),
      where('usersId', 'array-contains', userId)
    );
    const transactionRef = query(
      collection(db, 'transaction'),
      where('usersId', 'array-contains', userId)
    );
    const postsRef = query(
      collection(db, 'posts'),
      where('usersId', 'array-contains', userId)
    );
    const userContractorRef = doc(db, 'users', uid, 'contractors', userId);
    const contractorRef = doc(db, 'users', userId);

    const assetsSnapshot = await getDocs(assetsRef);
    const transactionSnapshot = await getDocs(transactionRef);
    const postsSnapshot = await getDocs(postsRef);

    transactionSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        usersId: arrayRemove(userId),
      });
    });

    postsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        usersId: arrayRemove(userId),
      });
    });

    assetsSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        usersId: arrayRemove(userId),
      });
    });

    currentBatch.update(contractorRef, {
      contractorAt: arrayRemove(uid),
    });

    currentBatch.delete(userContractorRef);

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const deleteMember = createAsyncThunk(
  'users/deleteMember',
  async (userId, thunkAPI) => {
    let currentBatch = writeBatch(db);
    let currentBatchSize = 0;
    const batches = [currentBatch];

    const { uid } = thunkAPI.getState().auth.user;
    const memberRef = doc(db, 'users', userId);
    const userRef = doc(db, 'users', uid);
    const assetsOwnRef = query(
      collection(db, 'assets'),
      where('uid', '==', uid),
      where('associatedMemberUid', 'array-contains', userId)
    );
    const assetsMemberRef = query(
      collection(db, 'assets'),
      where('uid', '==', userId),
      where('associatedMemberUid', 'array-contains', uid)
    );
    const categoryOwnRef = query(
      collection(db, 'category'),
      where('uid', '==', uid),
      where('membersId', 'array-contains', userId)
    );
    const categoryMemberRef = query(
      collection(db, 'category'),
      where('uid', '==', userId),
      where('membersId', 'array-contains', uid)
    );
    const transactionOwnRef = query(
      collection(db, 'transaction'),
      where('uid', '==', uid),
      where('associatedMemberUid', '==', userId)
    );
    const transactionMemberRef = query(
      collection(db, 'transaction'),
      where('uid', '==', userId),
      where('associatedMemberUid', '==', uid)
    );

    const assetsOwnSnapshot = await getDocs(assetsOwnRef);
    const assetsMemberSnapshot = await getDocs(assetsMemberRef);
    const categoryOwnSnapshot = await getDocs(categoryOwnRef);
    const categoryMemberSnapshot = await getDocs(categoryMemberRef);
    const transactionOwnSnapshot = await getDocs(transactionOwnRef);
    const transactionMemberSnapshot = await getDocs(transactionMemberRef);

    assetsOwnSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        associatedMemberUid: arrayRemove(userId),
      });
    });

    assetsMemberSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        associatedMemberUid: arrayRemove(uid),
      });
    });

    categoryOwnSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        membersId: arrayRemove(userId),
      });
    });

    categoryMemberSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        membersId: arrayRemove(uid),
      });
    });

    transactionOwnSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        associatedMemberUid: null,
      });
    });

    transactionMemberSnapshot.forEach(doc => {
      if (++currentBatchSize >= 500) {
        currentBatch = writeBatch(db);
        batches.push(currentBatch);
        currentBatchSize = 1;
      }

      currentBatch.update(doc.ref, {
        associatedMemberUid: null,
      });
    });

    currentBatch.update(memberRef, {
      memberAt: arrayRemove(uid),
    });

    currentBatch.update(userRef, {
      memberAt: arrayRemove(userId),
    });

    return Promise.all(batches.map(batch => batch.commit()));
  }
);

export const updateDeleteUser = createAsyncThunk(
  'users/updateDeleteUser',
  async ({ userId, prevAssetsId, assetsId }, thunkAPI) => {
    const { uid } = thunkAPI.getState().auth.user;
    const userContractorRef = doc(db, 'users', uid, 'contractors', userId);

    prevAssetsId.forEach(async assetId => {
      if (!assetsId.includes(assetId)) {
        const assetRef = doc(db, 'assets', assetId);
        const transactionRef = query(
          collection(db, 'transaction'),
          where('usersId', 'array-contains', userId),
          where('assetsId', '==', assetId)
        );
        const postsRef = query(
          collection(db, 'posts'),
          where('usersId', 'array-contains', userId),
          where('assetsId', '==', assetId)
        );

        const transactionSnapshot = await getDocs(transactionRef);
        const postsSnapshot = await getDocs(postsRef);

        transactionSnapshot.forEach(async doc => {
          await updateDoc(doc.ref, {
            usersId: arrayRemove(userId),
          });
        });

        postsSnapshot.forEach(async doc => {
          await updateDoc(doc.ref, {
            usersId: arrayRemove(userId),
          });
        });

        await updateDoc(assetRef, {
          usersId: arrayRemove(userId),
        });
      }
    });

    assetsId.forEach(async assetId => {
      if (!prevAssetsId.includes(assetId)) {
        const assetRef = doc(db, 'assets', assetId);

        await updateDoc(assetRef, {
          usersId: arrayUnion(userId),
        });
      }
    });

    return updateDoc(userContractorRef, {
      assetsId,
    });
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findUser.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(addUser.fulfilled, state => {
        state.user = null;
      })
      .addMatcher(
        isAnyOf(
          findUser.pending,
          addUser.pending,
          addUserFromLink.pending,
          deleteUser.pending,
          deleteUserAll.pending,
          updateDeleteUser.pending
        ),
        state => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          findUser.fulfilled,
          addUser.fulfilled,
          addUserFromLink.fulfilled,
          deleteUser.fulfilled,
          deleteUserAll.fulfilled,
          updateDeleteUser.fulfilled
        ),
        state => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          findUser.rejected,
          addUser.rejected,
          addUserFromLink.rejected,
          deleteUser.rejected,
          deleteUserAll.rejected,
          updateDeleteUser.rejected
        ),
        (state, { error }) => {
          state.loading = false;
          state.error = error;
        }
      );
  },
});

export const { setUser, setUsers, setMembers } = usersSlice.actions;

export default usersSlice.reducer;

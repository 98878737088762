import SvgIcon from '@mui/material/SvgIcon';
import { useLocation, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { StyledButton } from 'components/common/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const IconRouteButton = ({ title, to, icon, colorWhite }) => {
  const location = useLocation();
  const matches = useMediaQuery('(min-width:480px)');

  return (
    <Tooltip title={title}>
      <StyledButton
        component={Link}
        to={`${location.pathname}${to}`}
        state={{ background: location }}
        sx={{ marginInlineStart: matches ? 2.25 : 0.5 }}
        disableRipple={true}
      >
        <IconButton component='div'>
          <SvgIcon
            component={icon}
            viewBox='0 0 24 24'
            width='24'
            height='24'
            svgstroke='true'
            color={colorWhite ? 'white' : 'text'}
          />
        </IconButton>
        <Typography
          sx={{
            marginTop: -0.25,
            color: colorWhite ? 'white.main' : 'text.main',
            maxWidth: matches ? 'none' : 66
          }}
          variant='body6'
          component='span'
          noWrap 
        >
          {title}
        </Typography>
      </StyledButton>
    </Tooltip>
  );
};

export default IconRouteButton;

import { useMemo, useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import step1 from 'assets/images/welcome-step-1.png';
import step1_2x from 'assets/images/welcome-step-1-2x.png';
import step1_3x from 'assets/images/welcome-step-1-3x.png';
import step2 from 'assets/images/welcome-step-2.png';
import step2_2x from 'assets/images/welcome-step-2-2x.png';
import step2_3x from 'assets/images/welcome-step-2-3x.png';
import step3 from 'assets/images/welcome-step-3.png';
import step3_2x from 'assets/images/welcome-step-3-2x.png';
import step3_3x from 'assets/images/welcome-step-3-3x.png';
import {
  WelcomeButton,
  WelcomeContent,
  WelcomeImg,
  WelcomeText,
  WelcomeSlider,
} from '../styles';
import { useWelcome } from 'context/WelcomeContext';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

const WelcomeSteps = () => {
  const {
    isShowWelcome,
    hideWelcome,
    activeStep,
    setActiveStep,
    setStepLength,
  } = useWelcome();
  const location = useLocation();
  const { t } = useTranslation();

  const sliderRef = useRef(null);

  const steps = useMemo(() => {
    return [
      {
        id: 0,
        img: step1,
        img2x: step1_2x,
        img3x: step1_3x,
        text: t('welcome.steps.0', {
          logo: t('logo'),
        }),
        next: {
          label: t('action.next'),
          action: () => {
            sliderRef.current.slickGoTo(1);
            setActiveStep(1);
          },
        },
        skip: {
          label: t('action.skip'),
          action: () => hideWelcome(),
        },
      },
      {
        id: 1,
        img: step2,
        img2x: step2_2x,
        img3x: step2_3x,
        text: t('welcome.steps.1'),
        next: {
          label: t('action.next'),
          action: () => {
            sliderRef.current.slickGoTo(2);
            setActiveStep(2);
          },
        },
        skip: {
          label: t('action.skip'),
          action: () => hideWelcome(),
        },
      },
      {
        id: 2,
        img: step3,
        img2x: step3_2x,
        img3x: step3_3x,
        text: t('welcome.steps.2', {
          logo: t('logo'),
        }),
        next: {
          label: t('action.start'),
          action: () => hideWelcome(),
        },
        skip: {
          label: t('action.skip'),
          action: () => hideWelcome(),
        },
      },
    ];
  }, [hideWelcome, setActiveStep, t, sliderRef]);

  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
    };
  }, []);

  useEffect(() => {
    setStepLength(steps.length);
  }, [setStepLength, steps]);

  const getActiveStep = useMemo(() => {
    const { next, skip } = steps.find(step => step.id === activeStep);

    return { next, skip };
  }, [activeStep, steps]);

  if (isShowWelcome === 'false') {
    if (location.state?.isSignInWithLink) {
      return (
        <Navigate to={{ pathname: '/register', search: location.search }} />
      );
    } else {
      return <Navigate to='/' />;
    }
  }

  return (
    <>
      <WelcomeSlider component={Slider} {...settings} ref={sliderRef}>
        {steps.map(({ img, img2x, img3x, text, next, skip }, idx) => (
          <WelcomeContent key={`slide=${idx}`}>
            <WelcomeImg>
              <Box
                component='img'
                src={img}
                srcSet={`${img} 1x, ${img2x} 2x, ${img3x} 3x`}
                alt={t('logo')}
              ></Box>
            </WelcomeImg>
            <WelcomeText>
              <Typography variant='body2'>{text}</Typography>
            </WelcomeText>
          </WelcomeContent>
        ))}
      </WelcomeSlider>
      <WelcomeButton spacing={1}>
        <Button
          type='submit'
          variant='contained'
          fullWidth
          onClick={getActiveStep.next.action}
        >
          {getActiveStep.next.label}
        </Button>
        <Button
          type='submit'
          variant='text'
          fullWidth
          color='grey'
          onClick={getActiveStep.skip.action}
        >
          {getActiveStep.skip.label}
        </Button>
      </WelcomeButton>
    </>
  );
};

export default WelcomeSteps;

export const fallbackLng = 'en';
export const languages = [
  { code: 'am', name: 'አማርኛ (Amharic)' },
  { code: 'ar', name: 'اَلْعَرَبِيَّةُ (Arabic)' },
  { code: 'en', name: 'English (English)' },
  { code: 'es', name: 'Español (Spanish)' },
  { code: 'fr', name: 'Français (French)' },
  { code: 'ha', name: 'Harshen (Hausa)' },
  { code: 'he', name: 'עִבְרִית (Hebrew)' },
  { code: 'hi', name: 'हिन्दी (Hindi)' },
  { code: 'id', name: 'Bahasa Indonesia (Indonesian)' },
  { code: 'ig', name: 'Ásụ̀sụ́ Ìgbò (Igbo)' },
  { code: 'ja', name: '日本語 (Japanese)' },
  { code: 'ko', name: '한국어 (Korean)' },
  { code: 'ln', name: 'Ngala (Lingala)' },
  { code: 'om', name: 'Afaan Oromoo (Oromo)' },
  { code: 'pa', name: 'ਪੰਜਾਬੀ (Punjabi)' },
  { code: 'pl', name: 'Polski (Polish)' },
  { code: 'rw', name: 'Ikinyarwanda (Kinyarwanda)' },
  { code: 'sw', name: 'Kiswahili (Swahili)' },
  { code: 'uk', name: 'Українська мова (Ukrainian)' },
  { code: 'xh', name: 'isiXhosa (Xhosa)' },
  { code: 'yo', name: 'Èdè Yorùbá (Yoruba)' },
  { code: 'zh', name: '中文 (Chinese)' },
  { code: 'zu', name: 'isiZulu (Zulu)' },
];

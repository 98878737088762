import { Suspense, lazy } from 'react';
import { useLocation, Navigate, Routes, Route } from 'react-router-dom';
import Layout from 'components/layouts/Layout';
import WelcomeLayout from 'components/layouts/WelcomeLayout';
import PrivateRoute from 'components/router/PrivateRoute';
import WelcomeRoute from 'components/router/WelcomeRoute';
import IconRouteButton from 'components/IconRouteButton';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as ItemPlusIcon } from 'assets/images/post-plus-icon.svg';
import { useTranslation } from 'react-i18next';
import WelcomeSteps from 'pages/Welcome/WelcomeSteps';
import Dots from 'components/Dots';

const Home = lazy(() => import('pages/Home'));
const Notifications = lazy(() => import('pages/Notifications'));
const MoneyInOut = lazy(() => import('pages/MoneyInOut'));
const AssetsLiabilities = lazy(() => import('pages/AssetsLiabilities'));
const SignIn = lazy(() => import('pages/SignIn'));
const Register = lazy(() => import('pages/Register'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Profile = lazy(() => import('pages/Profile'));
const AssetsLiabilitiesAddEdit = lazy(() =>
  import('pages/AssetsLiabilitiesAddEdit')
);
const MoneyInOutAddEdit = lazy(() => import('pages/MoneyInOutAddEdit'));
const AddEditPost = lazy(() => import('pages/AddEditPost'));
const AddEditReply = lazy(() => import('pages/AddEditReply'));
const EditCategory = lazy(() => import('pages/EditCategory'));
const EditActivity = lazy(() => import('pages/EditActivity'));
const EditTag = lazy(() => import('pages/EditTag'));
const DetailAsset = lazy(() => import('pages/DetailAsset'));
const DetailPortfolio = lazy(() => import('pages/DetailPortfolio'));
const DetailCategory = lazy(() => import('pages/DetailCategory'));
const DetailActivity = lazy(() => import('pages/DetailActivity'));
const DetailTag = lazy(() => import('pages/DetailTag'));
const Portfolio = lazy(() => import('pages/Portfolio'));
const Category = lazy(() => import('pages/Category'));
const Activity = lazy(() => import('pages/Activity'));
const Tag = lazy(() => import('pages/Tag'));
const CategoriesByActivities = lazy(() =>
  import('pages/CategoriesByActivities')
);
const CategoriesByTags = lazy(() => import('pages/CategoriesByTags'));
const DetailCategoriesByActivities = lazy(() =>
  import('pages/DetailCategoriesByActivities')
);
const DetailCategoriesByTags = lazy(() =>
  import('pages/DetailCategoriesByTags')
);
const Filters = lazy(() => import('pages/Filters'));
const AddUser = lazy(() => import('pages/AddUser'));
const Users = lazy(() => import('pages/Users'));
const Members = lazy(() => import('pages/Members'));
const UserAssets = lazy(() => import('pages/UserAssets'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('pages/TermsAndConditions'));
const WelcomeIntro = lazy(() => import('pages/Welcome/WelcomeIntro'));
const CategoriesToView = lazy(() => import('pages/CategoriesToView'));
const ViewCategories = lazy(() => import('pages/ViewCategories'));
const DetailNotifications = lazy(() => import('pages/DetailNotifications'));

const AppRouter = () => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const { t } = useTranslation();

  return (
    <>
      <Routes location={background || location}>
        <Route
          path='/'
          element={
            <WelcomeRoute>
              <Layout title={t('logo')}>
                <Suspense fallback={<LinearProgress />}>
                  <SignIn />
                </Suspense>
              </Layout>
            </WelcomeRoute>
          }
        />
        <Route
          path='register'
          element={
            <WelcomeRoute>
              <Layout title={t('logo')}>
                <Suspense fallback={<LinearProgress />}>
                  <Register />
                </Suspense>
              </Layout>
            </WelcomeRoute>
          }
        />
        <Route
          path='reset-password'
          element={
            <WelcomeRoute>
              <Layout title={t('logo')}>
                <Suspense fallback={<LinearProgress />}>
                  <ResetPassword />
                </Suspense>
              </Layout>
            </WelcomeRoute>
          }
        />
        <Route
          path='/home'
          element={
            <WelcomeRoute>
              <PrivateRoute>
                <Layout title={t('logo')} showFilters showInfoButton>
                  <Suspense fallback={<LinearProgress />}>
                    <Home />
                  </Suspense>
                </Layout>
              </PrivateRoute>
            </WelcomeRoute>
          }
        />
        <Route
          path='/notifications'
          element={
            <WelcomeRoute>
              <PrivateRoute>
                <Layout
                  title={t('activity')}
                  contentLg
                  button={
                    <IconRouteButton
                      title={t('action.addPost')}
                      to='/add-post'
                      icon={ItemPlusIcon}
                    />
                  }
                  showFilters
                  showInfoButton
                >
                  <Suspense fallback={<LinearProgress />}>
                    <Notifications />
                  </Suspense>
                </Layout>
              </PrivateRoute>
            </WelcomeRoute>
          }
        />
        <Route
          path='/money-in-out'
          element={
            <WelcomeRoute>
              <PrivateRoute>
                <Layout
                  title={t('transactionInfo.title')}
                  colorWhite
                  contentSm
                  showFilters
                  showInfoButton
                  button={
                    <IconRouteButton
                      title={t('action.addMoney')}
                      to='/add-money-in-out'
                      icon={ItemPlusIcon}
                      colorWhite
                    />
                  }
                >
                  <Suspense fallback={<LinearProgress />}>
                    <MoneyInOut />
                  </Suspense>
                </Layout>
              </PrivateRoute>
            </WelcomeRoute>
          }
        />
        <Route
          path='/assets-liabilities'
          element={
            <WelcomeRoute>
              <PrivateRoute>
                <Layout
                  title={t('assetsInfo.title')}
                  colorWhite
                  contentSm
                  showFilters
                  showInfoButton
                  button={
                    <IconRouteButton
                      title={t('action.addAssetLiability')}
                      to='/add-assets-liabilities'
                      icon={ItemPlusIcon}
                      colorWhite
                    />
                  }
                >
                  <Suspense fallback={<LinearProgress />}>
                    <AssetsLiabilities />
                  </Suspense>
                </Layout>
              </PrivateRoute>
            </WelcomeRoute>
          }
        />
        <Route
          path='/privacy-policy'
          element={
            <Suspense fallback={<LinearProgress />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path='/terms-and-conditions'
          element={
            <Suspense fallback={<LinearProgress />}>
              <TermsAndConditions />
            </Suspense>
          }
        />
        <Route path='/welcome'>
          <Route
            index
            element={
              <WelcomeLayout bg='primary'>
                <Suspense fallback={<LinearProgress />}>
                  <WelcomeIntro />
                </Suspense>
              </WelcomeLayout>
            }
          />
          <Route
            path='steps'
            element={
              <Layout
                title={t('logo')}
                bg='background.secondary'
                contentMd
                button={<Dots />}
              >
                <WelcomeSteps />
              </Layout>
            }
          />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
      {background && (
        <Routes>
          <Route
            path={`${background.pathname}/profile`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/add-assets-liabilities`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AssetsLiabilitiesAddEdit />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-asset/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AssetsLiabilitiesAddEdit edit='asset' />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-liability/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AssetsLiabilitiesAddEdit edit='liability' />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/add-money-in-out`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <MoneyInOutAddEdit />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-money-in/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <MoneyInOutAddEdit edit='money-in' />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-money-out/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <MoneyInOutAddEdit edit='money-out' />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/add-post`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AddEditPost />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-post/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AddEditPost />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/add-reply/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AddEditReply />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-reply/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AddEditReply edit />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/detail-asset/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <DetailAsset />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-category/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <EditCategory />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-activity/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <EditActivity />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/edit-tag/:id`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <EditTag />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/filters`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Filters />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/portfolio`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Portfolio />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailPortfolio />
                </Suspense>
              }
            >
              <Route
                path='asset/:id'
                element={
                  <Suspense fallback={<LinearProgress />}>
                    <DetailAsset />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route
            path={`${background.pathname}/category`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Category />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailCategory />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/activity`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Activity />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailActivity />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/tag`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Tag />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailTag />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/categories-by-activities`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <CategoriesByActivities />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailCategoriesByActivities />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/categories-by-tags`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <CategoriesByTags />
              </Suspense>
            }
          >
            <Route
              path=':id'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <DetailCategoriesByTags />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/add-user`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <AddUser />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/users`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Users />
              </Suspense>
            }
          >
            <Route
              path=':id/assets'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <UserAssets />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/members`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <Members />
              </Suspense>
            }
          >
            <Route
              path=':id/select-categories'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <CategoriesToView />
                </Suspense>
              }
            />
            <Route
              path=':id/view-categories'
              element={
                <Suspense fallback={<LinearProgress />}>
                  <ViewCategories />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={`${background.pathname}/privacy-policy`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/terms-and-conditions`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path={`${background.pathname}/detail-notifications`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <DetailNotifications />
              </Suspense>
            }
          />
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
